import React, { Component } from 'react';
import { BrowserRouter, NavLink, Link } from 'react-router-dom';
import Card from '../../generic/Card';
import Loader from '../../generic/Loader';
import Stat from '../../generic/Stat';
import Notification from '../../generic/Notification';
import Table from '../../generic/Table';
import state from '../../State';
import { view } from 'react-easy-state';
import  Cookies  from  'universal-cookie';
import * as Recharts from 'recharts';
const {BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,ComposedChart } = Recharts;
const COOKIES = new Cookies();


export default view(class CampaignReports extends Component {
    componentDidMount(){
      state.api.get(`customers/campaigns`).then(resp => {
        state.campaignReports.filteredData = resp.data;
        state.campaignReports.loading = false;
      });
    }

    get tableView(){
        return state.campaignReports.filteredData.map(x => ({
          'Campaign' : x.name,
          'Total Customers' : x.total,
          'Contacted' : x.called,
          'Pending Level One' : x.pendingL1,
          'Pending Level Two' : x.pendingL2,
          'Signed Level Two' : x.signedL1,
          'Signed Level Two' : x.signedL2,
          'Has Pricing' : x.pricing,
        }));
    }

  render() {
    return (
        state.campaignReports.loading ? <Loader  absolute colour="#f4f4f4"/> : <Card height="calc(100vh - 66px)" style={{ padding: '16px' }}>
        <Table
          tid="campaign"
          data={this.tableView}
          perPage={50}
          height="calc(100vh - 98px)"
          icons={{
            fullscreen: false,
            download: true
          }}
        />
      </Card>
    );
  }
});
