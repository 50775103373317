import React, { Component } from 'react';
import state from '../State';
import { view } from 'react-easy-state';
import ReactDOM from 'react-dom';
import ExcelHelper from '../plugins/excelHelper';
import Loader from './Loader';

export default view(class Table extends Component {
    constructor(props) {
        super();
        this.props = props;
        this.interval = {};
        this.el = document.createElement('div');
    }
    componentDidUpdate() {
        if (this.props.data !== state.table[this.props.tid].data.raw && !this.props.onData) {
            state.table[this.props.tid].data.raw = this.props.data;
            state.table[this.props.tid].data.keys = this.props.endpoint ? [] : this.props.data.length ? Object.keys(this.props.data[0]).filter(x => x !== '_tableRowColour') : [];
            this.filterData();
        }
        state.table[this.props.tid].loading = this.props.loading ? true : false;
    }
    componentDidMount() {
        if (!state.table[this.props.tid]) state.table[this.props.tid] = {}
        state.table[this.props.tid].data = {
            raw: this.props.endpoint ? [] : this.props.data,
            filtered: [],
            length: 0,
            currentPageIndex: 0,
            keys: this.props.endpoint ? [] : this.props.data.length ? Object.keys(this.props.data[0]).filter(x => x !== '_tableRowColour') : []
        }
        state.table[this.props.tid].loading = this.props.endpoint ? true : this.props.loading ? true : false;
        state.table[this.props.tid].fullscreen = this.props.fullscreen ? true : false;
        state.table[this.props.tid].perPage = this.props.perPage ? this.props.perPage : 100;
        state.table[this.props.tid].search = {
            query: "",
            show: false
        }
        this.filterData();
        document.getElementById('table').appendChild(this.el);
        if (this.props.endpoint) {
            this.updateData();
            if (this.props.interval) {
                this.interval = setInterval(() => {
                    this.updateData();
                }, this.props.interval);
            }
        }
    }
    updateData() {
        state.api.get(this.props.endpoint).then(res => {
            new Promise(cont => {
                if (this.props.onData) {
                    this.props.onData(res.data).then(d => cont(d));
                } else {
                    cont(res.data);
                }
            }).then(data => {
                state.table[this.props.tid].data.raw = data;
                state.table[this.props.tid].data.keys = Object.keys(data[0]).filter(x => x !== '_tableRowColour');
                this.filterData();
                state.table[this.props.tid].loading = false;
            });
        });
    }
    componentWillUnmount() {
        try {
            if (this.props.interval) clearInterval(this.interval);
            document.getElementById('modal').removeChild(this.el);
        } catch (e) {

        }
        delete state.table[this.props.tid];
    }
    filterData() {
        let filtered = state.table[this.props.tid].data.raw.filter(r => {
            let query = state.table[this.props.tid].search.query.toLowerCase();
            if (query) {
                return Object.values(r).filter(v => String(v).toLowerCase().includes(query)).length;
            } else {
                return true;
            }
        });
        state.table[this.props.tid].data.length = filtered.length;
        let totalPages = Math.ceil(filtered.length / state.table[this.props.tid].perPage);
        let perPage = state.table[this.props.tid].perPage;
        state.table[this.props.tid].data.currentPageIndex = state.table[this.props.tid].data.currentPageIndex > totalPages-1 ? 0 : state.table[this.props.tid].data.currentPageIndex;
        state.table[this.props.tid].data.filtered = [...Array(totalPages)].reduce((j, x, i) => {
            j[i] = filtered.slice(i * perPage, i * perPage + perPage);
            return j;
        }, {});
    }
    render() {
        if (!state.table[this.props.tid]) {
            return null;
        } else {
            let maxPageNo = Math.ceil(state.table[this.props.tid].data.length / state.table[this.props.tid].perPage);
            let structure = <div className="table-kit" style={{ height: this.props.height }}>
                {state.table[this.props.tid].loading ? <Loader relative={true} /> :
                Object.keys(state.table[this.props.tid].data.filtered).length === 0 && !state.table[this.props.tid].search.query ? 
                <div className="table-kit-error">
                    <i className="fal fa-exclamation-triangle"></i>
                    <p>No data found</p>
                </div>
                :
                    <div><div className="table-kit-body" style={{ height: "calc(" + this.props.height + " - 35px)" }}>
                        <table>
                            <thead>
                                <tr>{state.table[this.props.tid].data.keys.map(k => <th key={k}>{k === '_actionCell' ? 'Actions' : k}</th>)}</tr>
                            </thead>
                            <tbody>
                                {Object.keys(state.table[this.props.tid].data.filtered).length ? state.table[this.props.tid].data.filtered[state.table[this.props.tid].data.currentPageIndex].map((x, i) => {
                                    let bg = x._tableRowColour ? x._tableRowColour : null;
                                    let row = Object.assign({}, x);
                                    delete row._tableRowColour;
                                    return <tr style={{ background: bg }} key={i} onClick={e => {
                                        this.props.handleClick && this.props.handleClick(row, state.table[this.props.tid].data.raw.findIndex(r => JSON.stringify(r) === JSON.stringify(row)))
                                    }}>{state.table[this.props.tid].data.keys.map((y, k) => {
                                        if (y === '_actionCell') {
                                            return <td key={k} className={row[y].class}>{row[y].icons.map((i,ii) => <i key={ii} className={`fas fa-${i.type}`} title={i.title} onClick={() => setTimeout(i.handleClick,200)}></i>)}</td>
                                        } else {
                                            return <td key={k} dangerouslySetInnerHTML={{ __html: state.validation.stringifyData(row[y]) }}></td>
                                        }
                                    })}</tr>
                                }) : null}
                            </tbody>
                        </table>
                    </div>
                        <div className="table-kit-footer">
                            {this.props.icons && this.props.icons.fullscreen === false ? null : <i className="fal  fa-arrows-alt" title="Fullscreen mode" onClick={() => state.table[this.props.tid].fullscreen = !state.table[this.props.tid].fullscreen} />}
                            {this.props.icons && this.props.icons.download === false ? null : <i className="fal  fa-download" title="Export to Excel" onClick={() => ExcelHelper.toFile({ "export": [].concat.apply([], Object.values(state.table[this.props.tid].data.filtered)) }, `${this.props.tid}.xlsx`)} />}
                            {this.props.icons && this.props.icons.search === false ? null : <i className="fal  fa-search" title="Search" onClick={() => {
                                state.table[this.props.tid].search.show = !state.table[this.props.tid].search.show;
                                state.table[this.props.tid].search.query = "";
                                this.filterData();
                            }} />}
                            {state.table[this.props.tid].search.show && <input placeholder="Search..." autoFocus type="search" value={state.table[this.props.tid].search.query} onInput={e => {
                                state.table[this.props.tid].search.query = e.currentTarget.value;
                                this.filterData();
                            }} />}
                            <div className="table-footer-right">

                                {maxPageNo > 1 &&
                                    <div className="table-pagination"><i className="fal fa-caret-left" title="Previous Page" onClick={() => state.table[this.props.tid].data.currentPageIndex && state.table[this.props.tid].data.currentPageIndex--} /><p>{state.table[this.props.tid].data.currentPageIndex + 1}/{maxPageNo}</p><i className="fal  fa-caret-right" title="Next Page" onClick={() => state.table[this.props.tid].data.currentPageIndex + 1 !== maxPageNo && state.table[this.props.tid].data.currentPageIndex++
                                    } /></div>
                                }

                                <p>{state.table[this.props.tid].data.length.toLocaleString()} rows</p>
                            </div>
                        </div></div>}
            </div>;
            return !state.table[this.props.tid].fullscreen ?
                structure
                : ReactDOM.createPortal(
                    structure, this.el
                )
        }
    }
});