import React, { Component } from 'react';
import Card from '../../generic/Card';
import Table from '../../generic/Table';
import state from '../../State';
import { view } from 'react-easy-state';
import Loader from '../../generic/Loader';

export default view(class DataUsers extends Component {
    componentWillUnmount() {
        state.dataUsers.loading = true;
        state.dataUsers.data = []
    }
    componentDidMount() {
        state.track({
            module: 'DATA',
            action: `LOADED_DATA_USERS`
        });
        state.api.post(`users/find${state.buildGetSelector(['firstName','teamName', 'lastName', 'id', 'email', 'accessPricing', 'accessSales', 'accessAdmin', 'accessReports', 'accessProspects', 'isActive', 'accessToken'])}`,{isActive:true}).then(res => {
            state.dataUsers.data = res.data;
            state.dataUsers.loading = false;
        });
    }
    get tableView() {
        return state.dataUsers.data.map(x => {
            return {
                'Team Name': x.teamName,
                'User Name': `${x.firstName} ${x.lastName}`,
                'Email': x.email,
                'Sales System': x.accessSales,
                'Data Management': x.accessProspects,
                'System Reporting': x.accessReports,
                'Pricing Upload': x.accessPricing,
                'Admin Information': x.accessAdmin
            }
        });
    }
    handleClick = (r,i) => {
        this.props.history.push(state.dataUsers.data[i].id.replace(/-/g,'_-_'));
    }
    render() {
        return (
            state.dataUsers.loading ? <Loader absolute colour="#f4f4f4" /> :
                <Card height="calc(100vh - 66px)" style={{ padding: '16px' }}>
                    <button className={`icon-btn ${state.sales.view !== 'all' ? 'inactive-btn' : ''}`} style={{ marginBottom: '16px' }} onClick={() => this.props.history.push('new-user')}>
                        <i className="fas fa-user"></i>
                        <span>New User</span>
                    </button>
                    <Table
                        tid="users"
                        data={this.tableView}
                        handleClick={this.handleClick}
                        height="calc(100vh - 160px)"
                        icons={{
                            fullscreen: false,
                            download: false
                        }}
                    />
                </Card>
        );
    }
})