/* eslint-disable */
import React, { Component } from 'react';
import state from '../State';
import { view } from 'react-easy-state';
import axios from 'axios';

export default view(class SearchInput extends Component {
    constructor(props) {
        super();
        this.filterResults = this.filterResults.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleHideDropdown = this.handleHideDropdown.bind(this);
    }
    filterResults(e) {
        state.searchInput.nodes[this.props.id].value = e.currentTarget.value;
        if (this.props.onInput) this.props.onInput(e.currentTarget.value);
        let searchQuery = e.currentTarget.value.toLowerCase().trim();
        if (this.props.onSearch) {
            this.props.onSearch(searchQuery).then(data => state.searchInput.nodes[this.props.id].filteredData = data);
        } else {
            if (searchQuery === "") {
                state.searchInput.nodes[this.props.id].filteredData = this.props.data;
            } else {
                state.searchInput.nodes[this.props.id].filteredData = this.props.data.filter(node => Object.keys(node).filter(key => String(node[key]).toLowerCase().indexOf(searchQuery) > -1).length > 0);
            }
        }
    }
    componentDidMount() {
        document.addEventListener('mousedown', this.handleHideDropdown);
        if (!state.searchInput.nodes[this.props.id]) {
            state.searchInput.nodes[this.props.id] = {
                value: this.props.value ? this.props.value : "",
                filteredData: this.props.data
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleHideDropdown);
        delete state.searchInput.nodes[this.props.id];
    }
    handleHideDropdown(e) {
        if (this.searchContainer && !this.searchContainer.contains(e.target)) {
            try {
                if (e.path.findIndex(node => {
                    return node.classList ? node.classList.contains('search-input-results') : false;
                }) === -1) {
                    state.searchInput.focus = false;
                }
            } catch (e) {

            }
        }
    }
    handleSelect(e) {
        let input = e.currentTarget.parentElement.previousSibling;
        let result = state.searchInput.nodes[this.props.id].filteredData[e.currentTarget.getAttribute("data-index")];
        state.searchInput.nodes[this.props.id].value = result.heading;
        this.props.select(result).then(dataToLoad => {
            state.searchInput.nodes[this.props.id].filteredData = dataToLoad;
            state.searchInput.focus = true;
        }).catch(() => state.searchInput.focus = false);
    }
    render() {
        return state.searchInput.nodes[this.props.id] ? <div className="search-input" ref={e => this.searchContainer = e}>
            <input className={this.props.additionalCssClasses ? this.props.additionalCssClasses : ""} value={state.searchInput.nodes[this.props.id].value} type="search" onInput={this.filterResults} placeholder={this.props.placeholder ? this.props.placeholder : "Search..."} onFocus={() => state.searchInput.focus = true} />
            <div ref={e => this.dropdownContainer = e} className="search-input-results" style={{ height: state.searchInput.nodes[this.props.id].filteredData.length > 0 && state.searchInput.focus ? "auto" : "0" }}>
                {
                    state.searchInput.nodes[this.props.id].filteredData.length > 0 ? state.searchInput.nodes[this.props.id].filteredData.map((node, i) =>
                        <div className="search-input-node" key={i} data-index={i} onClick={this.handleSelect}>
                            {node.heading && <p>{node.heading}</p>}
                            {node.subHeading && <p>{node.subHeading}</p>}
                            {node.description && <p>{node.description}</p>}
                        </div>
                    ) : ""
                }
            </div>
        </div> : null
    }
})