/* eslint-disable */
import XLSX from 'xlsx';

class ExcelHelper {
    constructor() {

    }
    fromFile(file) {
        return new Promise(res => {
            let reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = e => {
                let data = new Uint8Array(e.target.result);
                let wb = XLSX.read(data, { type: "array" });
                res(wb.SheetNames.map(name => XLSX.utils.sheet_to_json(wb.Sheets[name])));
            }
        });
    }
    // {name : {header:value}}
    toFile(sheets,fn){
        let wb = { SheetNames: Object.keys(sheets), Sheets: Object.keys(sheets).reduce((j,name) => {
            j[name] = XLSX.utils.json_to_sheet(sheets[name]);
            return j;
        },{})};
        XLSX.writeFile(wb,fn);
    }
}

export default new ExcelHelper();