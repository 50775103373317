import React, { Component } from 'react';

class Card extends Component {
  render() {
    return (
      <div onClick={e=> this.props.handleClick && this.props.handleClick(e)} className={`card ${this.props.className ? this.props.className : ''}`} style={Object.assign({height:this.props.height,width:this.props.width ? this.props.width : '100%'},this.props.style ? this.props.style : {})}>
        {this.props.children}
      </div>
    );
  }
}

export default Card;
