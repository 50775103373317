import React, { Component } from 'react';
import { BrowserRouter, NavLink, Link } from 'react-router-dom';
import Card from '../../generic/Card';
import Table from '../../generic/Table';
import Stat from '../../generic/Stat';
import state from '../../State';
import { view, store } from 'react-easy-state';
import Loader from '../../generic/Loader';
import Planner from '../../generic/Planner';
import SearchInput from '../../generic/SearchInput';
import email from '../../plugins/email';
import Notification from '../../generic/Notification';
import * as Recharts from 'recharts';
import ExcelHelper from '../../plugins/excelHelper';
const { LineChart, Line, BarChart, Bar, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } = Recharts;

export default view(class UploadCalls extends Component {
    componentWillUnmount() {
        state.uploadCalls = {
            loading: false,
            uploaded: false,
            customerID: '',
            callTime: '',
            loadingTitle: {
                main: '',
                sub: ''
            },
            customers: [],
            upload: {
                key: '',
                data: '',
                private: true
            }
        }
    }
    componentDidMount() {
        state.track({
            module: 'DATA',
            action: 'LOADED_UPLOAD_CALLS'
        });
    }
    // retrieveCustomers() {
    //     state.api.post(`customers/find${state.buildGetSelector(['id', 'name', 'nameOnAccount'])}`, { isActive: true, hasCalled: true }).then(res => {
    //         if (res.data.length === 0) {
    //             this.props.history.push('/data-management');
    //         } else {
    //             state.uploadCalls.customers = state.validation.ordersValuesByProperty(res.data, 'name');
    //             state.uploadCalls.loading = false;
    //         }
    //     });
    // }
    handleUpload(files) {
        if (!files[0]) {
            new Notification(`No data found`);
        } else {
            if (files[0].name.split('.').pop().toLowerCase() === 'wav') {
                let reader = new FileReader();
                let b64 = reader.readAsDataURL(files[0]);
                reader.onloadend = () => {
                    state.uploadCalls.upload.key = `.${files[0].name.split('.').pop().toLowerCase()}`;
                    state.uploadCalls.upload.data = reader.result.split('base64,').pop();
                    state.uploadCalls.loadingTitle.main = 'Loading Data';
                    state.uploadCalls.loadingTitle.sub = 'Retrieving customer data from the server';
                    state.uploadCalls.uploaded = true;
                }
            } else {
                new Notification(`WAV files only`);
            }
        }
    }
    assignDocument = () => {
        if(state.uploadCalls.customerID && state.uploadCalls.callTime){
        state.uploadCalls.loadingTitle.main = 'Assigning Call';
        state.uploadCalls.loadingTitle.sub = 'Assigning data to the server';
        state.uploadCalls.loading = true;
        state.uploadCalls.upload.key = state.uploadCalls.customerID + '/' + state.uploadCalls.callTime.replace(':','_') + state.uploadCalls.upload.key;
        state.api.post(`calls`,{
            key : state.uploadCalls.upload.key,
            dateCreated : state.uploadCalls.callTime,
            customerID : state.uploadCalls.customerID,
            isActive : true
        }).then(d => {
          console.log(d);  
        });
        state.api.post('storage', state.uploadCalls.upload).then(data => {
            new Notification('Successfully assigned call');
            this.componentWillUnmount();
        });
    } else {
        new Notification('Select a customer and call time');
    }
    }
    retrieveCustomersByQuery(q) {
        return new Promise(res => {
            state.api.post(`customers/search/15${state.buildGetSelector(['id', 'name'])}`, {
                name: q
            }).then(result => {
                res(result.data.map(x => {
                    return {
                        heading: x.name,
                        id: x.id
                    }
                }));
            })
        })
    }
    render() {
        return (state.uploadCalls.loading ?
            <div className="grid-center" style={{ height: 'calc(100vh - 66px)' }}>
                <Card height="400px" width="600px" style={{ padding: '30px 16px' }}>
                    <h1 className="with-st">{state.uploadCalls.loadingTitle.main}<small>{state.uploadCalls.loadingTitle.sub}</small></h1>
                    <Loader relative height="275px" />
                </Card>
            </div> :
            state.uploadCalls.uploaded ?
                <div className="grid-center" style={{ height: 'calc(100vh - 66px)' }}>
                    <Card height="400px" width="600px" style={{ padding: '30px 16px' }}>
                        <div>
                            <h1 className="with-st">Assign Call<small>Select a customer and the call time</small></h1>
                            <br /><br />
                            <div className="center-contents">
                                <label>Customer</label>
                                <SearchInput
                                    id="uploadCallsSearch"
                                    select={e => {
                                        state.uploadCalls.customerID = e.id;
                                        return new Promise((r,j) => j());
                                    }}
                                    data={[]}
                                    onSearch={q => this.retrieveCustomersByQuery(q)}
                                    placeholder="Search for customer..."
                                    value={''}
                                />

                                {/* <select value={state.uploadCalls.customerID} onChange={e => state.uploadCalls.customerID = e.currentTarget.value}>
                                    {state.uploadCalls.customers.map((x, i) => <option value={x.id}>{x.name}</option>)}
                                </select> */}
                                <label>Call Time</label>
                                <input type='datetime-local' onChange={e => state.uploadCalls.callTime = e.currentTarget.value}/>
                                <button className={`icon-btn`} style={{ marginTop: '16px' }} onClick={this.assignDocument}>
                                    <i className="fas fa-file-upload"></i>
                                    <span>Assign Call</span>
                                </button>
                            </div>
                        </div>
                    </Card>
                </div>
                :
                <div className="grid-center" style={{ height: 'calc(100vh - 66px)' }}>
                    <Card height="400px" width="600px">
                        <div className="center-contents">
                            <h1 className="with-st">Upload Call<small>You can select the time and assign to a customer</small></h1>
                            <label htmlFor="fileUploader" className="file-uploader" />
                            <input type="file" id="fileUploader" onChange={e => this.handleUpload(e.target.files)} />
                        </div>
                    </Card>
                </div>
        );
    }
})