import React, { Component } from 'react';
import Loader from '../../generic/Loader';
import state from '../../State';
import { view } from 'react-easy-state';
import Stat from '../../generic/Stat';
import Card from '../../generic/Card';
import DashboardStat from '../../generic/DashboardStat';
import * as Recharts from 'recharts';
const {AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } = Recharts;

export default view(class Dashboard extends Component {
  componentDidMount() {
    if (state.user.accessProspects || state.user.accessReports) {
      state.dashboard.userView = false;
      state.api.post(`users/find${state.buildGetSelector(["id", "firstName", "lastName"])}`, {
        accessSales: true,
        isActive: true
      }).then(res => state.dashboard.users = res.data);
    }
    state.dashboard.loading = true;
    this.retrieveData();
    this.retrieveDataLoop();
  }
  retrieveDataLoop(){
    state.dashboard.loop = setInterval(this.retrieveData,10000);
  }
  retrieveData(){
    let user = state.user.accessProspects || state.user.accessReports ? state.dashboard.userView ? state.dashboard.users[state.dashboard.userView].id : '' : state.user.id;
    state.api.get(`common/dashboard/system/${user}`).then(res => {
      if(res.data.userID ? res.data.userID : '' === user){
        res.data.dialTargetMet = (300*(state.user.accessProspects || state.user.accessReports ? state.dashboard.userView ? 1 : state.dashboard.users.length : 1)) / 8 * (new Date().getHours() - 9 ? new Date().getHours() - 9 : 1) <= res.data.dialled;
        res.data.callDurationByHour = res.data.callDurationByHour.map(x => {
          return {
            time : x.hour,
            "Total Call Duration (minutes)" : Math.ceil(x.duration / 1000 / 60)
          }
        });
        res.data.callTypesByHour = res.data.callTypesByHour.map(x => {
          return {
            time : x.hour,
            "Total Answered Calls" : x.answered,
            "Total Unanswered Calls" : x.unanswered,
            "Total Unusable Numbers" : x.unusable
          }
        });
        state.dashboard.data = res.data;
        state.dashboard.loading = false;
      }
    })
  }
  componentWillUnmount() {
    state.dashboard.loading = true;
    clearInterval(state.dashboard.loop);
    state.dashboard.data = [];
  }
  render() {
    return (
      state.dashboard.loading ? <Loader absolute colour="#f4f4f4" /> : state.user.accessProspects || state.user.accessReports ?
        <div>
        <select className="dashboard-view-selector" value={state.dashboard.userView} onChange={e => {
          state.dashboard.userView = e.currentTarget.value;
          state.dashboard.loading = true;
          this.retrieveData();
        }}>
          <option value="">All Data</option>
          {state.dashboard.users.map((x, i) => <option key={i} value={i}>{x.firstName} {x.lastName}</option>)}
        </select>
        <div className="dashboard-container">
          <h2>Today's Insights<small>Check performance for today against targets</small></h2>
          <div className="grid-1 grid-gap-big">
            <div className="grid-gap-big grid-4">
              <DashboardStat height="220px" heading="Callbacks Scheduled" value={state.dashboard.data.callbacks} description="Callbacks that have been planned for today" textColour="#75b447" />
              <DashboardStat height="220px" heading="Numbers Dialled" value={state.dashboard.data.dialled.toLocaleString()} icon={state.dashboard.data.dialTargetMet ? 'chevron-up' : 'chevron-down'} description="Numbers that you have dialled today" textColour={state.dashboard.data.dialTargetMet ? '#75b447' : '#d51254'} />
              <DashboardStat height="220px" heading="LOAs Sent" value={state.dashboard.data.loaSent} icon={state.dashboard.data.loaSent ? 'chevron-up' : ''} description="LOAs that have been sent out today" textColour={state.dashboard.data.loaSent ? '#75b447' : '#6d6d6d'} />
              <DashboardStat height="220px" heading="LOAs Signed" value={state.dashboard.data.loaSigned} icon={state.dashboard.data.loaSigned ? 'chevron-up' : ''} description="LOAs that have been signed today" textColour={state.dashboard.data.loaSigned ? '#75b447' : '#6d6d6d'} />
            </div>
            <div className="grid-gap-big dashboard-chart-con">
              <Card height="400px">
              <h2 className="dashboard-heading">Call Duration by Hour</h2>
            <ResponsiveContainer >
              <AreaChart data={state.dashboard.data.callDurationByHour} margin={{ bottom: 50, right: 50 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="time" tick={{ fontSize: 12 }} />
                <YAxis tick={{ fontSize: 12 }} />
                <Tooltip offset={0} itemStyle={{ fontSize: 14 }} labelStyle={{ fontSize: 14 }} />
                <Legend />
                <Area isAnimationActive={true} type="monotone" dataKey="Total Call Duration (minutes)" stackId="a" stroke="#60BFC7" fill="#60BFC7" />
              </AreaChart>
            </ResponsiveContainer>
              </Card>
              <Card height="400px">
              <h2 className="dashboard-heading">Calls by Hour</h2>
            <ResponsiveContainer >
              <AreaChart data={state.dashboard.data.callTypesByHour} margin={{ bottom: 50, right: 50 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="time" tick={{ fontSize: 12 }} />
                <YAxis tick={{ fontSize: 12 }} />
                <Tooltip offset={0} itemStyle={{ fontSize: 14 }} labelStyle={{ fontSize: 14 }} />
                <Legend />
                <Area isAnimationActive={true} type="monotone" dataKey="Total Answered Calls" stackId="a" stroke="#76B44D" fill="#76B44D" />
                <Area isAnimationActive={true} type="monotone" dataKey="Total Unanswered Calls" stackId="a" stroke="#D51F57" fill="#D51F57" />
                <Area isAnimationActive={true} type="monotone" dataKey="Total Unusable Numbers" stackId="a" stroke="#EC9C3A" fill="#EC9C3A" />
              </AreaChart>
            </ResponsiveContainer>
              </Card>
            </div>
          </div>
          <h2>Overall Statistics<small>See how the portfolio looks overall</small></h2>
          <div className="grid-1 grid-gap-big">
            <div className="grid-gap-big grid-4">
              <DashboardStat height="220px" heading="LOA Conversion Ratio" value={state.dashboard.data.loaOneSigned + state.dashboard.data.loaTwoSigned === 0 ? 'N/A' : `${Math.ceil(state.dashboard.data.ops / (state.dashboard.data.loaOneSigned + state.dashboard.data.loaTwoSigned))} : 1`}  description="Opportunities that have signed a Letter of Authority" textColour="#75b447" />
              <DashboardStat height="220px" heading="New Leads" value={state.dashboard.data.leads.toLocaleString()}  icon={state.dashboard.data.leads < 100 ? "chevron-down" : ""} description="Leads that haven't been contacted yet" textColour={state.dashboard.data.leads > 100 ? "#6d6d6d" : "#d51254"} />
              <DashboardStat height="220px" heading="Opportunities" value={state.dashboard.data.ops.toLocaleString()}  description="Leads that have been contacted" textColour={state.dashboard.data.ops ? '#75b447' : '#6d6d6d'} />
              <DashboardStat height="220px" heading="Pending LOAs" value={state.dashboard.data.loaPending}  description="Opportunities that have an LOA pending" textColour={state.dashboard.data.loaPending ? '#75b447' : '#6d6d6d'} />
              <DashboardStat height="220px" heading="Signed LOA Level One" value={state.dashboard.data.loaOneSigned}  description="Customers that have a signed LOA Level One" textColour={state.dashboard.data.loaOneSigned ? '#75b447' : '#6d6d6d'} />
              <DashboardStat height="220px" heading="Signed LOA Level Two" value={state.dashboard.data.loaTwoSigned}  description="Customers that have a signed LOA Level Two" textColour={state.dashboard.data.loaTwoSigned ? '#75b447' : '#6d6d6d'} />
              <DashboardStat height="220px" heading="Available to Sell" value={state.dashboard.data.availableToSell}  description="Customers that have pricing ready" textColour={state.dashboard.data.availableToSell ? '#75b447' : '#6d6d6d'} />
              <DashboardStat height="220px" heading="Customers Won" value={state.dashboard.data.won}  description="Customers that have confirmed pricing" textColour={state.dashboard.data.won ? '#75b447' : '#6d6d6d'} />
            </div>
          </div>
        </div></div> :
        state.user.accessSales ? 
        <div className="dashboard-container">
        <h2>Quick Actions<small>Jump to a section within the system</small></h2>
            <div className="grid-gap-big grid-4">
            <Stat height="200px" label="Sales Home" icon="phone flip-phone" colour="#75b447" handleClick={()=>this.props.history.push('/sales-system')} />
            <Stat height="200px" label="New Prospect" icon="user" colour="#60BFC7" handleClick={()=>this.props.history.push('/sales-system/new-prospect')} />
            <Stat height="200px" label="Knowledge Base" icon="info" colour="#2F7CAE" handleClick={()=>state.helper.show = !state.helper.show} />
            <Stat height="200px" label="Email Portal" icon="envelope" colour="#5d5d5d" handleClick={()=>window.open('https://portal.office.com/','_blank')} />
          </div>
          <h2>Today's Insights<small>Check performance for today against targets</small></h2>
            <div className="grid-gap-big grid-4">
              <DashboardStat height="220px" heading="Callbacks Scheduled" value={state.dashboard.data.callbacks} description="Callbacks that have been planned for today" textColour="#75b447" />
              <DashboardStat height="220px" heading="Numbers Dialled" value={state.dashboard.data.dialled.toLocaleString()} icon={state.dashboard.data.dialTargetMet ? 'chevron-up' : 'chevron-down'} description="Numbers that you have dialled today" textColour={state.dashboard.data.dialTargetMet ? '#75b447' : '#d51254'} />
              <DashboardStat height="220px" heading="LOAs Sent" value={state.dashboard.data.loaSent} icon={state.dashboard.data.loaSent ? 'chevron-up' : ''} description="LOAs that have been sent out today" textColour={state.dashboard.data.loaSent ? '#75b447' : '#6d6d6d'} />
              <DashboardStat height="220px" heading="LOAs Signed" value={state.dashboard.data.loaSigned} icon={state.dashboard.data.loaSigned ? 'chevron-up' : ''} description="LOAs that have been signed today" textColour={state.dashboard.data.loaSigned ? '#75b447' : '#6d6d6d'} />
          </div>
        </div> :
        null
    );
  }
})
