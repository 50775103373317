import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import state from '../../State';
import { view } from 'react-easy-state';
import config from '../../config';

export default view(class Navigation extends Component {
    render() {
        return (
            <div className="app-options">
                {state.timer.show &&
                    <div className="call-nav-overlay">
                        <span className="timer">{state.timer.value}</span>
                        <p className="script">
                            <b>Opener 1</b><br />
                            Hi good morning/afternoon is it possible to speak to the person whom is in charge of the utilities/electricity and gas for the business?<br /><br />
                            <b>Opener 2</b><br />
                            Hi good morning/afternoon is it possible to speak to (Name on Account) please? <br />
                            More casual approach may benefit, can you ‘pop’ me through<br />
                            Is (Name on Account) there please, I just need to quickly clarify something we discussed last week<br /><br />

                            <b>Key questions</b><br />
                            Who is your current supplier<br />
                            Have you used a broker before<br />
                            Do you have any outstanding issues with your current supplier as we can fix these whilst we tender<br />
                            Have you read much on the current state of the Energy Market<br />
                            What contract length do you normally go for<br />
                            Is there any suppliers you don’t want to work with<br />
                            Are you interested in renewable Energy at  cost neutral <br /><br />

                            <b>The DM is available</b><br />

                            My name is ………………. And I am from {config.meta.title} and I would like to briefly talk to you about your energy requirements at Company Limited.<br /><br />

                            I work for one of the leading energy consultants in the UK and would like to help you reduce the amount you spend on your energy contracts. Would it be possible to have a chat about how you currently purchase your energy and which supplier it is through? <br /><br />

                            Let the DM speak ask much as possible, don’t try and sell to them this is all about extracting as much information as possible<br /><br />

                            Interject and ask the DM if he currently uses a broker, if so is he happy with them? If not why isn’t he happy with them?<br />
                            Is it Price? We have relationships with 35 UK suppliers and we tender to each of those suppliers so we are one of the sharpest in the market place. We are fully independent and always offer you the best offering.<br />
                            Is it Service? We fully manage clients’ accounts, including invoice validation and supplier liaison. We have one of the highest client retention rates in the industry because of the price and service we offer.<br />
                            <br />
                            When they have given you these details, give a brief over view of how it works and how we help businesses save time, energy and money with our consultancy service. <br /><br />

                            Due to current Market conditions, associated with Brexit and geopolitical instability in the middle east, businesses are having to operate in the most expensive Energy market for the last 8 years with average prices up 25-35*% since 2016, the reason businesses use our services is we are often able to beat the market and use supplier relationships to get better contracts for our clients
<br /><br />
                            If they use a broker they will understand you require a LOA to price, ask for their email address and send it over, explain the level 1 section is to tender their supply and price. If they are happy with the prices you send the LOA back over for them to sign the level 2 and we LOCK in the contract.
<br /><br />
                            We handle the termination with their existing supplier, taking all the hassle away.
<br /><br />
                            If they haven’t used a broker before or never signed an LOA;<br />
                            We just require an LOA to gather current contractual information for comparison purposes, so you can see the offer broken down cost equated against your current spend. We also require it to tender the supply.
<br /><br />
                            The LOA is a simple permission slip and is standard within the industry.
                        </p>
                    </div>
                }
                <NavLink to="/dashboard" activeClassName="selected">
                    <i className="fa fa-tachometer-average" title="Dashboard"></i>
                    <span>Dashboard</span>
                </NavLink>
                {state.user.accessSales &&
                    <NavLink to="/sales-system" activeClassName="selected">
                        <i className="fa fa-phone flip-phone" title="Sales System"></i>
                        <span>Sales System</span>
                    </NavLink>}

                {state.crumb.includes('sales-system') &&

                    [<NavLink key={0} exact to="/sales-system" className="sub-option" activeClassName="selected">
                        <i className="fa fa-home" title="Sales System - Home"></i>
                        <span>Home</span>
                    </NavLink>,
                    <NavLink key={1} to="/sales-system/callback-calendar" className="sub-option" activeClassName="selected">
                        <i className="fa fa-calendar-alt" title="Sales System - Callback Calendar"></i>
                        <span>Callback Calendar</span>
                    </NavLink>,
                    <NavLink key={2} to="/sales-system/new-prospect" className="sub-option" activeClassName="selected">
                        <i className="fa fa-user" title="Sales System - New Prospect"></i>
                        <span>New Prospect</span>
                    </NavLink>]

                }

                {state.user.accessProspects && <NavLink to="/data-management" activeClassName="selected">
                    <i className="fa fa-database" title="Data Management"></i>
                    <span>Data Management</span>
                </NavLink>}


                {state.crumb.includes('data-management') &&

                    [<NavLink key={0} exact to="/data-management" className="sub-option" activeClassName="selected">
                        <i className="fa fa-home" title="Data Management - Home"></i>
                        <span>Home</span>
                    </NavLink>,
                    <NavLink key={1} to="/data-management/upload-prospects" className="sub-option" activeClassName="selected">
                        <i className="fa fa-upload" title="Data Management - Upload Prospects"></i>
                        <span>Upload Prospects</span>
                    </NavLink>,
                    <NavLink key={2} to="/data-management/manage-users/" className="sub-option" activeClassName="selected">
                        <i className="fa fa-user" title="Data Management - Manage Users"></i>
                        <span>Manage Users</span>
                    </NavLink>,
                    <NavLink key={3} to="/data-management/data-migration" className="sub-option" activeClassName="selected">
                        <i className="fa fa-tasks" title="Data Management - Data Migration"></i>
                        <span>Data Migration</span>
                    </NavLink>,
                    <NavLink key={4} to="/data-management/information-centre/" className="sub-option" activeClassName="selected">
                    <i className="fa fa-info" title="Data Management - Information Centre"></i>
                    <span>Information Centre</span>
                </NavLink>,
                    <NavLink to="/data-management/upload-calls/" className="sub-option" activeClassName="selected">
                    <i className="fa fa-headset" title="Data Management - Upload Calls"></i>
                    <span>Upload Calls</span>
                </NavLink>,
                <NavLink to="/data-management/campaign-report/" className="sub-option" activeClassName="selected">
                <i className="fa fa-file" title="Data Management - Campaign Report"></i>
                <span>Campaign Report</span>
            </NavLink>
                    ]


                }

                {state.user.accessReports && <NavLink to="/system-reporting" activeClassName="selected" >
                    <i className="fa fa-download" title="System Reporting"></i>
                    <span>System Reporting</span>
                </NavLink>}

                {(state.crumb.includes('system-reporting') && state.user.accessReports) &&

                    [<NavLink key={0} exact to="/system-reporting" className="sub-option" activeClassName="selected">
                        <i className="fa fa-home" title="System Reporting - Home"></i>
                        <span>Home</span>
                    </NavLink>,
                    <NavLink key={1} to="/system-reporting/sales-tracking" className="sub-option" activeClassName="selected">
                        <i className="fa fa-fingerprint" title="System Reporting - Agent Tracking"></i>
                        <span>Agent Tracking</span>
                    </NavLink>,
                    <NavLink key={2} to="/system-reporting/sales-analysis" className="sub-option" activeClassName="selected">
                        <i className="fa fa-user-cog" title="System Reporting - Agent Analysis"></i>
                        <span>Agent Analysis</span>
                    </NavLink>]

                }

                {!state.user.accessReports && state.user.accessAgentOverview && <NavLink to="/system-reporting/sales-tracking" activeClassName="selected" >
                    <i className="fa fa-fingerprint" title="System Reporting - Agent Tracking"></i>
                    <span>Agent Tracking</span>
                </NavLink>}

                {/* {state.user.accessPricing && <NavLink to="/pricing-upload" activeClassName="selected" >
                    <i className="fa fa-credit-card" title="Pricing Upload"></i>
                    <span>Pricing Upload</span>
                </NavLink>}

                {state.crumb.includes('pricing-upload') &&

                    [<NavLink key={0} exact to="/pricing-upload" className="sub-option" activeClassName="selected">
                        <i className="fa fa-home" title="Pricing Upload - Home"></i>
                        <span>Home</span>
                    </NavLink>,
                    <NavLink key={1} to="/pricing-upload/upload-document" className="sub-option" activeClassName="selected">
                        <i className="fa fa-upload" title="Pricing Upload - Upload Document"></i>
                        <span>Upload Document</span>
                    </NavLink>]

                } */}

                {(state.user.accessAdmin || state.user.accessPricing) && <NavLink to="/admin-information" activeClassName="selected">
                    <i className="fa fa-lock-alt" title="Admin Information"></i>
                    <span>Admin Information</span>
                </NavLink>} 

                {state.crumb.includes('admin-information') &&

                    [<NavLink key={0} exact to="/admin-information" className="sub-option" activeClassName="selected">
                        <i className="fa fa-home" title="Admin Information - Home"></i>
                        <span>Home</span>
                    </NavLink>,
                    <NavLink key={2} to="/admin-information/customer-calls" className="sub-option" activeClassName="selected">
                        <i className="fa fa-headset" title="Admin Information - Customer Calls"></i>
                        <span>Customer Calls</span>
                     </NavLink>]

                }
            </div>
        );
    }
})


