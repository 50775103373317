import React, { Component } from 'react';
import Card from '../../generic/Card';
import Loader from '../../generic/Loader';
import Table from '../../generic/Table';
import Stat from '../../generic/Stat';
import state from '../../State';
import { view } from 'react-easy-state';

export default view(class Dashboard extends Component {
    componentDidMount() {
        state.systemTracking.data = [];
        this.retrieveTracking();
    }
    retrieveTracking = () => {
        state.systemTracking.loading = true;
        state.api.get(`tracking/scope/${state.systemTracking.dates.from}/${state.systemTracking.dates.to}`).then(res => {
            state.systemTracking.data = state.validation.ordersValuesByProperty(res.data,'answeredCalls',true);
            state.systemTracking.loading = false;
        })
    }
    componentWillUnmount() {
        state.systemTracking.loading = true;
        state.systemTracking.data = [];
    }
    render() {
        return (
            state.systemTracking.loading ? <Loader absolute colour="#f4f4f4" /> :
                <div className="grid-1 grid-gap-big">
                    <Card className="report-filters grid-3" height="auto" style={{ padding: '4px 16px 16px 16px' }}>
                        <div>
                            <label htmlFor="dataSource">Date Range</label>
                            <div className="flex-row flex-row-padder">
                                <input type="datetime-local" value={state.systemTracking.dates.from} onChange={e => {
                                    state.systemTracking.dates.from = e.currentTarget.value;
                                }} />
                                <input type="datetime-local" value={state.systemTracking.dates.to} onChange={e => {
                                    state.systemTracking.dates.to = e.currentTarget.value;

                                }} />

                                <button className={`icon-btn`} onClick={this.retrieveTracking}>
                                    <i style={{ display: 'inline' }} className="fas fa-search"></i>
                                    <span>Search</span>
                                </button>

                            </div>
                        </div>

                    </Card>
                    <Card height="calc(100vh - 180px)" style={{ padding: '16px' }}>

                                    <Table
                                        tid="tracking"
                                        data={state.systemTracking.data.map(x => {
                                            let time = 'N/A';
                                            if(x.callTime !== 0){
                                                if (x.callTime < 60) {
                                                    time = x.callTime + ' seconds';
                                                } else if (x.callTime < 3600) {
                                                    let minutes = Math.floor(x.callTime / 60);
                                                    let seconds = Math.floor(x.callTime - (minutes * 60));
                                                    time = `${minutes} minutes ${seconds} seconds`;
                                                } else {
                                                    let hours = Math.floor(x.callTime / 60 / 60);
                                                    let minutes = Math.floor(x.callTime / 60 - (hours * 60));
                                                    let seconds = Math.floor(x.callTime - (minutes * 60) - (hours * 60 * 60));
                                                    time = `${hours} hours ${minutes} minutes ${seconds} seconds`;
                                                }
                                            }
                                            return {
                                                "Agent" : x.name,
                                                "Answered Calls" : x.answeredCalls,
                                                "LOAs Sent" : x.loaSent,
                                                "LOAs Signed" : x.loaSigned,
                                                "Callbacks Set" : x.callbackSet,
                                                "Unanswered Calls": x.unansweredCalls,
                                                "Call Time": time
                                            }
                                        })}
                                        height="calc(100vh - 220px)"
                                        icons={{
                                            fullscreen: false
                                        }}
                                    />
                    </Card>
                </div>
        );
    }
});