import React, { Component } from 'react';
import Card from '../../generic/Card';
import Loader from '../../generic/Loader';
import state from '../../State';
import { view } from 'react-easy-state';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';

export default view(class SystemSearch extends Component {
    constructor() {
        super();
        this.el = document.createElement('div');
    }
    componentDidMount() {
        document.getElementById('search').appendChild(this.el);
        this.searchInput.focus();
    }
    componentWillUnmount() {
        state.search.value = '';
        state.search.open = false;
        document.getElementById('search').removeChild(this.el);
    }
    handleExit(e){
        state.showSearch = false;
    }
    handleSearchQuery = (e) => {
        state.search.loading = true;
        let val = e.currentTarget.value;
        state.search.value = val;
        state.search.open = state.search.value !== '';
        setTimeout(() => this.handleRetrieveData(val),500);
    }
    handleRetrieveData(query){
        if(query === state.search.value && query){
            state.api.get(`common/search?query=${query}`).then(res => {
                state.search.data = Object.keys(res.data).map(key => {
                  if(key === 'customers'){
                      return {
                          crumb : ['Sales System','Customer Profile'],
                          values : res.data[key].map(x => {
                              return {
                                  value : x.name,
                                  pathExt : encodeURIComponent(x.name.toLowerCase())
                              }
                          }),
                          path : `/sales-system/`
                      }
                  } else if (key === 'users'){
                    return {
                        crumb : ['Data Management', 'Manage Users', 'User Profile'],
                        values : res.data[key].map(x => {
                            return {
                                value : `${x.firstName} ${x.lastName}`,
                                pathExt : x.id.replace(/-/g,'_-_')
                            }
                        }),
                        path : '/data-management/manage-users/'
                    }
                  }
                });
                console.log(state.search.data);
                state.search.loading = false
            })
        }
    }
    get resultsView(){
        return state.search.data.reduce((arr, obj) => {
            obj.values.forEach(item => {
                arr.push(<Link to={obj.path + item.pathExt} onClick={() => state.showSearch = false}>
                    <p>{item.value}</p>
                    <ul>{obj.crumb.map(x => <li>{x}</li>)}</ul>
                </Link>)
            })
            return arr;
        },[])
    }
    render() {
        return ReactDOM.createPortal(
            <div className="search-con" onClick={this.handleExit}>
                <Card className={`${state.search.open ? 'search-results-open' : ''} search-results`} handleClick={e => {
                  e.preventDefault();
                  e.stopPropagation();  
                }}>
                    <div className="search-input">
                        <i className="fas fal fa-search"/>
                        <input type="search" placeholder="Search System" ref={e => { this.searchInput = e }} value={state.search.value} onChange={this.handleSearchQuery} />
                        {state.search.open ?
                        <div className="search-results-con">
                            {state.search.loading ? <Loader relative/> : this.resultsView}
                        </div>
                        : null}
                    </div>
                </Card>
            </div>, this.el
        )
    }
});
