/* eslint-disable */
class DateKit {
    init() {
        Object.defineProperty(Date.prototype, "toFormat", {
            value: function toFormat(format) {
                var date = this;
                var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
                var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                var dateCodes = {
                    dddd() {
                        return days[date.getDay()];
                    },
                    dd() {
                        return ('0' + date.getDate()).slice(-2);
                    },
                    MMMM() {
                        return months[date.getMonth()];
                    },
                    MM() {
                        return ('0' + (date.getMonth() + 1)).slice(-2);
                    },
                    yyyy() {
                        return date.getFullYear();
                    },
                    yy() {
                        return String(date.getFullYear()).substr(2, 2);
                    },
                    hh() {
                        return ('0' + date.getHours()).slice(-2);
                    },
                    mm() {
                        return ('0' + date.getMinutes()).slice(-2);
                    },
                    ss() {
                        return ('0' + date.getSeconds()).slice(-2);
                    },
                }
                Object.keys(dateCodes).forEach(code => {
                    if (format.indexOf(code) != -1) {
                        format = format.replace(code, dateCodes[code]());
                    }
                });
                return format;
            }
        });
        Object.defineProperty(Date.prototype, "addDays", {
            value: function addDays(n) {
                return new Date(this.setDate(this.getDate() + n));
            }
        });
        Object.defineProperty(Date.prototype, "addMonths", {
            value: function addMonths(n) {
                return new Date(this.setMonth(this.getMonth() + n));
            }
        });
        Object.defineProperty(Date.prototype, "addYears", {
            value: function addYears(n) {
                return new Date(this.setYear(this.getFullYear() + n));
            }
        });
        Object.defineProperty(Date.prototype, "addSeconds", {
            value: function addSeconds(n) {
                return new Date(this.setSeconds(this.getSeconds() + n));
            }
        });
        Object.defineProperty(Date.prototype, "addMilliseconds", {
            value: function addMilliseconds(n) {
                return new Date(this.setMilliseconds(this.getMilliseconds() + n));
            }
        });
        Object.defineProperty(Date.prototype, "addMinutes", {
            value: function addMinutes(n) {
                return new Date(this.setMinutes(this.getMinutes() + n));
            }
        });
        Object.defineProperty(Date.prototype, "addHours", {
            value: function addHours(n) {
                return new Date(this.setHours(this.getHours() + n));
            }
        });
        Object.defineProperty(Date.prototype, "differenceDays", {
            value: function differenceDays(d) {
                return Math.round(Math.abs(this - d) / 86400000);
            }
        });
        Object.defineProperty(Date.prototype, "differenceHours", {
            value: function differenceHours(d) {
                return Math.round(Math.abs(this - d) / 3600000);
            }
        });
        Object.defineProperty(Date.prototype, "differenceMinutes", {
            value: function differenceMinutes(d) {
                return Math.round(Math.abs(this - d) / 60000);
            }
        });
        Object.defineProperty(Date.prototype, "differenceSeconds", {
            value: function differenceSeconds(d) {
                return Math.round(Math.abs(this - d) / 1000);
            }
        });
        Object.defineProperty(Date.prototype, "differenceMilliseconds", {
            value: function differenceMilliseconds(d) {
                return Math.round(Math.abs(this - d));
            }
        });
        Object.defineProperty(Date.prototype, "getFirstDayOfWeek", {
            value: function getFirstDayOfWeek() {
                return (new Date(this.setDate(this.getDate() - this.getDay() +1)));
            }
        });
    }
}

export default new DateKit().init();