/* eslint-disable */
export default class IcsHelper {
    constructor(props) {
        this.props = props;
    }
    download() {
        let link = document.createElement('a');
        link.setAttribute('href', encodeURI(`data:text/plain;charset=utf-8,` + this.raw));
        link.setAttribute('download', `${this.props.title}.ics`);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }
    get raw() {
        return `BEGIN:VCALENDAR
PRODID:-//Microsoft Corporation//Outlook 12.0 MIMEDIR//EN
VERSION:2.0
METHOD:PUBLISH
X-MS-OLK-FORCEINSPECTOROPEN:TRUE
BEGIN:VEVENT
CLASS:PUBLIC
DESCRIPTION:${this.props.description}
DTEND:${this.props.end.toJSON().replace(/-|:/g, '').split('.')[0]}Z
DTSTART:${this.props.start.toJSON().replace(/-|:/g, '').split('.')[0]}Z
PRIORITY:5
SEQUENCE:0
SUMMARY;LANGUAGE=en-us:${this.props.title}
TRANSP:OPAQUE
UID:${'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
                let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            })}
X-MICROSOFT-CDO-BUSYSTATUS:BUSY
X-MICROSOFT-CDO-IMPORTANCE:1
X-MICROSOFT-DISALLOW-COUNTER:FALSE
X-MS-OLK-ALLOWEXTERNCHECK:TRUE
X-MS-OLK-AUTOFILLLOCATION:FALSE
X-MS-OLK-CONFTYPE:0
BEGIN:VALARM
TRIGGER:-PT1440M
ACTION:DISPLAY
DESCRIPTION:Reminder
END:VALARM
END:VEVENT
END:VCALENDAR`
    }
}