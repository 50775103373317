import { store } from 'react-easy-state';
import axios from 'axios';
import Cookies from 'universal-cookie';
import config from './config';
const COOKIES = new Cookies();

let state = store(
  {
    nightMode: false,
    hideSidebar: false,
    showSearch: false,
    search : {
      value : '',
      open : false
    },
    customerCalls: {
           data : [],
            loading : true
          },
          uploadCalls: {
            loading: false,
            uploaded: false,
            customerID: '',
            callTime: '',
            loadingTitle: {
              main: '',
              sub: ''
            },
            customers: [],
            upload: {
              key: '',
              data: '',
              private: true
            }
          },
    newProspect: {
      data: {
        name: '',
        nameOnAccount: '',
        telephone: ''
      }
    },
    agentAnalysis: {
      loading: true,
      stats: {
        totalCustomers: 0,
        prospectPipeline: 0,
        totalCalls: 0,
        averageCallTime: 0,
        loasSent: 0,
        loasSigned: 0,
        loaConverisonRate: 0,
        totalCallTime: 0,
        longestCallTime: 0,
        unansweredCalls: 0,
        unusableCalls: 0,
        lastActivity: 0,
        lastCall: 0,
        availableToSell: 0,
        customersClosed: 0
      },
      dates: {
        from: new Date().toISOString().split('T')[0] + 'T00:00',
        to: new Date().toISOString().split('T')[0] + 'T23:59'
      },
      userIndex: 0,
      users: []
    },
    arrangeCallback: {
      loading: true,
      customerID: '',
      customerName: '',
      customerNotes: ''
    },
    dataMigration : {
      loading: true,
      datasets: [],
      addingDataset: false,
      users: [],
      datasetForm: {
        filter : "",
        action : "",
        value : "",
        customerName : ""
      }
    },
    deactivateClient: {
      loading: true,
      customerID: '',
      customerName: ''
    },
    dataAssignment: {
      loading: true,
      users: [],
      data: [],
      selected: [],
      assignUsers: [],
      allow: false,
      selectFromAgent: '',
      option: 'deactivate',
      assigning: false,
      assigningTitle: {
        main: '',
        sub: ''
      }
    },
    crumb: [],
    table: {},
    customerCall: {
      loading: true,
      data: []
    },
    timer: {
      show: false,
      value: '00:00:00',
      start: () => {
        let seconds = 0;
        state.timer.value = '00:00:00';
        let inv = setInterval(() => {
          if (!state.timer.show) clearInterval(inv);
          seconds++;
          let hours = Math.floor(seconds / 60 / 60);
          let minutes = Math.floor(seconds / 60 - (hours * 60));
          let secondsLeft = Math.floor(seconds - (minutes * 60) - (hours * 60 * 60));
          state.timer.value = `${hours.toString().length < 2 ? '0' + hours : hours}:${minutes.toString().length < 2 ? '0' + minutes : minutes}:${secondsLeft.toString().length < 2 ? '0' + secondsLeft : secondsLeft}`;
        }, 1000);
      }
    },
    dataUsers: {
      loading: true,
      data: []
    },
    dataInformationCentre: {
      loading: true,
      data: []
    },
    userProfile: {
      loading: true,
      data: []
    },
    adminEmail: {
      loading: true,
      file: '',
      loaEnv: '',
      customerID: '',
      email: '',
      customers: [],
      disabled: false,
      requiredInformation: {
        endDate: true,
        consumption: true,
        rates: true
      }
    },
    addressInput : [],
    adminHome: {
      loading: true,
      data: []
    },
    systemTracking: {
      loading: true,
      agentDrill: '',
      view: 'Answered Calls',
      stats: {
        answeredCalls: 0,
        sentLoas: 0,
        activeAgents: 0
      },
      tableView: '',
      dates: {
        from: new Date().toISOString().split('T')[0] + 'T00:00',
        to: new Date().toISOString().split('T')[0] + 'T23:59'
      },
      data: []
    },
    pricingUpload: {
      loading: false,
      uploaded: false,
      customerID: '',
      documentType: 'pricing',
      loadingTitle: {
        main: '',
        sub: ''
      },
      customers: [],
      upload: {
        key: '',
        data: '',
        private: true
      }
    },
    pricingHome: {
      loading: true,
      data: [],
      view: 'all'
    },
    informationProfile: {
      loading: true,
      type: 'Article',
      data: {
        name: '',
        content: '',
        isActive: true
      }
    },
    helper: {
      loading: true,
      show: false,
      filteredData: [],
      query: '',
      view: 'knowledge',
      articleIndex: null,
      supplierData: [],
      knowledgeData: []
    },
    customerProfile: {
      loading: true,
      qaBusy: false,
      data: [],
      requestCallTime : '',
      loas: {
        one: "",
        two: ""
      },
      onCall: false
    },
    adminProfile: {
      loading: true,
      data: []
    },
    accountSettings: {
      data: {
        firstName: '',
        lastName: '',
        email: '',
        loaMessage: '',
        password: ''
      }
    },
    systemHome: {
      sources: [
        {
          name: {
            friendly: 'Customers',
            key: 'customers'
          },
          column: {
            keys: ['Name', 'User.firstName', 'User.lastName', 'isActive', 'callDate', 'lastCallDate', 'notes'],
            friendlyMap: d => d.map(x => {
              return {
                'Name': x.Name,
                'Agent': x.User.firstName + ' ' + x.User.lastName,
                'Callback Date': x.callDate ? new Date(x.callDate) : 'N/A',
                'Last Call Date': x.lastCallDate ? new Date(x.lastCallDate) : 'N/A',
                'Active': x.isActive,
                '_tableRowColour': x.isActive ? 'rgb(238, 255, 238)' : 'rgb(255, 238, 238)'
              };
            })
          }
        },
        {
          name: {
            friendly: 'Articles',
            key: 'articles'
          },
          column: {
            keys: ['Name', 'Content', 'isActive'],
            friendlyMap: d => d.map(x => {
              return {
                'Name': x.Name,
                'Content': x.Content,
                'Active': x.isActive,
                '_tableRowColour': x.isActive ? 'rgb(238, 255, 238)' : 'rgb(255, 238, 238)'
              };
            })
          }
        },
        {
          name: {
            friendly: 'Suppliers',
            key: 'suppliers'
          },
          column: {
            keys: ['Name', 'Content', 'isActive', 'informationRequestEmail'],
            friendlyMap: d => d.map(x => {
              return {
                'Name': x.Name,
                'Information Request Email': x.informationRequestEmail,
                'Content': x.Content,
                'Active': x.isActive,
                '_tableRowColour': x.isActive ? 'rgb(238, 255, 238)' : 'rgb(255, 238, 238)'
              };
            })
          }
        },
        {
          name: {
            friendly: 'Users',
            key: 'users'
          },
          column: {
            keys: ['firstName', 'lastName', 'email', 'isActive'],
            friendlyMap: d => d.map(x => {
              return {
                'Full Name': `${x.firstName} ${x.lastName}`,
                'Email': x.email,
                'Active': x.isActive,
                '_tableRowColour': x.isActive ? 'rgb(238, 255, 238)' : 'rgb(255, 238, 238)'
              }
            })
          }
        },
        {
          name: {
            friendly: 'Tracking',
            key: 'tracking'
          },
          column: {
            keys: ['module', 'action', 'dateCreated', 'user.firstName', 'user.lastName', 'user.email', 'customer.name'],
            friendlyMap: d => d.map(x => {
              return {
                'Agent': x.user ? `${x.user.firstName} ${x.user.lastName} (${x.user.email})` : 'N/A',
                'Customer': x.customer ? x.customer.name : 'N/A',
                'Module': x.module,
                'Action': x.action,
                'Date': new Date(x.dateCreated)
              };
            })
          }
        }
      ],
      data: [],
      loading: true,
      selectedSourceIndex: 0
    },
    main: {
      loading: true
    },
    app: {
      loading: true
    },
    dashboard: {
      loading: true,
      data: [],
      users: []
    },
    searchInput: {
      focus: false,
      nodes: {}
    },
    sales: {
      loading: true,
      view: 'today',
      newView: 'all',
      counters: {
        recentlyDialled: 0,
        overdueCallbacks: 0,
        unavailableToSell: 0,
        availableToSell: 0,
        pendingLoa: 0,
        noAnswer: 0
      },
      data: [],
      userView: '',
      users: []
    },
    data: {
      loading: true,
      data: {
        totalCustomers: '...',
        totalUsers: '...',
        totalTrackers: '...',
        totalByUser: [],
        pendingLoas: null,
        newCustomersByUser: null
      }
    },
    salesCalendar: {
      loading: true,
      data: []
    },
    login: {
      loading: false,
      error: false,
      credentials: {
        email: '',
        password: ''
      }
    },
    user: {
      firstName: "",
      lastName: "",
      email: ""
    },
    dataUpload: {
      view: 'valid',
      uploading: false,
      uploaded: false,
      data: {
        valid: [],
        invalid: []
      },
      errorLog: [],
      title: {
        main: '',
        sub: ''
      }
    },
    campaignReports: {
      data : [],
      loading : true,
      options : [],
      campaign : '',
      filteredData:[]
    },
    keys: {
      app: COOKIES.get(config.cookies.system) ? COOKIES.get(config.cookies.system) : false,
      pca: 'WX96-HF26-RJ59-TK78',
      esign: COOKIES.get(config.cookies.esign) ? COOKIES.get(config.cookies.esign) : false
    },
    logOut() {
      COOKIES.remove(config.cookies.system, { path: '/' });
      state.keys.app = false;
    },
    authTest() {
      return new Promise(r => {
        state.api.get('login/auth').then(res => {
          if (res.status === 401) state.logOut();
          r(res.status !== 401);
        }).catch(() => {
          state.logOut();
          r(false);
        })
      });
    },
    get api() {
      return axios.create({
        // baseURL: config.generic.apiEndpoint,
        baseURL: config.api.system,
        headers: { 'Authorization': `Bearer ${state.keys.app}`, 'Accept': 'application/json', 'Content-Type': 'application/json' }
      })
    },
    get eSign() {
      return axios.create({
        baseURL: config.api.esign,
        headers: { 'Authorization': `Bearer ${state.keys.esign}`, 'Accept': 'application/json', 'Content-Type': 'application/json' }
      })
    },
    track: node => {
      state.user.email && state.user.email.indexOf('techbubble') === -1 && state.api.post('tracking', { ...node, userID: state.user.id, dateCreated: new Date().toJSON().split('T')[0] + ' ' + new Date().toTimeString().split(' ')[0], isActive: true })
    },
    addressPreview(address) {
      return address ? Object.keys(address).filter(x => x !== "isActive" && x !== "longitude" && x !== "latitude").reduce((str, key, i) => {
        if (address[key]) str += (i ? ', ' : '') + address[key];
        return str;
      }, '') : "";
    },
    buildGetSelector(arr) {
      return arr.reduce((p, x, i) => {
        if (!i) {
          p += "?props=" + x;
        } else {
          p += "&props=" + x;
        }
        return p;
      }, "");
    },
    validation: {
      percentageOfNumber(x, num) {
        return parseFloat(((x / num) * 100).toFixed(2));
      },
      randomizeArray(arr) {
        for (let i = arr.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [arr[i], arr[j]] = [arr[j], arr[i]];
        }
        return arr;
      },
      formatToCurrency(str) {
        return str.toFixed(2).replace(/./g, (c, i, a) => i && c !== "." && ((a.length - i) % 3 === 0) ? ',' + c : c);
      },
      onlyNumbers(str) {
        return str.replace(/[^0-9.]/g, "");
      },
      isEmail(str) {
        return /\S+@\S+\.\S+/.test(str);
      },
      isPostcode(pc) {
        return /(GIR 0AA)|((([ABCDEFGHIJKLMNOPRSTUWYZ][0-9][0-9]?)|(([ABCDEFGHIJKLMNOPRSTUWYZ][ABCDEFGHKLMNOPQRSTUVWXY][0-9][0-9]?)|(([ABCDEFGHIJKLMNOPRSTUWYZ][0-9][ABCDEFGHJKSTUW])|([ABCDEFGHIJKLMNOPRSTUWYZ][ABCDEFGHKLMNOPQRSTUVWXY][0-9][ABEHMNPRVWXY])))) [0-9][ABDEFGHJLNPQRSTUWXYZ]{2})/i.test(pc);
      },
      titleCase(str) {
        return str.replace(/\w\S*/g, x => {
          return x.charAt(0).toUpperCase() + x.substr(1).toLowerCase();
        })
      },
      formatTelephoneStr(str) {
        return str.replace(/(\d{4})(\d{3})(\d{4})/, '$1 $2 $3');
      },
      getType(d) {
        if (typeof d === "undefined" || d === null) {
          return 'object';
        } else if (typeof d === "boolean") {
          return 'boolean';
        } else if (typeof d === "number") {
          return 'number';
        } else if (d instanceof Date) {
          return 'date';
        } else if (typeof d === "object") {
          return 'object';
        } else {
          return 'string';
        }
      },
      stringifyData(d) {
        if (typeof d === "undefined" || d === null) {
          return "N/A";
        } else if (typeof d === "boolean") {
          return d ? "Yes" : "No";
        } else if (typeof d === "number") {
          return d.toLocaleString();
        } else if (d instanceof Date) {
          return d.toLocaleString();
        } else if (typeof d === "object") {
          return "Not Viewable";
        } else {
          return String(d);
        }
      },
      unqiueValuesByProperty(arr, prop) {
        return arr.filter((obj, pos, narr) => narr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos);
      },
      uniqueValues(arr) {
        return arr.filter((v, i) => arr.indexOf(v) === i);
      },
      ordersValuesByProperty(arr, prop, descending) {
        return arr.sort((a, b) => {
          if (a[prop] < b[prop]) return descending ? 1 : -1;
          if (a[prop] > b[prop]) return descending ? -1 : 1;
          return 0;
        })
      },
      medianOfArr(nArr) {
        nArr.sort((a, b) => a - b);
        let mid = nArr.length / 2;
        return mid % 1 ? nArr[mid - 0.5] : (nArr[mid - 1] + nArr[mid]) / 2;
      }
    }
  }
);

export default state;