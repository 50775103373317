import React, { Component } from 'react';
import state from '../../State';
import { view } from 'react-easy-state';

export default view(class InformationCentre extends Component {
    componentDidMount() {
        state.helper.view = 'knowledge';
        Promise.all([
            state.api.post(`suppliers/find${state.buildGetSelector(['name', 'content'])}`,{isActive:true}),
            state.api.post(`articles/find${state.buildGetSelector(['name', 'content'])}`,{isActive:true})
        ]).then(data => {
            state.helper.knowledgeData = data[1].data;
            state.helper.supplierData = data[0].data;
            this.filterData();
            state.helper.loading = false;
        })
    }
    filterData(){
        state.helper.filteredData = state.helper[`${state.helper.view}Data`].filter(x => x.name.toLowerCase().indexOf(state.helper.query.toLowerCase()) > -1);
    }
    render() {
        return state.helper.loading ? null : <div className={`app-helper`}>
            <div>
                <div>
                    <div className={`bar ${state.helper.show ? 'expand' : ''}`} onClick={e => {
                        state.helper.show = !state.helper.show;
                        state.track({
                            module : 'INFORMATION',
                            action : state.helper.show ? 'OPENED_CENTRE' : 'CLOSED_CENTRE'
                          });
                    }}>
                        <i className="fas fa-info"></i>
                    </div>
                    <div className={`con ${state.helper.show ? 'expand' : ''}`}>
                        {
                            state.helper.articleIndex !== null ?
                                <div>
                                    <i className="fal fa-arrow-circle-left helper-back" onClick={e => state.helper.articleIndex = null} />
                                    <h4>{state.helper.filteredData[state.helper.articleIndex].name}</h4>
                                    <hr />
                                    <div className="article-text">
                                        {state.helper.filteredData[state.helper.articleIndex].content}
                                    </div>
                                </div>
                                :
                                <div>
                                    <h4>Information Centre</h4>
                                    <ul>
                                        <li className={state.helper.view === 'knowledge' ? 'selected' : ''} onClick={() => {
                                            state.helper.view = 'knowledge';
                                            this.filterData();
                                        }}><i className="fas fa-info"></i>Knowledge</li>
                                        <li className={state.helper.view === 'supplier' ? 'selected' : ''} onClick={() => {
                                            state.helper.view = 'supplier';
                                            this.filterData();
                                        }}><i className="fas fa-bolt"></i>Suppliers</li>
                                    </ul>
                                    <input type="search" placeholder="Search..." defaultValue={state.helper.query} onInput={e => {
                                        state.helper.query = e.currentTarget.value;
                                        this.filterData();
                                    }
                                    } />
                                    <div className="help-results">
                                    
                                        {
                                            state.helper.filteredData.length ? state.helper.filteredData.map((x, i) =>
                                                <div key={i} className="help-result" data-index={i} onClick={e => state.helper.articleIndex = e.currentTarget.getAttribute('data-index')}>
                                                    <p>{x.name}</p>
                                                    <p>{x.content.substr(0, 60)}</p>
                                                </div>
                                            ) : <p><br/>No articles found</p>
                                        }
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    }
})