import React, { Component } from 'react';
import Card from '../../generic/Card';
import Loader from '../../generic/Loader';
import state from '../../State';
import { view } from 'react-easy-state';
import IcsHelper from '../../plugins/icsHelper';
import Notification from '../../generic/Notification';
import config from '../../config';

export default view(class ArrangeCallback extends Component {
  constructor() {
    super();

    this.state = {
      downloadICS: false
    };
  }
  componentDidMount() {
    state.track({
      module: 'SALES',
      action: 'LOADED_ARRANGE_CALLBACK'
    });
    state.arrangeCallback.loading = true;
    state.api.post(`customers/find${state.buildGetSelector(['id', 'name'])}`, {
      name: decodeURIComponent(this.props.match.params.customer),
      isActive: true
    }).then(res => {
      if (res.data.length) {
        state.arrangeCallback.customerID = res.data[0].id;
        state.arrangeCallback.customerName = res.data[0].name;
        state.arrangeCallback.loading = false;
      } else {
        this.props.history.push('/sales-system')
      }
    })
  }
  componentWillUnmount() {
    state.arrangeCallback.data = '';
    state.arrangeCallback.loading = false;
  }
  downloadICS = () => {
    new IcsHelper({
      title : state.arrangeCallback.customerName + ' - Call',
      description : `${state.arrangeCallback.customerNotes} (${config.generic.url}/${decodeURIComponent(this.props.match.params.customer)}/)`,
      start : new Date(state.arrangeCallback.data),
      end : new Date(state.arrangeCallback.data)
    }).download();
  }
  handleSetCallback = () => {
    if (!state.arrangeCallback.data) {
      new Notification('Invalid Date');
    } else {
      state.arrangeCallback.loading = true;
      state.track({
        module: 'SALES',
        action: 'SET_CALLBACK'
      });
      state.api.post(`customers/${state.arrangeCallback.customerID}`, { callDate: state.arrangeCallback.data, hasCalled: true }).then(res => {
        new Notification('Set Callback Successfully');
        if (this.state.downloadICS) {
          this.downloadICS();
        }
        this.props.history.push('/sales-system')
      })
    }
  }
  render() {
    return (
      state.arrangeCallback.loading ? <Loader absolute colour="#f4f4f4" /> :
        <div className="grid-center" style={{ height: 'calc(100vh - 66px)' }}>
          <Card height="auto" width="600px" style={{ padding: '50px 30px' }}>
            <div className="center-contents">
              <h1 className="with-st" style={{textAlign:'center'}}>Arrange Callback for {state.arrangeCallback.customerName}<small>Plan a date and time to call this customer</small></h1>
              <br />
              <input type="datetime-local" onChange={e => state.arrangeCallback.data = e.currentTarget.value} />
              <button className={`icon-btn`} style={{ marginTop: '16px' }} onClick={this.handleSetCallback}>
                <i className="fas fa-calendar"></i>
                <span>Set Callback</span>
              </button>
              <div style={{display: 'flex', margin: 5}}>
                <input id="icsCheckbox" type="checkbox" onClick={(e) => this.setState({downloadICS: e.target.checked})} />
                <label for="icsCheckbox" style={{margin: 0}}>&nbsp;Download ICS?</label>
              </div>
              
            </div>
          </Card>
        </div>
    );
  }
})
