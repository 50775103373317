import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import state from './State';
import * as components from './components';

class Routes extends React.Component {
  componentDidUpdate() {
    let crumb = window.location.pathname.split('/').filter(x => x);
    if (crumb.join('') !== state.crumb.join('')) state.crumb = crumb;
  }
  render() {
    return <Switch>
      <Route exact path="/" render={() => <Redirect to="/dashboard" />} />

      <Route exact path="/dashboard" component={components.Dashboard} />

      <Route exact path="/sales-system" component={components.SalesHome} />

      <Route exact path="/account-settings" component={components.AccountSettings} />

      <Route exact path="/sales-system/callback-calendar" component={components.SalesCalendar} />

      <Route exact path="/sales-system/new-prospect" component={components.NewProspect} />

      <Route exact path="/sales-system/:customer/" component={components.CustomerProfile} />
      <Route exact path="/sales-system/:customer/archive-customer" component={components.ArchiveCustomer} />
      <Route exact path="/sales-system/:customer/arrange-callback" component={components.ArrangeCallback} />
      <Route exact path="/sales-system/:customer/start-call" component={components.CustomerCall} />
      <Route exact path="/sales-system/:customer/on-call" component={components.CustomerProfile} />

      <Route exact path="/data-management" component={components.DataHome} />

      <Route exact path="/data-management/upload-prospects" component={components.DataUpload} />

      <Route exact path="/data-management/campaign-report" component={components.CampaignReport} />

      <Route exact path="/data-management/information-centre/" component={components.DataInformationCentre} />
      <Route exact path="/data-management/information-centre/:type/:node" component={components.InformationProfile} />

      <Route exact path="/data-management/data-migration" component={components.DataMigration} />

      <Route exact path="/data-management/manage-users/" component={components.DataUsers} />
      <Route exact path="/data-management/manage-users/:user" component={components.UserProfile} />

      <Route exact path="/system-reporting" component={components.SystemHome} />
      
      <Route exact path="/system-reporting/sales-tracking" component={components.SystemTracking} />
      
      <Route exact path="/system-reporting/sales-analysis" component={components.AgentAnalysis} />

      <Route exact path="/admin-information" component={components.AdminHome} />
      <Route exact path="/admin-information/:customer" component={components.AdminProfile} />
      <Route exact path="/admin-information/:customer/information-request" component={components.AdminEmail} />

      <Route exact path="/data-management/upload-calls" component={components.UploadCalls} />
      <Route exact path="/admin-information/customer-calls" component={components.CustomerCalls} />
    </Switch>
  }
}
export default Routes;