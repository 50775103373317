import React, { Component } from 'react';
import Card from '../../generic/Card';
import Notification from '../../generic/Notification';
import state from '../../State';
import { view } from 'react-easy-state';

export default view(class AccountSettings extends Component {
    componentDidMount() {
        state.track({
            module: 'ACCOUNT',
            action: `LOADED_ACCOUNT_SETTINGS`
        });
        this.resetData();
    }
    componentWillUnmount(){
        this.resetData();
    }
    resetData(){
        state.accountSettings.data = {
            firstName : state.user.firstName,
            lastName : state.user.lastName,
            email : state.user.email,
            loaMessage : state.user.loaMessage,
            password : ''
          };
    }
    handleSaveProfile = () => {
        if(
            state.accountSettings.data.firstName &&
            state.accountSettings.data.lastName &&
            state.validation.isEmail(state.accountSettings.data.email)
        ) {
            let updates = {
                firstName : state.accountSettings.data.firstName,
                lastName  : state.accountSettings.data.lastName,
                email     : state.accountSettings.data.email,
                loaMessage: state.accountSettings.data.loaMessage
            };
            if(state.accountSettings.data.password) updates.password = state.accountSettings.data.password;
            state.api.post(`users/${state.user.id}`,updates).then(d => {
                new Notification('Successfully updated account');
                state.user.firstName = state.accountSettings.data.firstName;
                state.user.lastName = state.accountSettings.data.lastName;
                state.user.email = state.accountSettings.data.email;
                state.user.loaMessage = state.accountSettings.data.loaMessage;
                this.resetData();
            });
        } else {
            new Notification('Invalid required fields');
        }
    }
    render() {
        return <div className="grid-gap-big user-profile-grid">
                <Card height="590px" style={{ padding: '16px', overflow: 'auto' }} className="full-width-form">
                    <h2 className="with-st">Account Settings<small>Make changes to your account</small></h2>
                    <hr />
                    <div className="grid-1">
                        <span>
                            <label>First Name</label>
                            <input maxLength="100" type="text" defaultValue={state.accountSettings.data.firstName} onChange={e => state.accountSettings.data.firstName = e.currentTarget.value} />
                        </span>
                        <span>
                            <label>Last name</label>
                            <input maxLength="100" type="text" defaultValue={state.accountSettings.data.lastName} onChange={e => state.accountSettings.data.lastName = e.currentTarget.value} />
                        </span>
                        <span>
                            <label>Email Address</label>
                            <input maxLength="200" type="text" defaultValue={state.accountSettings.data.email} onChange={e => state.accountSettings.data.email = e.currentTarget.value} />
                        </span>
                        <span>
                            <label>New Password</label>
                            <input maxLength="100" autoComplete="new-password" type="password" defaultValue="" onChange={e => state.accountSettings.data.password = e.currentTarget.value} />
                        </span>
                        <span>
                            <label>LOA Message</label>
                            <textarea maxLength="250" value={state.accountSettings.data.loaMessage} onChange={e => state.accountSettings.data.loaMessage = e.currentTarget.value} />
                        </span>
                    </div>
                    <button className={`icon-btn`} style={{ marginTop: '16px' }} onClick={this.handleSaveProfile}>
                        <i className="fas fa-save"></i>
                        <span>Update Account</span>
                    </button>
                </Card>
            </div>
    }
})
