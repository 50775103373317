import React, { Component } from 'react';
import Notification from './Notification';

class UploadButton extends Component {
    constructor(p) {
        super(p);
        this.fileUpload = null;
    }
    handleUpload(file) {
        if(file){
            let extension = file.name.split('.')[1];
            if(this.props.types.includes(extension.toLowerCase())){
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onloadend = () => {
                    this.props.handle(reader.result.split('base64,').pop());
                }
            } else {
                new Notification('Unsupported file type');
            }
        } else {
            new Notification('No data found');
        }
    }
    render() {
        return (
            [<button onClick={() => this.fileUpload.click()}>
                {this.props.text}
            </button>,
            <input style={{ display: 'none' }} type="file" ref={e => this.fileUpload = e} onChange={e => this.handleUpload(e.target.files[0])} />]
        );
    }
}

export default UploadButton;
