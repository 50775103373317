/* eslint-disable */
import XLSX from 'xlsx';

class DownloadHelper {
    file(name,type,data){
        data = `data:${type};base64,${data}`;
        let link = document.createElement('a');
        link.setAttribute('href', data);
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }
}

export default new DownloadHelper();