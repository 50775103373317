import React, { Component } from 'react';
import Card from '../../generic/Card';
import Table from '../../generic/Table';
import Stat from '../../generic/Stat';
import state from '../../State';
import { view } from 'react-easy-state';
import Loader from '../../generic/Loader';
import Notification from '../../generic/Notification';
import * as Recharts from 'recharts';
import ExcelHelper from '../../plugins/excelHelper';
import config from '../../config';
const { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } = Recharts;

export default view(class DataUpload extends Component {
    componentWillUnmount() {
        state.dataUpload = {
            view: 'valid',
            uploading: false,
            uploaded: false,
            data: {
                valid: [],
                invalid: []
            },
            title: {
                main: '',
                sub: ''
            }
        }
    }
    componentDidMount() {
        state.track({
            module : 'DATA',
            action : `LOADED_DATA_UPLOAD`
          });
        document.querySelector('.file-uploader').ondragover = document.querySelector('.file-uploader').ondragenter = e => {
            e.preventDefault();
            document.querySelector('.file-uploader').classList.add("file-uploader-over")
        }

        document.querySelector('.file-uploader').ondragleave = e => {
            document.querySelector('.file-uploader').classList.remove("file-uploader-over")
        }

        document.querySelector('.file-uploader').ondrop = e => {
            document.getElementById('fileUploader').files = e.dataTransfer.files;
            e.preventDefault();
        }
    }

    handleUpload(files) {
        if (!files[0]) {
            new Notification(`No data found`);
        } else {
            if (files[0].name.split('.').pop().toLowerCase().indexOf('xls') > -1) {
                ExcelHelper.fromFile(files[0]).then(jsonData => {
                    if (jsonData[0].length) {
                        if (jsonData[0].length < 3000) {
                            state.dataUpload.uploading = true;
                            this.validateColumns(jsonData[0]);
                        } else {
                            new Notification(`Max 3000 rows exceeded`);
                        }
                    } else {
                        new Notification(`No data found`);
                    }
                })
            } else {
                new Notification(`Invalid file format`);
            }
        }
    }

    validateColumns(rows) {
        state.dataUpload.title = {
            main: 'Verifying Columns',
            sub: 'Ensuring that the correct columns are being uploaded'
        }
        let keys = Object.keys(rows[0]);
        if (
            keys.includes('Name') &&
            keys.includes('NameOnAccount') &&
            keys.includes('Telephone') &&
            keys.includes('Agent')
        ) {
            this.validateRows(rows);
        } else if (
            keys.includes('Field1') &&
            keys.includes('Field9') &&
            keys.includes('Agent') &&
            ((keys.includes('Field25') && keys.includes('Field26')) || (keys.includes('Field30') && keys.includes('Field31')))
        ) {
            new Notification('Creditsafe export detected');
            this.parseCreditsafe(rows).then(d => this.validateRows(d));
        } else {
            new Notification(`Incorrect columns in file`);
            this.componentWillUnmount();
        }
    }
    parseCreditsafe(rows){
        rows.shift();
        return new Promise(finish => {
            finish(rows.map(x => {
                let row = {};
                row.Name = x.Field1;
                row.Telephone = x.Field9;
                row.Agent = x.Agent;
                let nameIsDirector = x.Field30 ? false : true;
                ['Field24','Field25','Field26','Field29','Field30','Field31','Field32'].forEach(key => {
                    if(!x[key]) x[key] = '';
                })
                row.NameOnAccount = nameIsDirector ? `${x.Field24} ${x.Field25} ${x.Field26}` : `${x.Field29} ${x.Field30} ${x.Field31}`;
                row.NameOnAccountTitle = nameIsDirector ? 'Director' : x.Field32;
                if(x.Field3) row.AddressLine1 = x.Field3;
                if(x.Field4) row.AddressLine2 = x.Field4;
                if(x.Field5) row.AddressLine3 = x.Field5;
                if(x.Field6) row.AddressLine4 = x.Field6;
                if(x.Field7) row.Postcode = x.Field7;
                return row;
            }));
        });
    }
    validateRows(rows) {
        rows = rows.filter(x => x.Name && x.NameOnAccount && x.Telephone && x.Agent);
        state.dataUpload.title = {
            main: 'Validating Columns',
            sub: 'Comparing upload data against the system'
        }
        rows = state.validation.unqiueValuesByProperty(rows, 'Name');
        let rowsToFind = [];
        new Promise(finish => {
            state.api.get(`users/${state.buildGetSelector(['id', 'firstName', 'lastName'])}`).then(users => {
                rows.forEach((row, i) => {
                    if (!row.Name || !row.NameOnAccount || !row.Telephone || !row.Agent) {
                        state.dataUpload.data.invalid.push({ ...row, e : 'Missing field' })
                    }
                    let userIndex = users.data.findIndex(x => `${x.firstName.toLowerCase()}${x.lastName.toLowerCase()}` === row.Agent.toLowerCase().split(' ').join(''));
                    if (userIndex > -1) {
                        rowsToFind.push({...row,userID:users.data[userIndex].id,dateCreated:new Date(),hasCalled:false,isActive:true,lastUpdated:new Date()});
                    } else {
                        state.dataUpload.data.invalid.push({ ...row, e : 'Invalid agent' })
                    }
                });
                finish();
            })
        }).then(() => {
            state.api.post('customers/data/duplicates',rowsToFind.map(x => x.Name)).then(res => {
                res.data = res.data.map(x => x.toLowerCase());
                state.dataUpload.data.valid = state.dataUpload.data.valid.concat(rowsToFind.filter(x => res.data.indexOf(x.Name.toLowerCase()) === -1));
                state.dataUpload.data.invalid = state.dataUpload.data.invalid.concat(rowsToFind.filter(x => res.data.indexOf(x.Name.toLowerCase()) !== -1).map(x => {return{...x,e : 'Duplicate entry'}}));
                state.dataUpload.uploaded = true;
                state.dataUpload.uploading = false;
            })
        })
    }
    get tableView() {
        if (state.dataUpload.view === 'valid') {
            return state.dataUpload.data.valid.map(x => {
                return {
                    'Name': x.Name,
                    'Name on Account': x.NameOnAccount,
                    'Telephone': x.Telephone,
                    'Agent': x.Agent
                }
            });
        } else {
            return state.dataUpload.data.invalid.map(x => {
                return {
                    'Name': x.Name,
                    'Name on Account': x.NameOnAccount,
                    'Telephone': x.Telephone,
                    'Agent': x.Agent,
                    'Error': x.e
                }
            });
        }
    }
    uploadToServer = () => {
        state.track({
            module : 'DATA',
            action : `UPLOADING_DATA`
          });
        state.dataUpload.uploaded = false;
        state.dataUpload.uploading = true;
        state.dataUpload.title = {
            main: `Starting Data Upload`,
            sub: 'Uploading data in to the system'
        }
        new Promise(finish => {
            state.api.post(`customers/bulk/new`,state.dataUpload.data.valid).then(d => {
                finish();
            });
        }).then(() => {
            this.componentWillUnmount();
            new Notification(`Successfully added Prospects`);
        });
    }
    render() {
        return (
            state.dataUpload.uploaded ?
                <div className="grid-1 grid-gap-big">
                    <div className="grid-3 grid-gap-big">
                        <Stat label="Valid Prospects" value={state.validation.stringifyData(state.dataUpload.data.valid.length)} colour="#76B44D" height="190px" />
                        <Stat label="Duplicate Prospects" value={state.validation.stringifyData(state.dataUpload.data.invalid.filter(x => x.e === 'Duplicate entry').length)} colour="#2F7CAE" height="190px" />
                        <Stat label="Invalid Prospects" value={state.validation.stringifyData(state.dataUpload.data.invalid.filter(x => x.e !== 'Duplicate entry').length)} colour="#D51F57" height="190px" />
                    </div>
                    <div className="grid-3 grid-gap-big">
                        <Card height="calc(100vh - 283px)">
                            <h2 className="dashboard-heading">Upload by Agent</h2>
                            <ResponsiveContainer >
                                <BarChart data={state.dataUpload.data.valid.reduce((a, x) => {
                                    let index = a.findIndex(y => y.name === x.Agent);
                                    if (index === -1) {
                                        a.push({
                                            name: x.Agent,
                                            'Total Prospects': 0
                                        });
                                        index = a.length - 1;
                                    }
                                    a[index]['Total Prospects']++;
                                    return a;
                                }, [])} margin={{ bottom: 50, right: 50 }}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" tick={{ fontSize: 12 }} />
                                    <YAxis tick={{ fontSize: 12 }} />
                                    <Tooltip offset={0} itemStyle={{ fontSize: 14 }} labelStyle={{ fontSize: 14 }} />
                                    <Legend />
                                    <Bar isAnimationActive={true} type="linear" dataKey="Total Prospects" stackId="a" fill="#60BFC7" />
                                </BarChart>
                            </ResponsiveContainer>
                        </Card>
                        <Card className="grid-span-2" height="calc(100vh - 283px)" style={{ padding: '16px' }}>
                            <div className="flex-row flex-row-padder">
                                <button className={`icon-btn ${state.dataUpload.view !== 'valid' ? 'inactive-btn' : ''}`} style={{ marginBottom: '16px' }} onClick={() => state.dataUpload.view = 'valid'}>
                                    <i className="fas fa-check"></i>
                                    <span>Valid</span>
                                </button>
                                <button className={`icon-btn ${state.dataUpload.view !== 'invalid' ? 'inactive-btn' : ''}`} style={{ marginBottom: '16px' }} onClick={() => state.dataUpload.view = 'invalid'}>
                                    <i className="fas fa-times"></i>
                                    <span>Invalid</span>
                                </button>
                                {state.dataUpload.data.valid.length !== 0 && <button className={`icon-btn upload-btn`} style={{ marginBottom: '16px', background: '#76b34e', border: '1px solid #699f45' }} onClick={this.uploadToServer}>
                                    <i className="fas fa-upload"></i>
                                    <span>Upload</span>
                                </button>
                                }
                            </div>
                            <Table
                                tid="upload"
                                data={this.tableView}
                                height="calc(100vh - 373px)"
                                icons={{
                                    fullscreen: false
                                }}
                            />
                        </Card>
                    </div>
                </div>
                : !state.dataUpload.uploading ? <div className="grid-center" style={{ height: 'calc(100vh - 66px)' }}>
                    <Card height="400px" width="600px">
                        <div className="center-contents">
                            <h1 className="with-st">Upload Prospects<small>Add new prospects to the system using the XLSX template</small></h1>
                            <label htmlFor="fileUploader" className="file-uploader" />
                            <input type="file" id="fileUploader" onChange={e => this.handleUpload(e.target.files)} />
                            <button onClick={() => window.location.href = `https://${config.generic.spaces}.ams3.digitaloceanspaces.com/customer_upload_sheet.xlsx`} className={`icon-btn ${state.sales.view !== 'all' ? 'inactive-btn' : ''}`} style={{ marginBottom: '16px' }}>
                                <i className="fas fa-file-excel"></i>
                                <span>Download Template</span>
                            </button>
                        </div>
                    </Card>
                </div> :
                    <div className="grid-center" style={{ height: 'calc(100vh - 66px)' }}>
                        <Card height="400px" width="600px" style={{ padding: '30px 16px' }}>
                            <h1 className="with-st">{state.dataUpload.title.main}<small>{state.dataUpload.title.sub}</small></h1>
                            <Loader relative height="275px" />
                        </Card>
                    </div>
        );
    }
})