import React, { Component } from 'react';
import Card from '../../generic/Card';
import Notification from '../../generic/Notification';
import state from '../../State';
import { view } from 'react-easy-state';
import email from '../../plugins/email';
import Loader from '../../generic/Loader';
import config from '../../config';

export default view(class UserProfile extends Component {
    componentWillUnmount() {
        state.userProfile.loading = true;
        state.userProfile.data = []
    }
    componentDidMount() {
        state.track({
            module: 'DATA',
            action: `LOADED_USER_PROFILE`
        });
        this.retrieveData();
    }
    retrieveData(){
        state.userProfile.new = this.props.match.params.user.toLowerCase() === 'new-user';
        if(!state.userProfile.new){
            state.api.get(`users/${this.props.match.params.user.replace(/_-_/g, '-')}${state.buildGetSelector(['firstName','teamName', 'lastName', 'email', 'accessPricing', 'accessSales', 'accessAdmin', 'accessReports', 'accessProspects','accessAgentOverview', 'isActive'])}`).then(res => {
                state.userProfile.data = res.data;
                state.userProfile.loading = false;
            });
        } else {
            state.userProfile.data = {
                firstName : '',
                lastName : '',
                email : ''
            }
            state.userProfile.loading = false;
        }
    }
    componentDidUpdate(prevProps){
        if (this.props.match.params.user) {
            if (this.props.match.params.user !== prevProps.match.params.user) {
                this.retrieveData();
            }
        }
    }
    handleSaveProfile = () => {
        if (state.userProfile.data.email && state.userProfile.data.firstName && state.userProfile.data.lastName) {
            if(state.userProfile.new) state.userProfile.data.password = Math.random().toString(36).slice(-8);
            let body = email.replace('[name]', `${state.userProfile.data.firstName} ${state.userProfile.data.lastName}`)
                .replace('[logoPng]', config.appearance.logo.email)
                .replace('[companyName]', config.meta.title)
                .replace('[content]', `Your account has been set-up for the ${config.meta.title} Sales System, your login credentials are as follows:<br/><br/>
                E-mail: <b>${state.userProfile.data.email}</b><br/>
                Password: <b>${state.userProfile.data.password}</b><br/><br/>`)
                .replace('[btnLink]', config.generic.url)
                .replace('[btnText]', 'Log in');
                if(state.userProfile.new) state.api.post('sendgrid', {
                From: { key: config.meta.title, value: config.email.noreply },
                To: { key: `${state.userProfile.data.firstName} ${state.userProfile.data.lastName}`, value: state.userProfile.data.email },
                Subject: 'Account Creation',
                Body: body
            })
            state.api.post(`users/${state.userProfile.new ? '' : this.props.match.params.user.replace(/_-_/g, '-')}`, state.userProfile.data).then(res => {
                delete state.userProfile.data.password;
                if(state.userProfile.new){
                    state.userProfile.new = false;
                    this.props.history.push(res.data);
                }
                new Notification('Successfully saved user');
            })
        } else {
            new Notification('Missing required field');
        }
    }
    resetPassword() {
        let password = Math.random().toString(36).slice(-8);
        state.api.post(`users/${this.props.match.params.user.replace(/_-_/g, '-')}`, { password: password, accessToken: 'reset' }).then(res => {
            let body = email.replace('[name]', `${state.userProfile.data.firstName} ${state.userProfile.data.lastName}`)
                .replace('[content]', `Your password for the Sales System has been reset, it will now be: <b>${password}</b>`)
                .replace('[btnLink]', config.generic.url)
                .replace('[btnText]', 'Log in')
                .replace('[companyName]',config.meta.title)
                .replace('[logoPng]', config.appearance.logo.email);
            state.api.post('sendgrid', {
                From: { key: config.meta.title, value: config.email.noreply },
                To: { key: `${state.userProfile.data.firstName} ${state.userProfile.data.lastName}`, value: state.userProfile.data.email },
                Subject: 'Password Reset',
                Body: body
            }).then(res => {
                new Notification('Successfully reset password');
            });
        })
    }
    render() {
        return (
            state.userProfile.loading ? <Loader absolute colour="#f4f4f4" /> :
                <div className="grid-gap-big user-profile-grid">
                    <Card height="530px" style={{ padding: '16px', overflow: 'auto' }} className="full-width-form">
                        <h2 className="with-st">{state.userProfile.data.firstName + ' ' + state.userProfile.data.lastName}<small>User Profile</small></h2>
                        <hr />
                        <div className="grid-1">
                            <span>
                                <label>First Name</label>
                                <input type="text" defaultValue={state.userProfile.data.firstName} onChange={e => state.userProfile.data.firstName = e.currentTarget.value} />
                            </span>
                            <span>
                                <label>Last Name</label>
                                <input type="text" defaultValue={state.userProfile.data.lastName} onChange={e => state.userProfile.data.lastName = e.currentTarget.value} />
                            </span>
                            <span>
                                <label>Team Name</label>
                                <input type="text" defaultValue={state.userProfile.data.teamName} onChange={e => state.userProfile.data.teamName = e.currentTarget.value} />
                            </span>
                            <span>
                                <label>Email</label>
                                <input type="text" defaultValue={state.userProfile.data.email} onChange={e => state.userProfile.data.email = e.currentTarget.value} />
                            </span>
                            <span>
                                <label>Active</label>
                                <select defaultValue={state.userProfile.data.isActive ? 'y' : 'n'} onChange={e => state.userProfile.data.isActive = e.currentTarget.value === 'y'} >
                                    <option value='n'>Inactive</option>
                                    <option value='y'>Active</option>
                                </select>
                            </span>
                        </div>
                        <button className={`icon-btn`} style={{ marginTop: '16px' }} onClick={this.handleSaveProfile}>
                            <i className="fas fa-save"></i>
                            <span>Save Profile</span>
                        </button>
                    </Card>
                    <Card height="530px" style={{ padding: '16px', overflow: 'auto' }} className="full-width-form">
                        <h2 className="with-st">Quick Actions<small>Password resets &amp; Permissions</small></h2>
                        <hr />
                        <div className="grid-1">
                            <div>{
                                !state.userProfile.new &&
                                <span>
                                    <label>Password Reset</label>
                                    <button className={`icon-btn`} onClick={() => this.resetPassword()}>
                                        <i className="fas fa-key"></i>
                                        <span>Reset</span>
                                    </button>
                                </span>
                            }
                            
                                <label>Permissions</label>
                                <div>
                                    <button className={`icon-btn`} onClick={e => state.userProfile.data.accessSales = !state.userProfile.data.accessSales} style={{ width: '190px', marginBottom: '10px', background: state.userProfile.data.accessSales ? 'rgb(118, 180, 77)' : 'rgb(64, 64, 64)' }}>
                                        <i className="fas fa-phone flip-phone"></i>
                                        <span>Sales System</span>
                                    </button>
                                    <button className={`icon-btn`} onClick={e => state.userProfile.data.accessProspects = !state.userProfile.data.accessProspects} style={{ width: '190px', marginBottom: '10px', background: state.userProfile.data.accessProspects ? 'rgb(118, 180, 77)' : 'rgb(64, 64, 64)' }}>
                                        <i className={`fas fa-database`}></i>
                                        <span>Data Management</span>
                                    </button>
                                    <button className={`icon-btn`} onClick={e => state.userProfile.data.accessAgentOverview = !state.userProfile.data.accessAgentOverview} style={{ width: '190px', marginBottom: '10px', background: state.userProfile.data.accessAgentOverview ? 'rgb(118, 180, 77)' : 'rgb(64, 64, 64)' }}>
                                        <i className="fas fa-user"></i>
                                        <span>Agent Overview</span>
                                    </button>
                                    <button className={`icon-btn`} onClick={e => state.userProfile.data.accessReports = !state.userProfile.data.accessReports} style={{ width: '190px', marginBottom: '10px', background: state.userProfile.data.accessReports ? 'rgb(118, 180, 77)' : 'rgb(64, 64, 64)' }}>
                                        <i className="fas fa-download"></i>
                                        <span>System Reporting</span>
                                    </button>
                                    <button className={`icon-btn`} onClick={e => state.userProfile.data.accessPricing = !state.userProfile.data.accessPricing} style={{ width: '190px', marginBottom: '10px', background: state.userProfile.data.accessPricing ? 'rgb(118, 180, 77)' : 'rgb(64, 64, 64)' }}>
                                        <i className="fas fa-credit-card"></i>
                                        <span>Pricing Upload</span>
                                    </button>
                                    <button className={`icon-btn`} onClick={e => state.userProfile.data.accessAdmin = !state.userProfile.data.accessAdmin} style={{ width: '190px', marginBottom: '10px', background: state.userProfile.data.accessAdmin ? 'rgb(118, 180, 77)' : 'rgb(64, 64, 64)' }}>
                                        <i className="fas fa-lock"></i>
                                        <span>Admin Information</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
        );
    }
})