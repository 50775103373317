import React, { Component } from 'react';
import Card from '../../generic/Card';
import Loader from '../../generic/Loader';
import Table from '../../generic/Table';
import Notification from '../../generic/Notification';
import state from '../../State';
import { view } from 'react-easy-state';
import jszip from 'jszip';
import { saveAs } from 'file-saver';
import downloadHelper from '../../plugins/downloadFile';

export default view(class CustomerCalls extends Component {
  componentDidMount() {
    state.customerCalls.loading = true;
    state.track({
      module: 'ADMIN',
      action: 'LOADED_CUSTOMER_CALLS'
    });
    state.api.post(`calls/find${state.buildGetSelector(['id', 'key', 'dateCreated', 'customer.name'])}`, { isActive: true }).then(resp => {
      state.customerCalls.data = resp.data.map(x => {
          x.dateCreated = new Date(x.dateCreated);
          return x;
      })
      state.customerCalls.loading = false;

    })
  }
  componentWillUnmount() {
    state.customerCalls.loading = true;
    state.customerCalls.data = [];
  }
  get tableView() {
    return state.customerCalls.data.map((x,i) => {
      return {
          'Reference' : x.id,
          'Customer Name' : x.customer.name,
          'Date Recorded' : x.dateCreated,
          '_actionCell': {
            class: 'callable-customer-actions',
            icons: [
                {
                    type: 'download',
                    title: 'Download Call',
                    handleClick: () => {
                        new Notification('Donwloading Call');
                        state.api.get(`storage?key=${ Component(x.key)}`).then(res => {
                            downloadHelper.file(`${x.customer.name} - ${state.validation.stringifyData(x.dateCreated)}.wav`, 'audio/wav', res.data.data);
                        });
                    }
                  },
                {
                    type: 'times-circle',
                    title: 'Deactivate Call',
                    handleClick: () => {
                        state.api.post(`calls/${x.id}`,{isActive : false}).then(res => {
                            if(state.customerCalls.data.length === 1){
                                state.customerCalls.data = [];
                            } else {
                                state.customerCalls.data.splice(i,1);
                            }
                            new Notification('Successfully Deactivated Call')
                        })
                    }
                  }
            ]
        }
      }
    });
  }
  render() {
    return (
      state.customerCalls.loading ? <Loader absolute colour="#f4f4f4" /> :
        <Card height="calc(100vh - 66px)" style={{ padding: '16px' }}>
          <Table
            tid="customerCalls"
            data={this.tableView}
            height="calc(100vh - 98px)"
            icons={{
              fullscreen: false,
              download: false
            }}
          />
        </Card>
    );
  }
})
