import React, { Component } from 'react';
import Card from '../../generic/Card';
import Table from '../../generic/Table';
import Stat from '../../generic/Stat';
import state from '../../State';
import { view } from 'react-easy-state';
import Loader from '../../generic/Loader';
import * as Recharts from 'recharts';
const { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } = Recharts;

export default view(class DataHome extends Component {
    componentDidMount() {
        state.data.loading = true;
        state.data.data = {
            newCustomersByUser: false,
            totalCustomers: '...',
            totalUsers: '...',
            totalTrackers: '...',
            totalByUser: [],
            pendingLoas: false
        }
        let path = this.props.location.pathname;
        setTimeout(() => {
            if (path === this.props.location.pathname) {
                state.track({
                    module: 'DATA',
                    action: `LOADED_DATA_HOME`
                });
                state.api.post(`customers/find${state.buildGetSelector(['user.firstName', 'user.lastName'])}`, {
                    hasCalled: false,
                    isActive: true
                }).then(res => {
                    state.data.data.newCustomersByUser = state.validation.ordersValuesByProperty(res.data.reduce((j, x) => {
                        let agent = `${x.user.firstName} ${x.user.lastName}`;
                        let index = j.findIndex(x => x['Sales Agent'] === agent);
                        if (index === -1) {
                            j.push({
                                'Sales Agent': agent,
                                'Prospects in Pipeline': 0
                            });
                            index = j.length - 1;
                        }
                        j[index]['Prospects in Pipeline']++;
                        return j;
                    }, []), 'Prospects in Pipeline', true).map(x => {
                        if (x['Prospects in Pipeline'] < 100) {
                            x['_tableRowColour'] = 'rgb(255, 238, 238)';
                        }
                        return x;
                    });
                })
                state.api.post('customers/count', { isActive: true }).then(res => state.data.data.totalCustomers = res.data)
                state.api.post('users/count', { isActive: true }).then(res => state.data.data.totalUsers = res.data)
                state.api.post(`tracking/count`, { isActive: true }).then(res => state.data.data.totalTrackers = res.data);
                state.api.post(`customers/find${state.buildGetSelector(['user.firstName', 'user.lastName', 'name', 'hasLoaLevelOne', 'hasLoaLevelTwo', 'loaLevelOneEnv', 'loaLevelTwoEnv', 'isActive'])}`, { isActive: true }).then(res => {
                    state.data.data.pendingLoas = res.data.filter(x => (x.loaLevelOneEnv && !x.hasLoaLevelOne) || (x.loaLevelTwoEnv && !x.hasLoaLevelTwo) && x.isActive).map(x => {
                        return {
                            'Name': x.name,
                            'Sales Agent': `${x.user.firstName} ${x.user.lastName}`,
                            'Awaiting Level One': x.loaLevelOneEnv ? (x.loaLevelOneEnv && !x.hasLoaLevelOne) : 'Not Sent',
                            'Awaiting Level Two': x.loaLevelTwoEnv ? (x.loaLevelTwoEnv && !x.hasLoaLevelTwo) : 'Not Sent'
                        }
                    })
                });
                state.api.post(`customers/find${state.buildGetSelector(['user.firstName', 'user.lastName', 'hasLoaLevelOne', 'hasLoaLevelTwo', 'hasPricingPdfCompleted'])}`, { isActive: true }).then(res => {
                    state.data.data.totalByUser = res.data.reduce((j, x) => {
                        let index = j.findIndex(y => y.name === `${x.user.firstName} ${x.user.lastName}`);
                        if (index === -1) {
                            j.push({
                                name: `${x.user.firstName} ${x.user.lastName}`,
                                'Total Customers': 0,
                                'LOA Level 1 Signed': 0,
                                'LOA Level 2 Signed': 0,
                                'Pricing Completed': 0
                            });
                            index = j.length - 1;
                        }
                        if (x.hasLoaLevelOne) j[index]['LOA Level 1 Signed']++;
                        if (x.hasLoaLevelTwo) j[index]['LOA Level 2 Signed']++;
                        if (x.hasPricingPdfCompleted) j[index]['Pricing Completed']++;
                        j[index]['Total Customers']++;
                        return j;
                    }, [])
                    state.data.loading = false;
                });
            }
        }, 1500);
    }
    render() {
        return (
            <div className="grid-1 grid-gap-big">
                <div className="grid-3 grid-gap-big stats-mobile">
                    <Stat label="Total Customers" value={state.validation.stringifyData(state.data.data.totalCustomers)} colour="#2F7CAE" height="190px" />
                    <Stat label="Active Agents" value={state.validation.stringifyData(state.data.data.totalUsers)} colour="#60BFC7" height="190px" />
                    <Stat label="Total Trackers" value={state.validation.stringifyData(state.data.data.totalTrackers)} colour="#76B44D" height="190px" />
                </div>
                <div className="grid-1 grid-gap-big">
                    <Card height="350px">
                        {state.data.loading ? <Loader absolute /> :
                            <div style={{ height: '100%' }}><h2 className="dashboard-heading">Total Customers by Agent</h2>
                                <ResponsiveContainer >
                                    <AreaChart data={state.data.data.totalByUser} margin={{ bottom: 50, right: 50 }}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" tick={{ fontSize: 12 }} />
                                        <YAxis tick={{ fontSize: 12 }} />
                                        <Tooltip offset={0} itemStyle={{ fontSize: 14 }} labelStyle={{ fontSize: 14 }} />
                                        <Legend />
                                        <Area isAnimationActive={true} type="monotone" dataKey="Total Customers" stackId="a" stroke="#D51F57" fill="#D51F57" />
                                        <Area isAnimationActive={true} type="monotone" dataKey="LOA Level 1 Signed" stackId="b" stroke="#76B44D" fill="#76B44D" />
                                        <Area isAnimationActive={true} type="monotone" dataKey="LOA Level 2 Signed" stackId="c" stroke="#2F7CAE" fill="#2F7CAE" />
                                        <Area isAnimationActive={true} type="monotone" dataKey="Pricing Completed" stackId="d" stroke="#60BFC7" fill="#60BFC7" />
                                    </AreaChart>
                                </ResponsiveContainer></div>
                        }
                    </Card>
                    <div className="grid-2 grid-gap-big">
                        <Card height="650px" style={{ padding: '0px 16px 16px 16px' }}>
                            {state.data.data.newCustomersByUser ?
                                [
                                    <h2 key={0} className="dashboard-heading">Prospects in Pipeline</h2>,
                                    <Table
                                    tid="newProspects"
                                        key={1}
                                        data={state.data.data.newCustomersByUser}
                                        height="590px"
                                        icons={{
                                            fullscreen: true,
                                            download: false
                                        }}
                                   />] : <Loader absolute />}
                        </Card>
                        <Card height="650px" style={{ padding: '0px 16px 16px 16px' }}>
                            {state.data.data.pendingLoas ?
                            [
                                <h2 className="dashboard-heading">Pending LOAs</h2>,
                                <Table
                                    tid="pendingLOAs"
                                    data={state.data.data.pendingLoas}
                                    height="590px"
                                    icons={{
                                        fullscreen: true,
                                        download: false
                                    }}
                                />] : <Loader absolute />}
                        </Card>
                    </div>
                </div>
            </div>
        );
    }
})
