import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import Card from '../../generic/Card';
import Notification from '../../generic/Notification';
import state from '../../State';
import Stat from '../../generic/Stat';
import Loader from '../../generic/Loader';
import { view } from 'react-easy-state';
import jszip from 'jszip';
import { saveAs } from 'file-saver';
import downloadHelper from '../../plugins/downloadFile';
import email from '../../plugins/email';
import config from '../../config';

export default view(class NewProspect extends Component {
    componentDidMount() {
        this.retrieveData();
    }
    retrieveData() {
        state.adminProfile.loading = true;
        console.time("Loading customer")
        state.api.get(`customers/admin/profile/${encodeURIComponent(this.props.match.params.customer)}`).then(res => {
            console.timeEnd("Loading customer");
            if (res.data) {
                res.data.managedBy = res.data.user.firstName + ' ' + res.data.user.lastName;
                state.adminProfile.data = res.data;
                state.adminProfile.data.rfq = '';
                state.adminProfile.data.rfq += `Length of contract: ${state.adminProfile.data.lengthOfContract?state.adminProfile.data.lengthOfContract:'N/A'}\n`;
                state.adminProfile.data.rfq += `Preferred suppliers: ${state.adminProfile.data.preferredSupplier?state.adminProfile.data.preferredSupplier:'N/A'}\n`;
                state.adminProfile.data.rfq += `Suppliers to avoid: ${state.adminProfile.data.suppliersToAvoid?state.adminProfile.data.suppliersToAvoid:'N/A'}\n`;
                state.adminProfile.data.rfq += `Price in mind: ${state.adminProfile.data.priceInMind?state.adminProfile.data.priceInMind:'N/A'}\n`;
                state.adminProfile.data.rfq += `Price conditioned: ${state.adminProfile.data.priceConditioned?state.adminProfile.data.priceConditioned:'N/A'}\n`;
                state.adminProfile.data.rfq += `Another broker: ${state.adminProfile.data.usingAnotherBroker?state.adminProfile.data.usingAnotherBroker:'N/A'}\n`;
                state.adminProfile.data.rfq += `Has renewal letter: ${state.adminProfile.data.hasRenewalLetter?state.adminProfile.data.hasRenewalLetter:'N/A'}\n`;
                state.adminProfile.data.rfq += `Other business: ${state.adminProfile.data.hasOtherBusiness?state.adminProfile.data.hasOtherBusiness:'N/A'}\n`;
                state.adminProfile.data.callDate = state.adminProfile.data.callDate ? new Date(state.adminProfile.data.callDate) : null;
                state.adminProfile.data.lastCallDate = state.adminProfile.data.lastCallDate ? new Date(state.adminProfile.data.lastCallDate) : null;
                state.adminProfile.data.notes = state.adminProfile.data.notes ? state.adminProfile.data.notes[0] === '{' ? JSON.parse(state.adminProfile.data.notes) : state.adminProfile.data.notes : null;
                Promise.all([
                    new Promise(ddTask => {
                        if ( state.adminProfile.data.hasDirectDebitMandateCompleted) {
                            state.api.get(`storage/url?key=${encodeURIComponent(`${ state.adminProfile.data.id}/ddmandate-completed.pdf`)}`).then(obj => {
                                ddTask(obj.data);
                            });
                        } else {
                            if ( state.adminProfile.data.hasDirectDebitMandateDocument) {
                                state.api.get(`storage/url?key=${encodeURIComponent(`${ state.adminProfile.data.id}/ddmandate.pdf`)}`).then(obj => {
                                    ddTask(obj.data);
                                });
                            } else {
                                ddTask();
                            }
                        }
                    }),
                    new Promise(pricingTask => {
                        if ( state.adminProfile.data.hasPricingPdfCompleted) {
                            state.api.get(`storage/url?key=${encodeURIComponent(`${ state.adminProfile.data.id}/pricing-completed.pdf`)}`).then(obj => {
                                pricingTask(obj.data);
                            });
                        } else {
                            if ( state.adminProfile.data.hasPricingPdfDocument) {
                                state.api.get(`storage/url?key=${encodeURIComponent(`${ state.adminProfile.data.id}/pricing.pdf`)}`).then(obj => {
                                    pricingTask(obj.data);
                                });
                            } else {
                                pricingTask();
                            }
                        }
                    }),
                ]).then(responses => {
                    state.adminProfile.data.dd = responses[0]
                    state.adminProfile.data.pricing = responses[1]
                    state.adminProfile.loading = false;
                })
            } else {
                this.props.history.push('/admin-information')
            }
        })
    }
    uploadFile(files,isPricing){
        if (!files[0]) {
            new Notification(`No data found`);
        } else {
            if (files[0].name.split('.').pop().toLowerCase() === 'pdf') {
                new Notification(`Uploading`);
                let reader = new FileReader();
                reader.readAsDataURL(files[0]);
                reader.onloadend = () => {
                    let data = reader.result.split('base64,').pop();
                    
                    Promise.all([state.api.post('storage', {
                        key: `${state.adminProfile.data.id}/${isPricing ? 'pricing' : 'ddmandate'}.pdf`,
                        data
                    }), state.api.post(`customers/${state.adminProfile.data.id}`, { [isPricing ? 'hasPricingPdfDocument' : 'hasDirectDebitMandateDocument']: true })]).then(data => {

                            console.log(data)

                            let friendlyFileName = isPricing ? 'Pricing Sheet' : 'Direct Debit Mandate';
                            let body = email.replace('[name]', state.adminProfile.data.user.firstName + ' ' + state.adminProfile.data.user.lastName)
                            .replace('[logoPng]', config.appearance.logo.email)
                            .replace('[companyName]', config.meta.title)
                                .replace('[content]', `The ${friendlyFileName} for ${state.adminProfile.data.name} has been uploaded by the pricing team.<br/><br/>You can now send this to the customer, click the link below to go to their profile.`)
                                .replace('[btnLink]', `${config.generic.url}/sales-system/${decodeURIComponent(state.adminProfile.data.name.toLowerCase())}/`)
                                .replace('[btnText]', 'View Profile');
                            state.api.post('sendgrid', {
                                From: { key: config.meta.title, value: config.email.noreply },
                                To: { key: state.adminProfile.data.user.firstName + ' ' + state.adminProfile.data.user.lastName, value: state.adminProfile.data.user.email },
                                Subject: `${state.adminProfile.data.name} - ${friendlyFileName} Uploaded`,
                                Body: body
                            });

                            new Notification(`Uploaded`);




                        });
                }
            } else {
                new Notification(`PDF files only`);
            }
        }
    }
    handleSaveProfile = () => {
        state.adminProfile.data.lastUpdated = new Date();
        console.log(state.adminProfile.data.id)
        state.track({
            module: 'SALES',
            action: `SAVED_ADMIN_PROFILE`,
            customerID: state.adminProfile.data.id
        });
        let customerData = {
            lastUpdated: state.adminProfile.data.lastUpdated,
            adminTimescale: state.adminProfile.data.adminTimescale,
            pricingTimescale: state.adminProfile.data.pricingTimescale,
            meterInformation: state.adminProfile.data.meterInformation,
            adminNotes: state.adminProfile.data.adminNotes,
            readyForPricing: state.adminProfile.data.readyForPricing
        };
        new Notification('Saving Profile');
        state.api.post(`customers/${state.adminProfile.data.id}`, customerData).then(res => {
            new Notification('Saved Profile');
        });
    }
    downloadLoa(lvl) {
        new Notification('Preparing LOA');
        if (state.adminProfile.data[lvl === 1 ? 'loaLevelOneEnv' : 'loaLevelTwoEnv'].indexOf('.pdf') === -1) {
          Promise.all([
            state.eSign.get(`${state.adminProfile.data[lvl === 1 ? 'loaLevelOneEnv' : 'loaLevelTwoEnv']}/certificate`),
            state.eSign.get(`${state.adminProfile.data[lvl === 1 ? 'loaLevelOneEnv' : 'loaLevelTwoEnv']}/download`)
          ]).then(files => {
            let zip = new jszip();
            zip.file(`${state.adminProfile.data.name} - LOA Level ${lvl} Certificate.pdf`,files[0].data,{base64:true});
            zip.file(`${state.adminProfile.data.name} - LOA Level ${lvl}.pdf`,files[1].data,{base64:true});
            zip.generateAsync({type:"blob"})
            .then(function(content) {
                saveAs(content, `${state.adminProfile.data.name} - LOA Level ${lvl}.zip`);
                new Notification('Downloaded Successfully');
            });
          });
        } else {
          state.api.get(`storage?key=${state.adminProfile.data[lvl === 1 ? 'loaLevelOneEnv' : 'loaLevelTwoEnv']}`).then(res => {
            downloadHelper.file(`${state.adminProfile.data.name} - LOA Level ${lvl}.pdf`,'application/pdf',res.data.data);
            new Notification('Downloaded Successfully');
          })
        }
      }
    render() {
        return state.adminProfile.loading ? <Loader absolute colour="#f4f4f4" /> : <div className="grid-gap-big grid-1">
                <div className="grid-gap-big grid-3">
                    <label htmlFor="pricingUpload" style={{opacity:1,cursor:'pointer',margin:0}}><Stat label="Upload Pricing" icon='file' colour="rgb(47, 124, 174)" height="190px" /></label>
                    <label htmlFor="ddUpload" style={{opacity:1,cursor:'pointer',margin:0}}><Stat label="Upload DD Mandate" icon='credit-card' colour="rgb(96, 191, 199)" height="190px" /></label>
                    {/* <Stat label="Information Request" handleClick={() => this.props.history.push('information-request')} icon='envelope' colour="#707070" height="190px" /> */}
                    <Stat label="Save Profile" handleClick={this.handleSaveProfile} icon='save' colour="#76B44D" height="190px" />
                </div>
                <div className="grid-gap-big grid-2">
                <Card height="660px" style={{ padding: '16px', overflow: 'auto' }} className="full-width-form">
                    <h2 className="with-st">Client Overview<small>General progress and activity with this client.</small></h2>
                    <hr />
                    <div className="grid-2 grid-gap-big">
                        <span>
                            <label>Pricing Status: {state.adminProfile.data.hasPricingPdfDocument ? state.adminProfile.data.hasPricingPdfCompleted ? <a target="_blank" href={state.adminProfile.data.pricing}>View Completed</a> : <a target="_blank" href={state.adminProfile.data.pricing}>Pending Completion</a> : 'Not Uploaded'}</label>
                        </span>
                        <span>
                            <label>DD Mandate Status: {state.adminProfile.data.hasDirectDebitMandateDocument ? state.adminProfile.data.hasDirectDebitMandateCompleted ? <a target="_blank" href={state.adminProfile.data.dd}>View Completed</a> : <a target="_blank" href={state.adminProfile.data.dd}>Pending Completion</a> : 'Not Uploaded'}</label>
                        </span>
                        <span>
                        <label>LOA Level One: {state.adminProfile.data.loaLevelOneEnv ? state.adminProfile.data.hasLoaLevelOne ? <a href={'#'} onClick={e => {
                            e.preventDefault();
                            this.downloadLoa(1)
                        }}>Download Signed</a> : 'Awaiting Signature' : 'Not Sent'}</label>
                        </span>
                        <span>
                        <label>LOA Level Two: {state.adminProfile.data.loaLevelTwoEnv ? state.adminProfile.data.hasLoaLevelTwo ? <a href={'#'} onClick={e => {
                            e.preventDefault();
                            this.downloadLoa(2)
                        }}>Download Signed</a> : 'Awaiting Signature' : 'Not Sent'}</label>
                        </span>
                    </div>
                    <br/>
                    <div className="grid-2 grid-gap-big">
                    <span>
                        <label>Admin Timescale</label>
                        <input type="date" defaultValue={state.adminProfile.data.adminTimescale ? new Date(state.adminProfile.data.adminTimescale).toJSON().substr(0, 10) : ''} onChange={e => state.adminProfile.data.adminTimescale = e.currentTarget.value === '' ? null : new Date(new Date(e.currentTarget.value).setHours(9))} />
                    </span>
                    <span>
                        <label>Pricing Timescale</label>
                        <input type="date" defaultValue={state.adminProfile.data.pricingTimescale ? new Date(state.adminProfile.data.pricingTimescale).toJSON().substr(0, 10) : ''} onChange={e => state.adminProfile.data.pricingTimescale = e.currentTarget.value === '' ? null : new Date(new Date(e.currentTarget.value).setHours(9))} />
                    </span></div>
                    <br/>
                    <span>
                        <label>Meter Information</label>
                        <textarea defaultValue={state.adminProfile.data.meterInformation} onChange={e => state.adminProfile.data.meterInformation = e.currentTarget.value}/>
                    </span>
                    <span>
                                <label>Notes</label>
                                <textarea defaultValue={state.adminProfile.data.adminNotes} onChange={e => state.adminProfile.data.adminNotes = e.currentTarget.value}/>
                            </span>
                            <span>
                                <label>Ready for Pricing</label>
                                <button className={`icon-btn ${!state.adminProfile.data.readyForPricing ? 'inactive-btn' : ''}`} onClick={() => state.adminProfile.data.readyForPricing = !state.adminProfile.data.readyForPricing}>
                                <span>{state.adminProfile.data.readyForPricing ? 'Yes' : 'No'}</span>
                            </button>
                            </span>
                </Card>
                <Card height="660px" style={{ padding: '16px', overflow: 'auto' }} className="full-width-form">
                    <h2 className="with-st">Sales Agent Notes<small>Notes made by the sales agent.</small></h2>
                    <hr />
                    <span>
                        <label>Agent Name</label>
                        <input type="text" defaultValue={state.adminProfile.data.managedBy} disabled/>
                    </span>
                    <br/><br/>
                    <div className="grid-2 grid-gap-big">
                    <span>
                        <label>Callback Date</label>
                        <input type="datetime-local" defaultValue={state.adminProfile.data.callDate ? new Date(state.adminProfile.data.callDate).toJSON().substr(0, 16) : ''} disabled/>
                    </span>
                    <span>
                        <label>Last Call Date</label>
                        <input type="datetime-local" defaultValue={state.adminProfile.data.lastCallDate ? new Date(state.adminProfile.data.lastCallDate).toJSON().substr(0, 16) : ''} disabled/>
                    </span></div>
                    <br/>
                    <span>
                        <label>RFQ Summary</label>
                        <textarea defaultValue={state.adminProfile.data.rfq} disabled />
                    </span>
                    <span>
                                <label>Notes</label>
                                <ReactQuill ref={el => { this.quillComp = el }} defaultValue={state.adminProfile.data.notes} disabled/>
                            </span>
                </Card>
                </div>
                <input type="file" id="pricingUpload" style={{display:'none'}} onChange={e => this.uploadFile(e.target.files,true)} />
                <input type="file" id="ddUpload" style={{display:'none'}} onChange={e => this.uploadFile(e.target.files,false)} />
            </div>
    }
})
