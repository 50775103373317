import React, { Component } from 'react';
import { BrowserRouter, NavLink, Link } from 'react-router-dom';
import state from './State';
import { view } from 'react-easy-state';
import Loader from './generic/Loader';
import Routes from './Routes';
import Navigation from './components/misc/Navigation';
import InformationCentre from './components/misc/InformationCentre';
import SystemSearch from './components/misc/SystemSearch';
import config from './config';

export default view(class App extends Component {
    componentDidMount() {
        state.crumb = window.location.pathname.split('/').filter(x => x);
        state.api.get('login/information').then(resp => {
            if (resp.status === 204) state.logOut()
            state.user = resp.data;
            state.main.loading = false;
        });
    }
    render() {
        return (state.main.loading ? <Loader /> :
            <BrowserRouter>
                <div className={`${state.user.isDarkMode ? 'night-mode' : ''} ${state.hideSidebar ? 'small-sidebar' : ''}`}>
                    {state.showSearch ? <SystemSearch /> : null}
                    <InformationCentre />
                    <div className={`app-container ${state.timer.show ? 'app-container-call' : ''} ${state.showSearch ? 'search-open' : ''}`}>
                        <div className={`app-navigation`}>
                            <img className="logo" alt="Logo" src={`${state.user.isDarkMode ? config.appearance.logo.light : config.appearance.logo.dark}`} />
                            <img className="mobile-logo" alt="Logo" src={config.appearance.logo.mobile} />
                            <Navigation />
                            <div className="app-user-con">
                            <div className='app-settings' onClick={() => state.showSearch = true}>
                                    <i className={`fa fa-search`}></i>
                                <h2>Search System</h2>
                            </div>
                            <div className='app-settings' onClick={() => state.hideSidebar = !state.hideSidebar}>
                                    <i className={`fa fa-${state.hideSidebar ? 'plus' : 'minus'}-square night-mode-trigger`}></i>
                                <h2>Hide Sidebar</h2>
                            </div>
                            <div className='app-settings' onClick={() => {
                                state.user.isDarkMode = !state.user.isDarkMode;
                                state.api.post(`users/${state.user.id}`,{isDarkMode: state.user.isDarkMode});
                            }}>
                                    <i className={`fa fa-${state.user.isDarkMode ? 'sun' : 'moon'} night-mode-trigger`}></i>
                                <h2>{state.user.isDarkMode ? 'Light' : 'Dark'} Mode</h2>
                            </div>
                            <NavLink className="app-settings" to="/account-settings" activeClassName="selected">
                                <i className={`fa fa-user`}></i>
                                <h2>Account Settings</h2>
                            </NavLink>
                                <div className="app-user" title="Sign Out" onClick={() => {
                                    state.logOut();
                                }}>
                                    <i className="fa fa-sign-out"></i>
                                    <h2>Sign Out</h2>
                                </div>
                            </div>
                        </div>
                        <div className="app-view">
                            {state.crumb.indexOf('dashboard') === -1 ? <div className="app-crumb">
                                {
                                    state.crumb.map((x,i) => <Link key={i} to={`${window.location.pathname.split(x)[0]}${x}/`}>{decodeURIComponent(x)}</Link>)
                                }
                            </div> : null }
                            <Routes />
                        </div>
                    </div>
                </div>
            </BrowserRouter>
        );
    }
})