import React, { Component } from 'react';
import Card from '../../generic/Card';
import Notification from '../../generic/Notification';
import state from '../../State';
import { view } from 'react-easy-state';
import Loader from '../../generic/Loader';

export default view(class InformationProfile extends Component {
    componentWillUnmount() {
        state.informationProfile.loading = true;
        state.informationProfile.data = {
            name: '',
            content: '',
            isActive: true
        };
    }
    componentDidMount() {
        state.informationProfile.loading = true;
        state.informationProfile.type = this.props.match.params.type;
        if (this.props.match.params.node.indexOf('new') > -1) {
            state.informationProfile.data = {
                name: '',
                content: '',
                isActive: true
            };
            state.informationProfile.loading = false;
        } else {
            let options = ['id', 'name', 'content', 'isActive'];
            if(state.informationProfile.type === 'Supplier'){
                options.push('informationRequestEmail');
            }
            state.api.get(`${state.informationProfile.type === 'Supplier' ? 'suppliers' : 'articles'}/${this.props.match.params.node.replace(/_-_/g, '-')}${state.buildGetSelector(options)}`).then(res => {
                state.informationProfile.data = res.data;
                state.informationProfile.loading = false;
            })
        }
    }
    handleSaveProfile = () => {
        if (state.informationProfile.data.name && state.informationProfile.data.content) {
            state.api.post(`${state.informationProfile.type === 'Supplier' ? 'suppliers' : 'articles'}/${state.informationProfile.data.id ? state.informationProfile.data.id : ''}`, state.informationProfile.data).then(res => {
                new Notification(`Successfully Saved ${state.informationProfile.type}`);
                this.props.history.push('/data-management/information-centre/')
            })
        } else {
            new Notification('Missing Required Field(s)');
        }
    }
    render() {
        return (
            state.informationProfile.loading ? <Loader absolute colour="#f4f4f4" /> :
                <div className="grid-gap-big user-profile-grid">
                    <Card height={state.informationProfile.type === 'Supplier' ? '510px' : '440px'} style={{ padding: '16px', overflow: 'auto' }} className="full-width-form">
                        <h2 className="with-st">{state.informationProfile.data.name}<small>{state.informationProfile.type} Profile</small></h2>
                        <hr />
                        <div className="grid-1">
                            <span>
                                <label>Name</label>
                                <input type="text" defaultValue={state.informationProfile.data.name} onChange={e => state.informationProfile.data.name = e.currentTarget.value} />
                            </span>
                            <span>
                                <label>Content</label>
                                <textarea defaultValue={state.informationProfile.data.content} onChange={e => state.informationProfile.data.content = e.currentTarget.value.replace(/(<([^>]+)>)/ig, '')}>

                                </textarea>
                            </span>

                            {
                                state.informationProfile.type === 'Supplier' &&
                                [
                                    <span>
                                        <label>Information Request Email</label>
                                        <input type="text" defaultValue={state.informationProfile.data.informationRequestEmail} onChange={e => state.informationProfile.data.informationRequestEmail = e.currentTarget.value} />
                                    </span>
                                ]
                            }

                            <span>
                                <label>Active</label>
                                <select defaultValue={state.informationProfile.data.isActive ? 'y' : 'n'} onChange={e => state.informationProfile.data.isActive = e.currentTarget.value === 'y'}>
                                    <option value='n'>Inactive</option>
                                    <option value='y'>Active</option>
                                </select>
                            </span>
                        </div>
                        <button className={`icon-btn`} style={{ marginTop: '16px' }} onClick={this.handleSaveProfile}>
                            <i className="fas fa-save"></i>
                            <span>Save {state.informationProfile.type}</span>
                        </button>
                    </Card>
                </div>
        );
    }
})