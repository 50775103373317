import React, { Component } from 'react';
import Card from '../../generic/Card';
import Notification from '../../generic/Notification';
import Table from '../../generic/Table';
import Stat from '../../generic/Stat';
import state from '../../State';
import { view } from 'react-easy-state';
import '../../plugins/dateKit';
import Loader from '../../generic/Loader';

export default view(class SalesCallCentre extends Component {
  componentWillUnmount() {
    state.sales.data = {};
    state.sales.newData = null;
    state.sales.users = [];
    state.sales.userView = '';
    state.sales.view = 'today';
    state.sales.newView = 'today';
    state.sales.loading = true;
    state.sales.tableLoader = true;
  }
  componentDidMount() {
    state.sales.userView = state.user.id;
    state.sales.loading = true;
    state.sales.tableLoader = true;
    state.track({
      module: 'SALES',
      action: 'LOADED_SALES_HOME'
    });
    if (state.user.accessProspects) {
      state.api.post(`users/find${state.buildGetSelector(["id", "firstName", "lastName"])}`, {
        accessSales: true,
        isActive: true
      }).then(res => state.sales.users = res.data.filter(x => `${x.firstName} ${x.lastName}` !== `${state.user.firstName} ${state.user.lastName}`));
    }
    this.getInitialData();
  }
  getInitialData(){
    state.sales.loader = true;
    state.sales.view = 'today';
    state.sales.data = {};
    state.api.get(`customers/sales/new/${state.sales.userView}`).then(data => {
      state.sales.newData = data.data;
      state.sales.loader = false;
    });
    this.getToday();
  }
  getToday(){
    state.sales.view = 'today';
    if(!state.sales.data.today){
      state.sales.tableLoader = true;
      state.api.get(`customers/sales/today/${state.sales.userView}`).then(data => {
        state.sales.data.today = data.data;
        state.sales.tableLoader = false;
      });
    } else {
      state.sales.tableLoader = false;
    }
  }
  getPending(){
    state.sales.view = 'pending';
    if(!state.sales.data.pending){
      state.sales.tableLoader = true;
      state.api.get(`customers/sales/pending/${state.sales.userView}`).then(data => {
        state.sales.data.pending = data.data;
        state.sales.tableLoader = false;
      });
    } else {
      state.sales.tableLoader = false;
    }
  }
  getSigned(){
    state.sales.view = 'signed';
    if(!state.sales.data.signed){
      state.sales.tableLoader = true;
      state.api.get(`customers/sales/signed/${state.sales.userView}`).then(data => {
        state.sales.data.signed = data.data;
        state.sales.tableLoader = false;
      });
    } else {
      state.sales.tableLoader = false;
    }
  }
  getAos(){
    state.sales.view = 'aos';
    if(!state.sales.data.aos){
      state.sales.tableLoader = true;
      state.api.get(`customers/sales/aos/${state.sales.userView}`).then(data => {
        state.sales.data.aos = data.data;
        state.sales.tableLoader = false;
      });
    } else {
      state.sales.tableLoader = false;
    }
  }
  getOverdue(){
    state.sales.view = 'overdue';
    if(!state.sales.data.overdue){
      state.sales.tableLoader = true;
      state.api.get(`customers/sales/overdue/${state.sales.userView}`).then(data => {
        state.sales.data.overdue = data.data;
        state.sales.tableLoader = false;
      });
    } else {
      state.sales.tableLoader = false;
    }
  }
  getRecent(){
    state.sales.view = 'recent';
    if(!state.sales.data.recent){
      state.sales.tableLoader = true;
      state.api.get(`customers/sales/recent/${state.sales.userView}`).then(data => {
        state.sales.data.recent = data.data;
        state.sales.tableLoader = false;
      });
    } else {
      state.sales.tableLoader = false;
    }
  }
  getDormant(){
    state.sales.view = 'dormant';
    if(!state.sales.data.dormant){
      state.sales.tableLoader = true;
      state.api.get(`customers/sales/dormant/${state.sales.userView}`).then(data => {
        state.sales.data.dormant = data.data;
        state.sales.tableLoader = false;
      });
    } else {
      state.sales.tableLoader = false;
    }
  }
  get tableView() {
    let data = state.sales.data[state.sales.view] ? state.sales.data[state.sales.view].map(d => {
      return {
        "Campaign": d.campaign,
        "Company": d.name,
        "Contact": d.nameOnAccount,
        "Telephone": d.telephone,
        "Last Call": new Date(d.lastCallDate),
        "Callback": new Date(d.callDate),
        '_actionCell': {
          class: 'callable-customer-actions',
          icons: [
            {
              type: 'phone flip-phone',
              title: 'Call Prospect',
              handleClick: () => {
                this.props.history.push(`/sales-system/${encodeURIComponent(d.name.toLowerCase())}/start-call`)
              }
            },
            {
              type: 'folder',
              title: 'View Profile',
              handleClick: () => {
                this.props.history.push(`/sales-system/${encodeURIComponent(d.name.toLowerCase())}/`)
              }
            },
            {
              type: 'calendar',
              title: 'Arrange Callback',
              handleClick: () => {
                this.props.history.push(`/sales-system/${encodeURIComponent(d.name.toLowerCase())}/arrange-callback`)
              }
            },
            {
              type: 'times-circle',
              title: 'Deactivate Prospect',
              handleClick: () => {
                this.props.history.push(`/sales-system/${encodeURIComponent(d.name.toLowerCase())}/archive-customer`)
              }
            }
          ]
        }
      }
    }) : [];

    if(state.sales.view === 'today'){
      data = state.validation.ordersValuesByProperty(data, "Callback", false);
    } else {
      data = state.validation.ordersValuesByProperty(data, "Last Call", true);
    }

    return data;
  }

  handleStartCall = () => {
    if (state.sales.newData) {
      this.props.history.push(`/sales-system/${encodeURIComponent(state.sales.newData.toLowerCase())}/start-call`);
    } else {
      new Notification('No new prospects found');
    }
  }

  render() {
    return (
      [state.user.accessProspects ?
        <select className="sales-home-view-selector" value={state.sales.userView} onChange={e => {
          state.sales.userView = e.currentTarget.value;
          this.getInitialData();
        }}>
          <option value="">Current User</option>
          {state.sales.users.map((x, i) => <option key={i} value={x.id}>{x.firstName} {x.lastName}</option>)}
        </select>
        : null,
      <div className="grid-1 grid-gap-big">
        <div className="grid-2 grid-gap-big stats-mobile">
          <Stat label={state.sales.loader ? "Loading New Data" : "New Sales Call"} handleClick={() => state.sales.loader ? null : this.handleStartCall()} icon='phone flip-phone' colour={state.sales.loader ? "#76B44D40" : "#76B44D"} height="190px" />
          <Stat label="Create New Prospect" handleClick={() => this.props.history.push('/sales-system/new-prospect')} icon="user-plus" colour="#707070" height="190px" />
          {/* <Stat label="Contacted Customers" value={`0`} colour="#60BFC7" height="190px" /> */}
        </div>
        <Card height="calc(100vh - 270px)" style={{ padding: '16px' }}>
          <h2 className="with-st">Your Customers<small>All contacted customers assigned to you.</small></h2>
                        <hr />
          <div className="flex-row flex-row-padder">
          <button className={`icon-btn ${state.sales.view !== 'today' ? 'inactive-btn' : ''}`} style={{ marginBottom: '16px' }} onClick={this.getToday}>
              <i className="fas fa-calendar"></i>
              <span>Today's Callbacks</span>
              {/* {state.sales.counters.todaysCallbacks ? <span className="counter">{state.sales.counters.todaysCallbacks < 10 ? state.sales.counters.todaysCallbacks : '+'}</span> : null} */}
            </button>
            <button className={`icon-btn ${state.sales.view !== 'pending' ? 'inactive-btn' : ''}`} style={{ marginBottom: '16px' }} onClick={this.getPending}>
              <i className="fas fa-signature"></i>
              <span>Pending LOA</span>
            </button>
            <button className={`icon-btn ${state.sales.view !== 'signed' ? 'inactive-btn' : ''}`} style={{ marginBottom: '16px' }} onClick={this.getSigned}>
              <i className="fas fa-file-signature"></i>
              <span>Signed LOA</span>
            </button>
            <button className={`icon-btn ${state.sales.view !== 'aos' ? 'inactive-btn' : ''}`} style={{ marginBottom: '16px' }} onClick={this.getAos}>
              <i className="fas fa-user-tie"></i>
              <span>Available to Sell</span>
            </button>
            <button className={`icon-btn ${state.sales.view !== 'overdue' ? 'inactive-btn' : ''}`} style={{ marginBottom: '16px' }} onClick={this.getOverdue}>
              <i className="fas fa-calendar-times"></i>
              <span>Overdue Callbacks</span>
            </button>
            <button className={`icon-btn ${state.sales.view !== 'recent' ? 'inactive-btn' : ''}`} style={{ marginBottom: '16px' }} onClick={this.getRecent}>
              <i className="fas fa-phone-volume"></i>
              <span>Recently Dialled</span>
            </button>
            <button className={`icon-btn ${state.sales.view !== 'dormant' ? 'inactive-btn' : ''}`} style={{ marginBottom: '16px' }} onClick={this.getDormant}>
              <i className="fas fa-question"></i>
              <span>No Assignment</span>
            </button>
          </div>
          <Table
            tid="prospects"
            loading={state.sales.tableLoader}
            data={this.tableView}
            height="calc(100vh - 450px)"
            handleClick={e => this.props.history.push(`/sales-system/${encodeURIComponent(e.Company.toLowerCase())}/`)}
            icons={{
              fullscreen: false,
              download: false
            }}
          />
        </Card>
      </div>]
    );
  }
})
