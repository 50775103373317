import React, { Component } from 'react';
import Loader from '../../generic/Loader';
import state from '../../State';
import { view } from 'react-easy-state';
import Card from '../../generic/Card';
import SearchInput from '../../generic/SearchInput';
import Notification from '../../generic/Notification';
import Table from '../../generic/Table';

export default view(class DataMigration extends Component {
    componentDidMount() {
        state.dataMigration.loading = true;
        state.api.post(`users/find${state.buildGetSelector(['firstName', 'lastName', 'id'])}`, { isActive: true, accessSales: true }).then(res => {
            state.dataMigration.users = res.data;
            state.dataMigration.loading = false;
        })
    }
    componentWillUnmount() {
        this.reset();
    }
    reset(){
        state.dataMigration.datasets = [];
        state.dataMigration.datasetForm = {
            action: '', filter: '', value: '', customerName: ''
        }
    }
    renderValueComponent() {
        switch (state.dataMigration.datasetForm.filter) {
            case 'agent':
            case 'newagent':
                return <span>
                    <label>Agent</label>
                    <select value={state.dataMigration.datasetForm.value} onChange={e => state.dataMigration.datasetForm.value = e.currentTarget.value}>
                        <option value="">Select an agent</option>
                        {state.dataMigration.users.map((x, i) => <option key={i} value={x.id}>{x.firstName} {x.lastName}</option>)}
                    </select>
                </span>;
            case 'search':
                return <span>
                    <label>Customer</label>
                    <SearchInput
                        id="customerSearch"
                        select={e => {
                            console.log(e)
                            state.dataMigration.datasetForm.value = e.id;
                            state.dataMigration.datasetForm.customerName = e.heading;
                            return new Promise((r, j) => j());
                        }}
                        data={[]}
                        onSearch={q => this.retrieveCustomersByQuery(q)}
                        placeholder="Search for customer..."
                        value={''}
                    />
                </span>;
            default: return null;
        }
    }
    retrieveCustomersByQuery(q) {
        return new Promise(res => {
            state.api.post(`customers/search/50${state.buildGetSelector(['id', 'name'])}`, {
                name: q
            }).then(result => {
                res(result.data.map(x => {
                    return {
                        heading: x.name,
                        id: x.id
                    }
                }));
            })
        })
    }
    handleAddDataset() {
        if (
            state.dataMigration.datasetForm.action &&
            state.dataMigration.datasetForm.filter &&
            (state.dataMigration.datasetForm.value || state.dataMigration.datasetForm.filter === 'new' || state.dataMigration.datasetForm.filter === 'active' || state.dataMigration.datasetForm.filter === 'inactive')
        ) {
            state.dataMigration.addingDataset = true;
            let filters = {};
            switch (state.dataMigration.datasetForm.filter) {
                case 'agent':
                    filters.userID = state.dataMigration.datasetForm.value;
                    break;
                case 'new':
                    filters.hasCalled = false;
                    break;
                case 'active':
                    filters.isActive = true;
                    break;
                case 'inactive':
                    filters.isActive = false;
                    break;
                case 'newagent':
                    filters.hasCalled = false;
                    filters.userID = state.dataMigration.datasetForm.value;
                    break;
                case 'search':
                    filters.id = state.dataMigration.datasetForm.value;
                    break;
                default:
                    break;
            }
            state.api.post('customers/count', filters).then(res => {
                state.dataMigration.datasets.push({ ...state.dataMigration.datasetForm, count: res.data });
                console.log(state.dataMigration.datasets);
                new Notification('Added dataset');
                state.dataMigration.datasetForm = {
                    action: '', filter: '', value: '', customerName: ''
                };
                state.dataMigration.addingDataset = false;
            })
        } else {
            new Notification('Missing field(s) for dataset');
        }
    }
    readableDatasetName(dataset) {
        let str = '';
        switch (dataset.action) {
            case 'deactivate':
                str += 'Deactivate';
                break;
            case 'activate':
                str += 'Activate';
                break;
            case 'loa1on':
                str += 'Pend LOA Level 1';
                break;
            case 'loa2on':
                str += 'Pend LOA Level 2';
                break;
            case 'loa1off':
                str += 'Unpend LOA Level 1';
                break;
            case 'loa2off':
                str += 'Unpend LOA Level 2';
                break;
            default:
                let user = state.dataMigration.users.find(x => x.id === dataset.action);
                str += `Assign ${user.firstName} ${user.lastName} data`;
                break;
        }
        str += ' where ';
        let user;
        switch (dataset.filter) {
            case 'agent':
                user = state.dataMigration.users.find(x => x.id === dataset.value);
                str += `the assignee is ${user.firstName} ${user.lastName}`;
                break;
            case 'new':
                str += `the customers haven't been contacted`;
                break;
            case 'active':
                str += `the customers are active`;
                break;
            case 'inactive':
                str += `the customers are inactive`;
                break;
            case 'newagent':
                user = state.dataMigration.users.find(x => x.id === dataset.value);
                str += `the assignee is ${user.firstName} ${user.lastName} and the customers haven't been contacted`;
                break;
            case 'search':
                str += `the customer is ${dataset.customerName}`
                break;
            default:
                break;
        }
        return str;
    }
    handleMigration = () => {
        state.dataMigration.loading = true;
        new Promise(finish => {
            let datasetsParsed = 0;
            let migrationData = [];
            state.dataMigration.datasets.forEach(ds => {
                let dmn = {};
                switch (ds.action) {
                    case 'deactivate':
                        dmn.isActive = false;
                        break;
                    case 'activate':
                        dmn.isActive = true;
                        break;
                    case 'loa1on':
                        dmn.loaLevelOneEnv = 'manual';
                        break;
                    case 'loa2on':
                        dmn.loaLevelTwoEnv = 'manual';
                        break;
                    case 'loa1off':
                        dmn.loaLevelOneEnv = '';
                        break;
                    case 'loa2off':
                        dmn.loaLevelTwoEnv = '';
                        break;
                    default:
                        dmn.userID = ds.action;
                        break;
                }
                if (ds.filter === 'search') {
                    migrationData.push({ ...dmn, id: ds.value });
                    datasetsParsed++;
                    if (datasetsParsed === state.dataMigration.datasets.length) finish(migrationData);
                } else {
                    let filters = {};
                    switch (ds.filter) {
                        case 'agent':
                            filters.userID = ds.value;
                            break;
                        case 'new':
                            filters.hasCalled = false;
                            break;
                        case 'active':
                            filters.isActive = true;
                            break;
                        case 'inactive':
                            filters.isActive = false;
                            break;
                        case 'newagent':
                            filters.hasCalled = false;
                            filters.userID = ds.value;
                            break;
                        case 'search':
                            filters.id = ds.value;
                            break;
                        default:
                            break;
                    }
                    state.api.post(`customers/find${state.buildGetSelector(['id'])}`, filters).then(res => {
                        res.data.forEach(x => migrationData.push({ ...dmn, id: x.id }));
                        datasetsParsed++;
                        if (datasetsParsed === state.dataMigration.datasets.length) finish(migrationData);
                    })
                }
            });
        }).then(migrationData => {
            migrationData = migrationData.reduce((a, x) => {
                let i = a.findIndex(y => y.id === x.id);
                if (i === -1) {
                    a.push(x);
                } else {
                    a[i] = { ...a[i], ...x };
                }
                return a;
            }, []);
            console.log(migrationData);
            state.api.post('customers/bulk/update',migrationData).then(res => {
                console.log(res);
                state.dataMigration.loading = false;
                this.reset();
                new Notification('Migration Complete');
            });
        });
    }
    get tableView() {
        return state.dataMigration.datasets.map((x, i) => {
            return {
                'Migration Task': this.readableDatasetName(x),
                'Rows to be affected': x.count,
                '_actionCell': {
                    class: 'callable-customer-actions',
                    icons: [
                        {
                            type: 'times-circle',
                            title: 'Delete Migration',
                            handleClick: () => {
                                if (state.dataMigration.datasets.length === 1) {
                                    state.dataMigration.datasets = [];
                                } else {
                                    state.dataMigration.datasets.splice(i, 1);
                                }
                            }
                        }
                    ]
                }
            }
        });
    }
    render() {
        return state.dataMigration.loading ? <Loader absolute colour="#f4f4f4" /> : <div className="grid-data-migration grid-gap-big">
            <Card height="500px" style={{ padding: '16px', overflow: 'auto' }} className="full-width-form">
                {state.dataMigration.addingDataset ? <Loader absolute /> : [
                    <h2 className="with-st">New Dataset<small>Filter a new dataset to be migrated</small></h2>,
                    <hr />,
                    <div className="grid-1">
                        <span>
                            <label>Filter Option</label>
                            <select value={state.dataMigration.datasetForm.filter} onChange={e => state.dataMigration.datasetForm.filter = e.currentTarget.value}>
                                <option value="">Select a filter</option>
                                <option value="agent">By Agent</option>
                                <option value="new">All New Data</option>
                                <option value="active">All Active Data</option>
                                <option value="inactive">All Inactive Data</option>
                                <option value="newagent">All New Data by Agent</option>
                                <option value="search">Search for Customer</option>
                            </select>
                        </span>
                        {
                            this.renderValueComponent()
                        }
                        <span>
                            <label>Action</label>
                            <select value={state.dataMigration.datasetForm.action} onChange={e => state.dataMigration.datasetForm.action = e.currentTarget.value}>
                                <option value="">Select an action</option>
                                <option value="deactivate">Deactivate</option>
                                <option value="activate">Activate</option>
                                <option value="loa1on">Set Pending LOA Level 1</option>
                                <option value="loa2on">Set Pending LOA Level 2</option>
                                <option value="loa1off">Remove Pending LOA Level 1</option>
                                <option value="loa2off">Remove Pending LOA Level 2</option>
                                {state.dataMigration.users.map((x, i) => <option key={i} value={x.id}>{x.firstName} {x.lastName} (reassignment)</option>)}
                            </select>
                        </span>
                    </div>,
                    <button className={`icon-btn`} style={{ marginTop: '16px' }} onClick={this.handleAddDataset}>
                        <i className="fas fa-tasks"></i>
                        <span>Add Dataset</span>
                    </button>
                ]}
            </Card>
            {state.dataMigration.datasets.length ? <Card height="500px" style={{ padding: '16px', overflow: 'auto' }} className="full-width-form">
                <h2 className="with-st">Migration List<small>View all migrations before starting</small></h2>
                <hr />
                <Table
                    perPage={100}
                    tid="migrate"
                    data={this.tableView}
                    handleClick={this.handleClick}
                    height="320px"
                    icons={{
                        fullscreen: false,
                        download: false
                    }}
                />
                <button className={`icon-btn`} style={{ marginTop: '16px' }} onClick={this.handleMigration}>
                    <i className="fas fa-truck-loading"></i>
                    <span>Migrate Data</span>
                </button>
            </Card> : null}
        </div>
    }
});