import React, { Component } from 'react';
import state from '../../State';
import { view } from 'react-easy-state';
import Loader from '../../generic/Loader';
import Planner from '../../generic/Planner';

export default view(class SalesCalendar extends Component {
  componentWillUnmount() {
    state.salesCalendar.loading = false;
    state.salesCalendar.data = [];
  }
  componentDidMount() {
    state.salesCalendar.loading = true;
    state.track({
      module : 'SALES',
      action : `LOADED_SALES_CALENDAR`
    });
    state.api.post(`customers/find${state.buildGetSelector(["id", "name", "nameOnAccount", "callDate", "futureEndDate"])}`, {
      userID: state.user.id,
      isActive: true
    }).then(resp => {
      state.salesCalendar.data = resp.data.filter(x => x.callDate || x.futureEndDate).map(x => {
        return {
            id : x.name,
            date : new Date(x.callDate ? x.callDate : x.futureEndDate),
            data : x.name
        }
      });
      state.salesCalendar.loading = false;
    });
  }
  render() {
    return (state.salesCalendar.loading ? <Loader absolute colour="#f4f4f4" /> :
      <Planner handler={e => this.props.history.push(`/sales-system/${(encodeURIComponent(e.toLowerCase()))}/`)} data={
            state.salesCalendar.data
      }/>
    );
  }
})
