/* eslint-disable */
import React, { Component } from 'react';
import state from '../State';
import { view } from 'react-easy-state';
import axios from 'axios';
import SearchInput from './SearchInput';

export default view(class AddressInput extends Component {
    constructor() {
        super();
        this.handleAddressSelect = this.handleAddressSelect.bind(this);
    }
    componentDidMount(){
        state.addressInput[this.props.id] = {
            data: {
                addressLine1: "",
                addressLine2: "",
                addressLine3: "",
                addressLine4: "",
                postcode: ""
            },
            open: false
        }
        if(this.props.data){
            if(this.props.data.addressLine1) state.addressInput[this.props.id].data.addressLine1 = this.props.data.addressLine1;
            if(this.props.data.addressLine2) state.addressInput[this.props.id].data.addressLine2 = this.props.data.addressLine2;
            if(this.props.data.addressLine3) state.addressInput[this.props.id].data.addressLine3 = this.props.data.addressLine3;
            if(this.props.data.addressLine4) state.addressInput[this.props.id].data.addressLine4 = this.props.data.addressLine4;
            if(this.props.data.postcode) state.addressInput[this.props.id].data.postcode = this.props.data.postcode;
        }
    }
    handleAddressSelect(d) {
        return new Promise((res, rej) => {
            if (d.type != "Address") {
                axios.get('https://services.postcodeanywhere.co.uk/Capture/Interactive/Find/v1.00/json3.ws', {
                    params: {
                        Key: state.keys.pca,
                        Countries: "GB",
                        Limit: 10,
                        Container: d.ID
                    }
                }).then(result => {
                    if (!result.data.Items.length) {
                        res([]);
                    } else if (result.data.Items[0].Error) {
                        res([]);
                    } else {

                        res(result.data.Items.map(item => {
                            return {
                                heading: item.Text,
                                subHeading: item.Description,
                                ID: item.Id,
                                type: item.Type
                            }
                        }))
                    }
                });
            } else {
                axios.get('https://services.postcodeanywhere.co.uk/Capture/Interactive/Retrieve/v1.00/json3.ws', {
                    params: {
                        Key: state.keys.pca,
                        Id: d.ID
                    }
                }).then(result => {
                    state.addressInput[this.props.id].data.addressLine1 = result.data.Items[0].Line1;
                    state.addressInput[this.props.id].data.addressLine2 = result.data.Items[0].Line2;
                    state.addressInput[this.props.id].data.addressLine3 = result.data.Items[0].AdminAreaName;
                    state.addressInput[this.props.id].data.addressLine4 = result.data.Items[0].City;
                    state.addressInput[this.props.id].data.postcode = result.data.Items[0].PostalCode;
                    this.props.change(state.addressInput[this.props.id].data);
                    state.addressInput[this.props.id].open = false;
                    state.searchInput.nodes[this.props.id].value = '';
                    state.searchInput.nodes[this.props.id].filteredData = [];
                    rej();
                });
            }
        })
    }
    handleSearch(q) {
        return new Promise(res => {
            axios.get('https://services.postcodeanywhere.co.uk/Capture/Interactive/Find/v1.00/json3.ws', {
                params: {
                    Key: state.keys.pca,
                    Countries: "GB",
                    Limit: 10,
                    Container: "",
                    Text: q
                }
            }).then(result => {
                if (!result.data.Items.length) {
                    res([]);
                } else if (result.data.Items[0].Error) {
                    res([]);
                } else {
                    res(result.data.Items.map(item => {
                        return {
                            heading: item.Text,
                            subHeading: item.Description,
                            ID: item.Id,
                            type: item.Type
                        }
                    }))
                }
            });
        });
    }
    render() {
        return <SearchInput
                        select={this.handleAddressSelect}
                        data={[]}
                        onSearch={this.handleSearch}
                        placeholder="Start typing an address"
                        id={this.props.id}
                    />
    }
})