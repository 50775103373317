import React, { Component } from 'react';
import Card from '../../generic/Card';
import Loader from '../../generic/Loader';
import state from '../../State';
import { view } from 'react-easy-state';

export default view(class CustomerCall extends Component {
    componentDidMount() {
        state.api.get(`customers/sales/call/${encodeURIComponent(this.props.match.params.customer)}`).then(res => {
            if (res.data) {
                state.customerCall.data = res.data;
                state.track({
                    module: 'SALES',
                    action: `PENDING_CALL`,
                    customerID: state.customerCall.data.id
                });
                state.customerCall.loading = false;
                window.location.href = `tel:${state.customerCall.data.telephone.replace(/ /g, '')}`;
            } else {
                this.props.history.push('/sales-system')
            }
        })
    }
    componentWillUnmount() {
        state.customerCall.data = [];
        state.customerCall.loading = true;
    }
    handleNoAnswer = () => {
        state.customerCall.loading = true;
        state.track({
            module: 'SALES',
            action: `NO_ANSWER`,
            customerID: state.customerCall.data.id
        });
        state.api.post(`customers/${state.customerCall.data.id}`, { hasInitialNoAnswer: true, noAnswerDate: new Date().toJSON().split('T')[0] + ' ' + new Date().toTimeString().split(' ')[0] }).then(res => {
            this.props.history.push('/sales-system');
        });
    }
    handleUnusable = () => {
        state.customerCall.loading = true;
        state.track({
            module: 'SALES',
            action: `UNUSABLE`,
            customerID: state.customerCall.data.id
        });
        state.api.post(`customers/${state.customerCall.data.id}`, { isActive: false, deactivationDate: new Date() }).then(() => {
            this.props.history.push('/sales-system');
        });
    }
    handleAnswered = () => {
        state.customerCall.loading = true;
        state.track({
            module: 'SALES',
            action: `ANSWERED_CALL`,
            customerID: state.customerCall.data.id
        });
        state.api.post(`customers/${state.customerCall.data.id}`, { hasCalled: true }).then(() => {
            this.props.history.push('on-call');
        });
    }
    render() {
        return (
            state.customerCall.loading ? <Loader absolute colour="#f4f4f4" /> :
                <div className="grid-center" style={{ height: 'calc(100vh - 66px)' }}>
                    <Card height="auto" width="600px" style={{ padding: '50px 0px' }}>
                        <div className="center-contents">
                            <h1 className="with-st">Calling {state.customerCall.data.nameOnAccount}<small>{state.customerCall.data.nameOnAccountTitle} at {state.customerCall.data.name}</small></h1>
                            <div className="flex-row flex-row-padder" style={{ marginTop: '50px' }}>
                                <button className={`icon-btn`} style={{ width: 'auto', background: '#76B44D' }} onClick={this.handleAnswered}>
                                    <i className="fas fa-phone flip-phone"></i>
                                    <span>Answered</span>
                                </button>
                                <button className={`icon-btn`} style={{ width: 'auto', background: '#D51F57' }} onClick={this.handleNoAnswer}>
                                    <i className={`fas fa-phone-slash flip-phone`}></i>
                                    <span>No Answer</span>
                                </button>

                                <button className={`icon-btn`} style={{ width: 'auto', background: '#535353' }} onClick={this.handleUnusable}>
                                    <i className={`fas fa-do-not-enter`}></i>
                                    <span>Unusable</span>
                                </button>

                            </div>
                        </div>
                    </Card>
                </div>
        );
    }
})
