import React, { Component } from 'react';
import { Prompt } from 'react-router'
import Card from '../../generic/Card';
import Loader from '../../generic/Loader';
import Stat from '../../generic/Stat';
import email from '../../plugins/email';
import state from '../../State';
import { view } from 'react-easy-state';
import Notification from '../../generic/Notification';
import AddressInput from '../../generic/AddressInput';
import UploadButton from '../../generic/UploadButton';
import ReactQuill from 'react-quill';
import downloadHelper from '../../plugins/downloadFile';
import config from '../../config';

export default view(class CustomerProfile extends Component {
    constructor() {
        super();
        this.quill = null;
        this.quillComp = null;
    }
    attachQuillRefs() {
        if (!state.customerProfile.loading) {
            if (typeof this.quillComp.getEditor !== 'function') return;
            this.quill = this.quillComp.getEditor();
        }
    }
    componentDidUpdate(prevProps){
        this.attachQuillRefs();
        if (this.props.match.params.customer) {
            if (this.props.match.params.customer !== prevProps.match.params.customer) {
                this.retrieveData();
            }
        }
    }
    retrieveData() {
        state.customerProfile.loading = true;
        console.time("Loading customer")
        state.api.get(`customers/sales/profile/${encodeURIComponent(this.props.match.params.customer)}`).then(res => {
            console.timeEnd("Loading customer");
            if (res.data) {
                res.data.managedBy = res.data.user.firstName + ' ' + res.data.user.lastName;
                delete res.data.user;
                state.customerProfile.data = res.data;
                state.customerProfile.data.callDate = state.customerProfile.data.callDate ? new Date(state.customerProfile.data.callDate) : null;
                state.customerProfile.data.notes = state.customerProfile.data.notes ? state.customerProfile.data.notes[0] === '{' ? JSON.parse(state.customerProfile.data.notes) : state.customerProfile.data.notes : null;
                if (state.customerProfile.data.hasLoaLevelOne) {
                    if (state.customerProfile.data.loaLevelOneEnv.indexOf('.pdf') === -1) {
                        state.eSign.get(state.customerProfile.data.loaLevelOneEnv + '/uri').then(res => {
                            state.customerProfile.loas.one = res.data;
                        });
                    } else {
                        state.api.get(`storage/url?key=${encodeURIComponent(state.customerProfile.data.loaLevelOneEnv)}`).then(res => {
                            state.customerProfile.loas.one = res.data;
                        });
                    }
                }
                if (state.customerProfile.data.hasLoaLevelTwo) {
                    if (state.customerProfile.data.loaLevelTwoEnv.indexOf('.pdf') === -1) {
                        state.eSign.get(state.customerProfile.data.loaLevelTwoEnv + '/uri').then(res => {
                            state.customerProfile.loas.two = res.data;
                        });
                    } else {
                        state.api.get(`storage/url?key=${encodeURIComponent(state.customerProfile.data.loaLevelTwoEnv)}`).then(res => {
                            state.customerProfile.loas.two = res.data;
                        });
                    }
                }
                state.track({
                    module: 'SALES',
                    action: 'LOADED_CUSTOMER_PROFILE',
                    customerID: state.customerProfile.data.id
                });
                if (state.customerProfile.onCall) {
                    state.track({
                        module: 'SALES',
                        action: `STARTED_CALL`,
                        customerID: state.customerProfile.data.id
                    });
                }
                state.customerProfile.loading = false;
            } else {
                this.props.history.push('/sales-system')
            }
        })
    }
    componentDidMount() {
        this.attachQuillRefs();
        if (this.props.match.path.indexOf('on-call') > -1) {
            state.customerProfile.onCall = true;
            state.timer.show = true;
            state.timer.start();
        }
        this.retrieveData();
    }
    componentWillUnmount() {
        if (state.customerProfile.onCall) {
            state.api.post(`customers/${state.customerProfile.data.id}`, { lastCallDate: new Date().toJSON().split('T')[0] + ' ' + new Date().toTimeString().split(' ')[0] });
        }
        state.customerProfile.data = [];
        state.customerProfile.loas = {
            one: '',
            two: ''
        }
        state.customerProfile.onCall = false;
        state.customerProfile.loading = true;
        state.timer.show = false;
    }
    prepareLoa(level) {
        return new Promise((finish, invalid) => {
            if (
                state.customerProfile.data.addressLine1
                &&
                state.customerProfile.data.postcode
                &&
                state.customerProfile.data.name
                &&
                state.customerProfile.data.nameOnAccountTitle
                &&
                state.customerProfile.data.nameOnAccount
            ) {
                new Notification('Preparing LOA');
                state.api.get(`storage?key=loa-${level}.pdf`).then(file => {
                    state.eSign.post('fields', JSON.stringify(file.data.data), {
                        headers: { 'Authorization': `Bearer ${state.keys.esign}`, 'Accept': 'application/json', 'Content-Type': 'application/json' },
                    }).then(fields => {
                        let updatedFields = fields.data.reduce((updates, field) => {
                            updates.push({
                                Key: field,
                                Value: state.customerProfile.data[field]
                            });
                            return updates;
                        }, []);
                        updatedFields.push({
                            Key: 'date',
                            Value: new Date().toLocaleDateString('en-GB')
                        });
                        updatedFields.push({
                            Key: 'address',
                            Value: state.addressPreview({ a: state.customerProfile.data.addressLine1, b: state.customerProfile.data.addressLine2, c: state.customerProfile.data.addressLine3, d: state.customerProfile.data.addressLine4 })
                        });
                        state.eSign.post('fillfields', JSON.stringify({
                            DocumentBase64: file.data.data,
                            FormFields: updatedFields
                        })).then(updatedFile => {
                            finish(updatedFile.data);
                        });
                    })
                });
            } else {
                new Notification('Missing fields for LOA');
                invalid();
            }
        });
    }
    handleSendLoa(level) {
        let start = new Date();
        state.track({
            module: 'SALES',
            action: `SENT_LOA_LEVEL_${level}`,
            customerID: state.customerProfile.data.id
        });
        if (!state.validation.isEmail(state.customerProfile.data.email)) {
            new Notification('Invalid Email Address');
        } else {
            state.customerProfile.qaBusy = true;
            this.prepareLoa(level).then(data => {
                new Notification('Sending LOA');
                state.eSign.post('', {
                    "EmailConfiguration": {
                        "FromFullName": config.meta.title,
                        "FromEmail": config.email.noreply,
                        "Heading": `Letter of Authority - Level ${level}`,
                        "Logo": config.appearance.logo.dark,
                        "Body": state.user.loaMessage ? state.user.loaMessage : "This document will allow us to proceed with your energy pricing.",
                        "CarbonCopies": []
                    },
                    "Signature": {
                        "FullName": state.customerProfile.data.nameOnAccount,
                        "Email": state.customerProfile.data.email,
                        "Hook": `${config.api.system}common/signedloa`,
                        "Colour": "#eee",
                        "Heading": `Letter of Authority - Level ${level}`,
                        "Logo": config.appearance.logo.dark,
                        "SubHeading": state.user.loaMessage ? state.user.loaMessage : "This document will allow us to proceed with your energy pricing.",
                        "HookData": state.customerProfile.data.name + "{" + level
                    },
                    "EnvelopeContents": {
                        "SignBox": true,
                        "DocumentName": state.customerProfile.data.name + ' - LOA Level ' + level,
                        "DocumentBase64": data
                    }
                }).then(env => {
                    state.customerProfile.data[`loaLevel${level === 1 ? 'One' : 'Two'}Env`] = env.data;
                    let update = { [`LoaLevel${level === 1 ? 'One' : 'Two'}Env`]: state.customerProfile.data[`loaLevel${level === 1 ? 'One' : 'Two'}Env`], email: state.customerProfile.data.email };
                    state.api.post(`customers/${state.customerProfile.data.id}`, update).then(res => {
                        state.customerProfile.qaBusy = false;
                        new Notification('Sent LOA Successfully');
                    });
                });
            }).catch(() => state.customerProfile.qaBusy = false);
        }
    }
    handleDownloadLoa(level) {
        state.customerProfile.qaBusy = true;
        state.track({
            module: 'SALES',
            action: `DOWNLOADED_LOA_LEVEL_${level}_TEMPLATE`,
            customerID: state.customerProfile.data.id
        });
        this.prepareLoa(level).then(data => {
            downloadHelper.file(`${state.customerProfile.data.name} - LOA Level ${level}.pdf`, 'application/pdf', data);
            state.customerProfile.qaBusy = false;
            new Notification('Downloaded LOA Successfully');
        }).catch(() => state.customerProfile.qaBusy = false);
    }

    handleViewDocusign(level) {
        state.track({
            module: 'SALES',
            action: `VIEWING_LOA_LEVEL_${level}_SIGNED`,
            customerID: state.customerProfile.data.id
        });
        window.open(state.customerProfile.loas[level === 1 ? 'one' : 'two'], '_blank');
    }
    handleRequestCall() {
                if (state.customerProfile.requestCallTime) {
                    let body = email.replace('[name]', 'Call Uploaders')
                    .replace('[content]', `${state.user.firstName} ${state.user.lastName} has requested a call to <b>${state.customerProfile.data.name}</b> to be uploaded.<br/><br/>   
                           <table>
                               <tbody>
                                <tr>
                                <td>Customer Telephone</td>
                                <td><b>${state.customerProfile.data.telephone}</b></td>
                            </tr>
                            <tr>
                                       <td>Agent Name</td>
                                       <td><b>${state.customerProfile.data.managedBy}</b></td>
                                    </tr>
                                   <tr>
                                       <td>Time Dialled (approx.)</td>
                                        <td><b>${state.customerProfile.requestCallTime.toLocaleString()}</b></td>
                                    </tr>
                                </tbody>
                            </table>
                            
                            `)
                        .replace('[btnLink]', `${config.generic.url}/data-management/upload-calls/`)
                        .replace('[btnText]', 'Upload Call');
                    state.api.post('sendgrid', {
                        From: { key: config.meta.title, value: config.email.noreply },
                        To: { key: config.email.callUploader.subject, value: config.email.callUploader.email },
                        Subject: `Call Upload Request from ${state.user.firstName} ${state.user.lastName}`,
                        Body: body
                    }).then(res => {
                        new Notification('Sent Request');
                    });
                } else {
                    new Notification('Select a date and time');
                }
            }
    handleSaveProfile = () => {
        console.time("save");
        state.customerProfile.data.lastUpdated = new Date();
        state.customerProfile.data.notes = this.quillComp.makeUnprivilegedEditor(this.quill).getContents();
        state.track({
            module: 'SALES',
            action: `SAVED_CUSTOMER_PROFILE`,
            customerID: state.customerProfile.data.id
        });
        let customerData = {...state.customerProfile.data};
        delete customerData.hasLoaLevelOne;
        delete customerData.hasLoaLevelTwo;
        new Notification('Saving Profile');
        state.api.post(`customers/${state.customerProfile.data.id}`, { ...customerData, notes: JSON.stringify(state.customerProfile.data.notes) }).then(res => {
            new Notification('Saved Profile');
            console.timeEnd("save");
        });
    }

    handleEndCall = () => {
        state.customerProfile.onCall = false;
        state.timer.show = false;
        state.track({
            module: 'SALES',
            action: `ENDED_CALL`,
            customerID: state.customerProfile.data.id
        });
        new Notification('Ended Call');
        let updates = { lastCallDate: new Date().toJSON().split('T')[0] + ' ' + new Date().toTimeString().split(' ')[0], hasCalled: true };
        if(state.customerProfile.data.callDate){
           updates.callDate = null;
        }
        state.api.post(`customers/${state.customerProfile.data.id}`, updates).then(res => {
           this.props.history.push(`/sales-system/${encodeURIComponent(state.customerProfile.data.name.toLowerCase())}/`);
        });
    }

    handleUploadLoa(level, base64) {
        state.api.post('storage', {
            key: `${state.customerProfile.data.id}/loa-${level}.pdf`,
            data: base64,
            private: true
        }).then(env => {
            state.customerProfile.data[`loaLevel${level === 1 ? 'One' : 'Two'}Env`] = `${state.customerProfile.data.id}/loa-${level}.pdf`;
            state.customerProfile.data[`hasLoaLevel${level === 1 ? 'One' : 'Two'}`] = true;
            state.customerProfile.data[`LoaLevel${level === 1 ? 'One' : 'Two'}SignDate`] = new Date().toJSON().split('T')[0] + ' ' + new Date().toTimeString().split(' ')[0];

            let update = { [`LoaLevel${level === 1 ? 'One' : 'Two'}Env`]: state.customerProfile.data[`loaLevel${level === 1 ? 'One' : 'Two'}Env`] };
            update[`LoaLevel${level === 1 ? 'One' : 'Two'}SignDate`] = new Date().toJSON().split('T')[0] + ' ' + new Date().toTimeString().split(' ')[0];
            update[`hasLoaLevel${level === 1 ? 'One' : 'Two'}`] = true;

            state.track({
                module: 'SALES',
                action: `SIGNED_LOA_LEVEL_${level}`,
                customerID: state.customerProfile.data.id
            })

            state.api.post(`customers/${state.customerProfile.data.id}`, update).then(res => {
                new Notification('Uploaded LOA Successfully');
            });

        });
    }
    handleUploadDocument(type, base64) {
        state.api.post('storage', {
            key: `${state.customerProfile.data.id}/${type}-completed.pdf`,
            data: base64,
            private: true
        }).then(env => {
            state.customerProfile.data[type === 'pricing' ? 'hasPricingPdfCompleted' : 'hasDirectDebitMandateCompleted'] = true;
            state.api.post(`customers/${state.customerProfile.data.id}`, { [type === 'pricing' ? 'hasPricingPdfCompleted' : 'hasDirectDebitMandateCompleted']: true }).then(res => {
                new Notification('Uploaded Document Successfully');
            });
        });
        let formalFileName = type === 'pricing' ? 'Pricing Sheet' : 'Direct Debit Mandate';
        let body = email.replace('[name]', 'Pricing Team')
            .replace('[content]', `${state.user.firstName} ${state.user.lastName} has uploaded the completed ${formalFileName} for ${state.customerProfile.data.name}.<br/><br/>You will now be able to view this in the Pricing Home section on BSGE.`)
            .replace('[btnLink]', `${config.generic.url}/pricing-upload`)
            .replace('[companyName]', config.meta.title)
            .replace('[logoPng]',config.appearance.logo.email)
            .replace('[btnText]', 'View');
        state.api.post('sendgrid', {
            From: { key: config.meta.title, value: config.email.noreply },
            To: { key: config.email.pricing.subject, value: config.email.pricing.email },
            Subject: `${state.customerProfile.data.name} - ${formalFileName} Completed`,
            Body: body
        });
    }
    handleDownloadDocument(type) {
        new Notification('Preparing Document');
        state.api.get(`storage/url?key=${decodeURIComponent(state.customerProfile.data.id + '/' + type + '.pdf')}`).then(res => {
            window.open(res.data, '_blank');
        })
    }
    handleSendDocument(type) {
        if (!state.validation.isEmail(state.customerProfile.data.email)) {
            new Notification('Invalid Email Address');
        } else {
            new Notification('Preparing Document');
            let formalFileName = type === 'pricing' ? 'Pricing Sheet' : 'Direct Debit Mandate';
            state.api.get(`storage/url?key=${decodeURIComponent(state.customerProfile.data.id + '/' + type + '.pdf')}`).then(res => {
                let body = email.replace('[name]', state.customerProfile.data.name)
                    .replace('[content]', `Please click the link to see the ${formalFileName}.`)
                    .replace('[btnLink]', res.data)
                    .replace('[companyName]', config.meta.title)
                    .replace('[logoPng]',config.appearance.logo.email)
                    .replace('[btnText]', 'View file');
                state.api.post('sendgrid', {
                    From: { key: config.meta.title, value: config.email.noreply },
                    To: { key: state.customerProfile.data.nameOnAccount, value: state.customerProfile.data.email },
                    Subject: formalFileName,
                    Body: body
                }).then(res => {
                    new Notification('Sent Document');
                });
            })
        }
    }
    render() {
        return (
            state.customerProfile.loading ? <Loader absolute colour="#f4f4f4" /> :
                <div className="grid-1 grid-gap-big">
                    <div className={`grid-3 grid-gap-big`}>
                        {
                            state.customerProfile.onCall ?
                                <Stat label="End Call" handleClick={this.handleEndCall} icon='phone-slash flip-phone' colour="#D51F57" height="190px" />
                                :
                                <Stat label="Start Call" handleClick={() => this.props.history.push('start-call')} icon='phone flip-phone' colour="#76B44D" height="190px" />
                        }

                        <Stat label="Save Profile" handleClick={this.handleSaveProfile} icon='save' colour="rgb(96, 191, 199)" height="190px" />
                        <Stat label="Arrange Callback" handleClick={() => {
                            this.handleSaveProfile();
                            this.props.history.push('arrange-callback')
                        }} icon='calendar' colour="rgb(47, 124, 174)" height="190px" />
                    </div>
                    <div className="grid-3 grid-gap-big">
                        <Card height="900px" style={{ padding: '16px', overflow: 'auto' }} className="grid-span-2 full-width-form">
                            <h2 className="with-st">{state.customerProfile.data.name}<small>Customer Profile</small></h2>
                            <hr />
                            <div className="grid-2 grid-gap-big">
                                <div>
                                    <span>
                                        <label>Company Name</label>
                                        <input type="text" defaultValue={state.customerProfile.data.name} onChange={e => state.customerProfile.data.name = e.currentTarget.value} />
                                    </span>
                                    <span>
                                        <label>Company Number</label>
                                        <input type="text" defaultValue={state.customerProfile.data.companyNumber} onChange={e => state.customerProfile.data.companyNumber = e.currentTarget.value} />
                                    </span>
                                    <span>
                                        <label>Contact Name</label>
                                        <input type="text" defaultValue={state.customerProfile.data.nameOnAccount} onChange={e => state.customerProfile.data.nameOnAccount = e.currentTarget.value} />
                                    </span>
                                    <span>
                                        <label>Contact Job Title</label>
                                        <input type="text" defaultValue={state.customerProfile.data.nameOnAccountTitle} onChange={e => state.customerProfile.data.nameOnAccountTitle = e.currentTarget.value} />
                                    </span>
                                    <span>
                                        <label>Email Address</label>
                                        <input type="text" defaultValue={state.customerProfile.data.email} onChange={e => state.customerProfile.data.email = e.currentTarget.value} />
                                    </span>
                                    <span>
                                        <label>Telephone Number</label>
                                        <input type="text" defaultValue={state.customerProfile.data.telephone} onChange={e => state.customerProfile.data.telephone = e.currentTarget.value} />
                                    </span>
                                    <span>
                                        <label>Contract End Date</label>
                                        <input type="date" defaultValue={state.customerProfile.data.futureEndDate ? new Date(state.customerProfile.data.futureEndDate).toJSON().substr(0, 10) : ''} onChange={e => state.customerProfile.data.futureEndDate = new Date(new Date(e.currentTarget.value).setHours(9))} />
                                    </span>
                                    <span>
                                        <label>Callback Date</label>
                                        <input type="datetime-local" defaultValue={state.customerProfile.data.callDate ? new Date(state.customerProfile.data.callDate).toJSON().substr(0, 16) : ''} onChange={e => state.customerProfile.data.callDate = e.currentTarget.value === '' ? null : e.currentTarget.value} />
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        <label>Address Autofill</label>
                                        <AddressInput change={e => {
                                            Object.assign(state.customerProfile.data, e);
                                        }
                                        } />
                                    </span>
                                    <span>
                                        <label>Address Line 1</label>
                                        <input type="text" value={state.customerProfile.data.addressLine1} onChange={e => state.customerProfile.data.addressLine1 = e.currentTarget.value} />
                                    </span>
                                    <span>
                                        <label>Address Line 2</label>
                                        <input type="text" value={state.customerProfile.data.addressLine2} onChange={e => state.customerProfile.data.addressLine2 = e.currentTarget.value} />
                                    </span>
                                    <span>
                                        <label>Address Line 3</label>
                                        <input type="text" value={state.customerProfile.data.addressLine3} onChange={e => state.customerProfile.data.addressLine3 = e.currentTarget.value} />
                                    </span>
                                    <span>
                                        <label>Address Line 4</label>
                                        <input type="text" value={state.customerProfile.data.addressLine4} onChange={e => state.customerProfile.data.addressLine4 = e.currentTarget.value} />
                                    </span>
                                    <span>
                                        <label>Postcode</label>
                                        <input type="text" value={state.customerProfile.data.postcode} onChange={e => state.customerProfile.data.postcode = e.currentTarget.value} />
                                    </span>
                                    <span>
                                        <label>Managed By</label>
                                        <input type="text" defaultValue={state.customerProfile.data.managedBy} readOnly />
                                    </span>
                                </div>
                            </div>
                            <span>
                                <label>Notes</label>
                                <ReactQuill ref={el => { this.quillComp = el }} defaultValue={state.customerProfile.data.notes} />
                            </span>
                        </Card>
                        <Card height="900px" style={{ padding: state.customerProfile.qaBusy ? 0 : '16px', overflow: 'auto' }} className="full-width-form">
                            {
                                state.customerProfile.qaBusy ? <Loader absolute /> :
                                    [<h2 className="with-st">Quick Actions<small>Documents &amp; LOAs</small></h2>,
                                    <hr />,
                                    <div className="grid-1 grid-gap-big">
                                        <div>
                                        <span>
                                                <label>Campaign</label>
                                                {console.log(state.customerProfile.data)}
                                                <input type="text" defaultValue={state.customerProfile.data.campaign} onChange={e => state.customerProfile.data.campaign = e.currentTarget.value} />
                                            </span>
                                            <span>
                                                <label>Stage</label>
                                                <select value={state.customerProfile.data.stage} onInput={e => state.customerProfile.data.stage = e.currentTarget.value}>
                                                    <option value="">No stage set</option>
                                                    <option value="unsure">Unsure</option>
                                                    <option value="notinterested">Not Interested</option>
                                                    <option value="mildinterest">Mild Interest</option>
                                                    <option value="highinterest">High Interest</option>
                                                </select>
                                            </span>

                                            <label>LOA Level One</label>
                                            <div className="flex-row flex-row-padder">
                                                <button onClick={() => this.handleSendLoa(1)}>
                                                    <span>{state.customerProfile.data.loaLevelOneEnv ? 'Send Again' : 'Send'}</span>
                                                </button>
                                                <button onClick={() => this.handleDownloadLoa(1)}>

                                                    <span>Template</span>
                                                </button>
                                                <UploadButton types={['pdf']} handle={data => this.handleUploadLoa(1, data)} icon={'file-upload'} text={'Upload'} />
                                            </div>
                                            <div className="flex-row flex-row-padder">
                                                <button style={{ marginTop: '5px', background: state.customerProfile.loas.one ? 'rgb(118, 180, 77)' : 'rgb(64, 64, 64)' }} disabled={!state.customerProfile.loas.one} onClick={() => this.handleViewDocusign(1)}>

                                                    <span>View Signed</span>
                                                </button>
                                            </div>
                                            <label>LOA Level Two</label>
                                            <div className="flex-row flex-row-padder">
                                                <button onClick={() => this.handleSendLoa(2)}>

                                                    <span>{state.customerProfile.data.loaLevelTwoEnv ? 'Send Again' : 'Send'}</span>
                                                </button>
                                                <button onClick={() => this.handleDownloadLoa(2)}>

                                                    <span>Template</span>
                                                </button>
                                                <UploadButton types={['pdf']} handle={data => this.handleUploadLoa(2, data)} icon={'file-upload'} text={'Upload'} />
                                            </div>
                                            <div className="flex-row flex-row-padder">
                                                <button style={{ marginTop: '5px', background: state.customerProfile.loas.two ? 'rgb(118, 180, 77)' : 'rgb(64, 64, 64)' }} disabled={!state.customerProfile.loas.two} onClick={() => this.handleViewDocusign(2)}>

                                                    <span>View Signed</span>
                                                </button>
                                            </div>
                                            <label>Direct Debit Mandate</label>
                                            <div className="flex-row flex-row-padder">
                                                <button onClick={() => this.handleSendDocument('ddmandate')} disabled={!state.customerProfile.data.hasDirectDebitMandateDocument} style={{ background: state.customerProfile.data.hasDirectDebitMandateDocument ? 'rgb(54, 142, 201)' : 'rgb(64, 64, 64)' }} >

                                                    <span>Send</span>
                                                </button>
                                                <button disabled={!state.customerProfile.data.hasDirectDebitMandateDocument} style={{ background: state.customerProfile.data.hasDirectDebitMandateDocument ? 'rgb(54, 142, 201)' : 'rgb(64, 64, 64)' }} onClick={() => this.handleDownloadDocument('ddmandate')}>

                                                    <span>Download</span>
                                                </button>
                                                <UploadButton types={['pdf']} handle={data => this.handleUploadDocument('ddmandate', data)} icon={'file-upload'} text={'Upload'} />
                                            </div>
                                            <div className="flex-row flex-row-padder">
                                                <button style={{ marginTop: '5px', background: state.customerProfile.data.hasDirectDebitMandateCompleted ? 'rgb(118, 180, 77)' : 'rgb(64, 64, 64)' }} disabled={!state.customerProfile.data.hasDirectDebitMandateCompleted} onClick={() => this.handleDownloadDocument('ddmandate-completed')}>

                                                    <span>View Signed</span>
                                                </button>
                                            </div>
                                            <label>Pricing PDF</label>
                                            <div className="flex-row flex-row-padder">
                                                <button onClick={() => this.handleSendDocument('pricing')} disabled={!state.customerProfile.data.hasPricingPdfDocument} style={{ background: state.customerProfile.data.hasPricingPdfDocument ? 'rgb(54, 142, 201)' : 'rgb(64, 64, 64)' }} >

                                                    <span>Send</span>
                                                </button>
                                                <button onClick={() => this.handleDownloadDocument('pricing')} disabled={!state.customerProfile.data.hasPricingPdfDocument} style={{ background: state.customerProfile.data.hasPricingPdfDocument ? 'rgb(54, 142, 201)' : 'rgb(64, 64, 64)' }} >

                                                    <span>Download</span>
                                                </button>
                                                <UploadButton types={['pdf']} handle={data => this.handleUploadDocument('pricing', data)} icon={'file-upload'} text={'Upload'} />
                                            </div>
                                            <div className="flex-row flex-row-padder">
                                                <button style={{ marginTop: '5px', background: state.customerProfile.data.hasPricingPdfCompleted ? 'rgb(118, 180, 77)' : 'rgb(64, 64, 64)' }} disabled={!state.customerProfile.data.hasPricingPdfCompleted} onClick={() => this.handleDownloadDocument('pricing-completed')}>

                                                    <span>View Signed</span>
                                                </button>
                                            </div>
                                            <span>
                                                <label>Request Call to be Uploaded</label>
                                                <div className="">
                                                    <input type="datetime-local" onChange={e => state.customerProfile.requestCallTime = new Date(e.currentTarget.value)} />
                                                    <button onClick={this.handleRequestCall} style={{ background: 'rgb(54, 142, 201)', marginTop: '5px' }} >
                                                        <span>Request Call</span>
                                                    </button>
                                                </div>
                                            </span>
                                        </div>
                                    </div>]
                            }
                        </Card>
                    </div>
                    <div className="grid-3 grid-gap-big">
                    <Card height="440px" style={{ padding: '16px', overflow: 'auto' }} className="grid-span-2 full-width-form">
                        <h2 className="with-st">Ready for Quote (RFQ)<small>RFQ Form</small></h2>
                        <hr />
                        <div className="grid-2 grid-gap-big">
                            <span>
                                <label>Ideal Length of Contract</label>
                                <input type="number" defaultValue={state.customerProfile.data.lengthOfContract} onChange={e => state.customerProfile.data.lengthOfContract = e.currentTarget.value} />
                            </span>
                            <span>
                                <label>Preferred Supplier</label>
                                <input type="text" defaultValue={state.customerProfile.data.preferredSupplier} onChange={e => state.customerProfile.data.preferredSupplier = e.currentTarget.value} />
                            </span>
                            <span>
                                <label>Suppliers to Avoid</label>
                                <input type="text" defaultValue={state.customerProfile.data.suppliersToAvoid} onChange={e => state.customerProfile.data.suppliersToAvoid = e.currentTarget.value} />
                            </span>
                            <span>
                                <label>Price in Mind</label>
                                <input type="number" defaultValue={state.customerProfile.data.priceInMind} onChange={e => state.customerProfile.data.priceInMind = e.currentTarget.value} />
                            </span>
                            <span>
                                <label>Has the Customer been Price Conditioned?</label>
                                <select defaultValue={state.customerProfile.data.priceConditioned ? '1' : '0'} onChange={e => state.customerProfile.data.priceConditioned = e.currentTarget.value === '1'}>
                                    <option value="0">N/A</option>
                                    <option value="1">Yes</option>
                                </select>
                            </span>
                            <span>
                                <label>Using Another Broker?</label>
                                <select defaultValue={state.customerProfile.data.usingAnotherBroker ? '1' : '0'} onChange={e => state.customerProfile.data.usingAnotherBroker = e.currentTarget.value === '1'}>
                                    <option value="0">N/A</option>
                                    <option value="1">Yes</option>
                                </select>
                            </span>
                            <span>
                                <label>Recieved a Renewal Letter?</label>
                                <select defaultValue={state.customerProfile.data.hasRenewalLetter ? '1' : '0'} onChange={e => state.customerProfile.data.hasRenewalLetter = e.currentTarget.value === '1'}>
                                    <option value="0">N/A</option>
                                    <option value="1">Yes</option>
                                </select>
                            </span>
                            <span>
                                <label>Any Other Business?</label>
                                <select defaultValue={state.customerProfile.data.otherBusiness ? '1' : '0'} onChange={e => state.customerProfile.data.otherBusiness = e.currentTarget.value === '1'}>
                                    <option value="0">N/A</option>
                                    <option value="1">Yes</option>
                                </select>
                            </span>
                        </div>
                    </Card>
                    <Card height="440px" style={{ padding: state.customerProfile.qaBusy ? 0 : '16px', overflow: 'auto' }} className="full-width-form">
                    <h2 className="with-st">Admin Information<small>All information added by admin</small></h2>
                        <hr />
                        <div className="grid-1 grid-gap-big">
                        <span>
                                <label>Admin Timescale</label>
                                <input type="date" defaultValue={state.customerProfile.data.adminTimescale ? new Date(state.customerProfile.data.adminTimescale).toJSON().substr(0, 10) : ''} disabled />
                            </span>
                            <span>
                                <label>Pricing Timescale</label>
                                <input type="date" defaultValue={state.customerProfile.data.pricingTimescale ? new Date(state.customerProfile.data.pricingTimescale).toJSON().substr(0, 10) : ''} disabled />
                            </span>
                            <span>
                                <label>Notes</label>
                                <textarea defaultValue={state.customerProfile.data.adminNotes} disabled />
                            </span>
                        </div>
                    </Card>
                </div>
                </div>
        );
    }
})
