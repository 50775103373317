import React, { Component } from 'react';
import Card from '../../generic/Card';
import Stat from '../../generic/Stat';
import state from '../../State';
import { view } from 'react-easy-state';
import Loader from '../../generic/Loader';

export default view(class AgentAnalysis extends Component {
    componentWillUnmount() {
        state.agentAnalysis.loading = true;

    }
    componentDidMount() {
        state.agentAnalysis.loading = true;
        state.track({
            module: 'DATA',
            action: `LOADED_AGENT_ANALYSIS`
        });
        state.api.post(`users/find${state.buildGetSelector(['firstName', 'lastName', 'email', 'id'])}`, {
            isActive: true,
            accessSales: true
        }).then(res => {
            state.agentAnalysis.users = res.data;
            this.retrieveData();
        })
    }
    retrieveData = () => {
        state.agentAnalysis.loading = true;

        Promise.all([
            state.api.post(`customers/find${state.buildGetSelector(['hasLoaLevelTwo', 'hasLoaLevelOne', 'loaLevelOneEnv', 'loaLevelTwoEnv', 'hasPricingPdfDocument', 'hasPricingPdfCompleted', 'isActive', 'hasCalled'])}`, {
                userID: state.agentAnalysis.users[state.agentAnalysis.userIndex].id
            }),
            state.api.post(`tracking/scope/${state.agentAnalysis.dates.from}/${state.agentAnalysis.dates.to}${state.buildGetSelector(['action', 'dateCreated'])}`, { module: 'SALES', userID: state.agentAnalysis.users[state.agentAnalysis.userIndex].id })
        ]).then(results => {
            state.agentAnalysis.stats.totalCustomers = results[0].data.filter(x => x.isActive).length;
            state.agentAnalysis.stats.prospectPipeline = results[0].data.filter(x => !x.hasCalled && x.isActive).length;
            state.agentAnalysis.stats.loasSent = results[0].data.filter(x => (!x.hasLoaLevelOne && x.loaLevelOneEnv) || (!x.hasLoaLevelTwo && x.loaLevelTwoEnv)).length;
            state.agentAnalysis.stats.loasSigned = results[0].data.filter(x => x.hasLoaLevelOne || x.hasLoaLevelTwo).length;
            state.agentAnalysis.stats.loaConverisonRate = results[0].data.filter(x => (x.hasLoaLevelOne || x.hasLoaLevelTwo) && x.hasCalled).length / results[0].data.length * 100;
            state.agentAnalysis.stats.availableToSell = results[0].data.filter(d => (d.hasLoaLevelTwo || d.hasLoaLevelOne) && d.hasPricingPdfDocument).length;
            state.agentAnalysis.stats.customersClosed = results[0].data.filter(d => d.hasLoaLevelTwo && d.hasPricingPdfCompleted).length;
            results[1].data = state.validation.ordersValuesByProperty(results[1].data.map(x => {
                x.dateCreated = new Date(x.dateCreated);
                return x;
            }),'dateCreated');
            state.agentAnalysis.stats.totalCalls = results[1].data.filter(x => x.action === 'STARTED_CALL').length;
            let callTimeSeconds = 0;
            let longestCallTimeSeconds = 0;
            let callTimes = [];
            let calls = results[1].data.filter(x => x.action === 'ENDED_CALL');
            calls.forEach(endCall => {
                let mostRelatableStart = state.validation.ordersValuesByProperty(results[1].data.filter(y => {
                    return y.action === 'STARTED_CALL' && y.dateCreated < endCall.dateCreated;
                }), 'dateCreated', true);
                if (mostRelatableStart.length) {
                    let timeSeconds = Math.round((endCall.dateCreated - mostRelatableStart[0].dateCreated) / 1000);
                    callTimes.push(timeSeconds);
                    if (longestCallTimeSeconds < timeSeconds) longestCallTimeSeconds = timeSeconds;
                    callTimeSeconds += timeSeconds;
                }
            });
            state.agentAnalysis.stats.totalCallTime = callTimeSeconds ? this.readableTime(callTimeSeconds) : 0;
            state.agentAnalysis.stats.longestCallTime = longestCallTimeSeconds ? this.readableTime(longestCallTimeSeconds) : 0;
            state.agentAnalysis.stats.averageCallTime = callTimes.length ? this.readableTime(state.validation.medianOfArr(callTimes)) : 0;
            state.agentAnalysis.stats.unansweredCalls = results[1].data.filter(x => x.action === 'NO_ANSWER').length;
            state.agentAnalysis.stats.unusableCalls = results[1].data.filter(x => x.action === 'UNUSABLE').length;
            state.agentAnalysis.stats.lastActivity = results[1].data.length ? this.readableTime((new Date() - results[1].data[results[1].data.length - 1].dateCreated) / 1000) : 0;
            state.agentAnalysis.stats.lastCall = results[1].data.length ? this.readableTime((new Date() - results[1].data.filter(x => x.action === 'ENDED_CALL')[results[1].data.filter(x => x.action === 'ENDED_CALL').length - 1].dateCreated) / 1000) : 0;
            state.agentAnalysis.loading = false;
        })
    }
    readableTime(seconds) {
        if (seconds < 3600) {
            let minutes = Math.floor(seconds / 60);
            if (Math.floor(seconds - (minutes * 60)) > 30) minutes += 1;
            return `${minutes} min${minutes > 1 ? 's' : ''}`;
        } else {
            let hours = Math.floor(seconds / 60 / 60);
            let minutes = Math.floor(seconds / 60 - (hours * 60));
            if (Math.floor(seconds - (minutes * 60)) > 30) minutes += 1;
            return `${hours} hr${hours > 1 ? 's' : ''} ${minutes} min${minutes > 1 ? 's' : ''}`;
        }
    }
    render() {
        return state.agentAnalysis.loading ? <Loader absolute colour="#f4f4f4" /> : <div className="grid-1 grid-gap-big">
            <Card height="auto" style={{ padding: '4px 16px 16px 16px' }}>
                <div>
                    <label htmlFor="dataSource">Date Range</label>
                    <div className="flex-row flex-row-padder">
                        <select style={{ height: '41px' }} value={state.agentAnalysis.userIndex} onChange={e => {
                            state.agentAnalysis.userIndex = e.currentTarget.value;
                        }}>
                            {state.agentAnalysis.users.map((x, i) => <option value={i}>{x.firstName} {x.lastName}</option>)}
                        </select>
                        <input type="datetime-local" value={state.agentAnalysis.dates.from} onChange={e => {
                            state.agentAnalysis.dates.from = e.currentTarget.value;
                        }} />
                        <input type="datetime-local" value={state.agentAnalysis.dates.to} onChange={e => {
                            state.agentAnalysis.dates.to = e.currentTarget.value;

                        }} />
                        <button className={`icon-btn`} onClick={this.retrieveData}>
                            <i style={{ display: 'inline' }} className="fas fa-search"></i>
                            <span>Search</span>
                        </button>
                    </div>
                </div>
            </Card>
            <div className={`grid-3 grid-gap-big agent-stats`}>
                <Stat label="Total Customers" value={state.agentAnalysis.stats.totalCustomers ? state.validation.stringifyData(state.agentAnalysis.stats.totalCustomers) : 'N/A'} textColour="#383838" height="190px" />
                <Stat label="Prospect Pipeline" value={state.agentAnalysis.stats.prospectPipeline ? state.validation.stringifyData(state.agentAnalysis.stats.prospectPipeline) : 'N/A'} textColour="#383838" height="190px" />
                <Stat label="Total Calls" value={state.agentAnalysis.stats.totalCalls ? state.validation.stringifyData(state.agentAnalysis.stats.totalCalls) : 'N/A'} textColour="#383838" height="190px" />
                <Stat label="Total Call Time" value={state.agentAnalysis.stats.totalCallTime ? state.validation.stringifyData(state.agentAnalysis.stats.totalCallTime) : 'N/A'} textColour="#383838" height="190px" />
                <Stat label="Longest Call Time" value={state.agentAnalysis.stats.longestCallTime ? state.validation.stringifyData(state.agentAnalysis.stats.longestCallTime) : 'N/A'} textColour="#383838" height="190px" />
                <Stat label="Average Call Time" value={state.agentAnalysis.stats.averageCallTime ? state.agentAnalysis.stats.averageCallTime : 'N/A'} textColour="#383838" height="190px" />
                <Stat label="LOAs Signed" value={state.agentAnalysis.stats.loasSigned ? state.validation.stringifyData(state.agentAnalysis.stats.loasSigned) : 'N/A'} textColour="#383838" height="190px" />
                <Stat label="LOAs Sent" value={state.agentAnalysis.stats.loasSent ? state.validation.stringifyData(state.agentAnalysis.stats.loasSent) : 'N/A'} textColour="#383838" height="190px" />
                <Stat label="LOA Conversion Rate" value={state.agentAnalysis.stats.loaConverisonRate ? state.agentAnalysis.stats.loaConverisonRate.toFixed(2) + '%' : 'N/A'} textColour="#383838" height="190px" />
                <Stat label="Unanswered Calls" value={state.agentAnalysis.stats.unansweredCalls ? state.validation.stringifyData(state.agentAnalysis.stats.unansweredCalls) : 'N/A'} textColour="#383838" height="190px" />
                <Stat label="Unusable Calls" value={state.agentAnalysis.stats.unusableCalls ? state.validation.stringifyData(state.agentAnalysis.stats.unusableCalls) : 'N/A'} textColour="#383838" height="190px" />
                <Stat label="Last Activity" value={state.agentAnalysis.stats.lastActivity ? state.agentAnalysis.stats.lastActivity : 'N/A'} textColour="#383838" height="190px" />
                <Stat label="Last Call" value={state.agentAnalysis.stats.lastCall ? state.validation.stringifyData(state.agentAnalysis.stats.lastCall) : 'N/A'} textColour="#383838" height="190px" />
                <Stat label="Customers Available to Sell" value={state.agentAnalysis.stats.availableToSell ? state.validation.stringifyData(state.agentAnalysis.stats.availableToSell) : 'N/A'} textColour="#383838" height="190px" />
                <Stat label="Customers Closed" value={state.agentAnalysis.stats.customersClosed ? state.agentAnalysis.stats.customersClosed : 'N/A'} textColour="#383838" height="190px" />
            </div>
        </div>;
    }
})