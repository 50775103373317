/* eslint-disable */
import React from 'react';
import { view } from 'react-easy-state';

export default view(class Planner extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      date : new Date(),
      data : props.data
    }
    this.previousWeek = this.previousWeek.bind(this);
    this.nextWeek = this.nextWeek.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleNavigation = this.handleNavigation.bind(this);
  }
  shouldComponentUpdate(){
    if(this.props.data != this.state.data){
    this.setState({
      data : this.props.data
    });
  }
    return true;
  }
  getFirstDayOfWeek(d) {
    return (new Date(d.setDate(d.getDate() - d.getDay() +1)));
  }

  addDays(d,n) {
    return new Date(d.setDate(d.getDate() + n));
  }
  nextWeek(){
    this.setState({
      date : this.addDays(this.getFirstDayOfWeek(this.state.date),7)
    });
  }
  previousWeek(){
    this.setState({
      date : this.addDays(this.getFirstDayOfWeek(this.state.date),-7)
    });
  }
  handleClick(e){
    if(this.props.handler) this.props.handler(this.state.data[Number(e.currentTarget.getAttribute("data-index"))].data);
  }
  handleNavigation(e){
    if (e.key === "ArrowLeft") {
      this.previousWeek();
    } else if (e.key === "ArrowRight") {
      this.nextWeek();
    }
  }
  render(){
    return (
    <div className="week-planner" onKeyDown={this.handleNavigation} tabIndex="0">
      <div className="week-planner-times">
        <div className="week-planner-controls">
        <i className="fas fa-caret-left" onClick={this.previousWeek}></i>
        <i className="fas fa-caret-right" onClick={this.nextWeek}></i>
        </div>
        {["7:00am","8:00am","9:00am","10:00am","11:00am","12:00pm","1:00pm","2:00pm","3:00pm","4:00pm","5:00pm","6:00pm","7:00pm"].map((time) => <span className="week-planner-time">{time}</span>)}
      </div>
      <div className="week-planner-plot">
        <div className="week-planner-days">
          {[...Array(7)].map((x, i) => <span className="week-planner-day">{this.addDays(this.getFirstDayOfWeek(this.state.date),i).toLocaleDateString('en-GB')}</span>)}
        </div>
        <div className="week-planner-plot-area">
          {[...Array(7)].map((x, i) => {
            let currentDate = this.addDays(this.getFirstDayOfWeek(this.state.date),i);
            let plottableDates = this.state.data.filter(node => node.date.toLocaleDateString('en-GB') == currentDate.toLocaleDateString('en-GB') && node.date.getHours() >= 7 && node.date.getHours() <= 19);
            return <div className="week-planner-plot-area-day">
              {
                
                plottableDates.map((node) => {
                  let i = this.state.data.findIndex(match => match == node);
                  let plotTime = {
                    top : `${(((node.date.getHours() - 7) * 60) + node.date.getMinutes()) * 2 + 12}px`
                  }
                  return <span className="week-planner-plot-area-node" data-index={i} onClick={this.handleClick} style={plotTime} title={`${node.id} - ${node.date.toLocaleString()}`}>{node.id}</span>
                })
              }
            </div>
          })}
        </div>
      </div>
    </div>
    )
  }
})