import React, { Component } from 'react';
import Card from '../../generic/Card';
import Loader from '../../generic/Loader';
import state from '../../State';
import { view } from 'react-easy-state';
import email from '../../plugins/email';
import Notification from '../../generic/Notification';
import config from '../../config';

export default view(class AdminEmail extends Component {
    componentDidMount() {
        state.track({
            module: 'PRICING',
            action: 'LOADED_INFORMATION_REQUEST'
        });
        // state.api.post(`customers/find${state.buildGetSelector(['id', 'name', 'email', 'loaLevelTwoEnv', 'hasLoaLevelTwo', 'hasDirectDebitMandateDocument', 'hasDirectDebitMandateCompleted', 'hasPricingPdfDocument', 'hasPricingPdfCompleted'])}`, { isActive: true }).then(res => {
        //     state.adminEmail.customers = res.data.filter(x => {
        //         return x.hasDirectDebitMandateDocument || x.hasLoaLevelTwo || x.hasPricingPdfDocument
        //     });
        //     if (state.adminEmail.customers.length) {
        //         state.adminEmail.loaEnv = state.adminEmail.customers[0].loaLevelTwoEnv;
        //         state.adminEmail.customerID = state.adminEmail.customers[0].id;
        //         state.adminEmail.file = state.adminEmail.customers[0].hasLoaLevelTwo ? 'loa' : state.adminEmail.customers[0].hasDirectDebitMandateDocument ? 'ddmandate' : 'pricing';
        //         state.adminEmail.loading = false;
        //     } else {
        //         this.props.history.push('/pricing-upload');
        //     }
        // });
        state.adminEmail.loading = false;
    }
    // handleCustomerChange = e => {
    //     let customer = state.adminEmail.customers.find(x => x.id === e.currentTarget.value);
    //     state.adminEmail.customerID = e.currentTarget.value;
    //     state.adminEmail.file = customer.hasLoaLevelTwo ? 'loa' : customer.hasDirectDebitMandateDocument ? 'ddmandate' : 'pricing';
    //     state.adminEmail.loaEnv = customer.hasLoaLevelTwo ? customer.loaLevelTwoEnv : '';
    // }
    sendDocuments() {
        new Notification('Sending Documents');
        let file = {
            name: state.adminEmail.file === 'loa' ? 'Letter of Acceptance' : state.adminEmail.file === 'ddmandate' ? 'Direct Debit Mandate' : 'Pricing Sheet',
            link: ''
        }
        new Promise(res => {
            if (state.adminEmail.file === 'loa') {
                if (state.adminEmail.loaEnv.indexOf('.pdf') === -1) {
                    state.eSign.get(`${state.adminEmail.loaEnv}/uri`).then(docusignUri => {
                        file.link = docusignUri.data;
                        res();
                    });
                } else {
                    state.api.get(`storage/url?key=${state.adminEmail.loaEnv}`).then(uri => {
                        file.link = uri.data;
                        res();
                    })
                }
            } else {
                state.api.get(`storage/url?key=${encodeURIComponent(`${state.adminEmail.customerID}/${state.adminEmail.file}.pdf`)}`).then(obj => {
                    file.link = obj.data;
                    res();
                });
            }
        }).then(() => {
            let body = email.replace('[name]', state.adminEmail.email)
                .replace('[logoPng]', config.appearance.logo.email)
                .replace('[companyName]', config.meta.title)
                .replace('[content]', `Could we please have the ${
                    Object.keys(state.adminEmail.requiredInformation).map(k => {
                        if (k === 'endDate' && state.adminEmail.requiredInformation[k]) return 'Contract End Date';
                        if (k === 'consumption' && state.adminEmail.requiredInformation[k]) return 'Annual Consumption';
                        if (k === 'rates' && state.adminEmail.requiredInformation[k]) return 'Standing Charge & Unit Rates';
                        return '';
                    }).join(', ')
                    } for the below meters?<br/><br/>
                <ul>${state.adminEmail.meters.split(',').map(x => `<li>${x.trim()}</li>`).join('')}</ul>`)
                .replace('[btnLink]', file.link)
                .replace('[btnText]', 'View file');
            state.api.post('sendgrid', {
                From: { key: `${state.user.firstName} ${state.user.lastName}`, value: state.user.email },
                To: { key: state.adminEmail.email.split('@')[0], value: state.adminEmail.email },
                Subject: `Documents from ${config.meta.title}`,
                Body: body
            }).then(res => {
                new Notification('Sent Documents');
            });
        });
    }
    render() {
        return (
            state.adminEmail.loading ? <Loader absolute colour="#f4f4f4" /> :
                <div className="grid-center" style={{ height: 'calc(100vh - 66px)' }}>
                    <Card height="520px" width="600px" style={{ padding: '30px 16px' }}>
                        <div>
                            <h1 className="with-st">Information Request<small>Automatically send an email to a supplier</small></h1>
                            <br />
                            <div className="center-contents">

                                <label>Email Address</label>
                                <input type="email" value={state.adminEmail.email} onChange={e => state.adminEmail.email = e.currentTarget.value} />

                                <label>Meters (comma seperated)</label>
                                <input type="text" value={state.adminEmail.meters} onChange={e => state.adminEmail.meters = e.currentTarget.value} />

                                <label>Required Information</label>
                                <div className="flex-row flex-row-padder">
                                    <button className={`icon-btn ${state.adminEmail.requiredInformation.endDate ? '' : 'inactive-btn'}`} style={{ width: 'auto' }} onClick={() => state.adminEmail.requiredInformation.endDate = !state.adminEmail.requiredInformation.endDate}>
                                        <i className="fas fa-calendar"></i>
                                        <span>End Date</span>
                                    </button>
                                    <button className={`icon-btn ${state.adminEmail.requiredInformation.consumption ? '' : 'inactive-btn'}`} style={{ width: 'auto' }} onClick={() => state.adminEmail.requiredInformation.consumption = !state.adminEmail.requiredInformation.consumption}>
                                        <i className="fas fa-bolt"></i>
                                        <span>Consumption</span>
                                    </button>
                                    <button className={`icon-btn ${state.adminEmail.requiredInformation.rates ? '' : 'inactive-btn'}`} style={{ width: 'auto' }} onClick={() => state.adminEmail.requiredInformation.rates = !state.adminEmail.requiredInformation.rates}>
                                        <i className="fas fa-list-ol"></i>
                                        <span>Rates</span>
                                    </button>
                                </div>

                                <button className={`icon-btn`} style={{ marginTop: '16px', background: state.validation.isEmail(state.adminEmail.email) && state.adminEmail.meters && (state.adminEmail.requiredInformation.rates || state.adminEmail.requiredInformation.consumption || state.adminEmail.requiredInformation.endDate) ? '#76B44D' : 'grey' }} disabled={state.validation.isEmail(state.adminEmail.email) && state.adminEmail.meters && (state.adminEmail.requiredInformation.rates || state.adminEmail.requiredInformation.consumption || state.adminEmail.requiredInformation.endDate) ? false : true} onClick={this.sendDocuments}>
                                    <i className="fas fa-envelope"></i>
                                    <span>Send Documents</span>
                                </button>
                            </div>
                        </div>
                    </Card>
                </div>
        );
    }
})
