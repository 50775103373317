/* eslint-disable */
import React, { Component } from 'react';
import state from '../../State';
import { view } from 'react-easy-state';
import Loader from '../../generic/Loader';
import Table from '../../generic/Table';
import Card from '../../generic/Card';
import jsonpack from 'jsonpack';

export default view(class systemHome extends Component {
    constructor() {
        super();
    }
    componentWillUnmount() {
        Object.assign(state.systemHome,{
            data : [],
            hiddenColumns : [],
            loading : true,
            selectedSourceIndex : 0
        });
    }
    componentDidMount() {
        state.track({
            module : 'DATA',
            action : `LOADED_DATA_REPORTS`
          });
        if (this.props.location.search) {
            if (this.props.location.search.split('config=').length > 0) {
                try {
                    let parsed = jsonpack.unpack(atob(this.props.location.search.split('config=')[1]));
                    state.systemHome.hiddenColumns = parsed.hc;
                    state.systemHome.selectedSourceIndex = parsed.s;
                    state.systemHome.filters = JSON.parse(parsed.f).map(x => {
                        if(x.type === 'date'){
                            x.value = new Date(x.value);
                        }
                        return x;
                    });
                } catch (e) {

                }
            }
        }
        state.systemHome.data = [];
        state.systemHome.loading = false;
    }
    updateUrl = () => {
        this.props.history.push(`?config=${btoa(jsonpack.pack({
            hc:state.systemHome.hiddenColumns,
            f:JSON.stringify(state.systemHome.filters),
            s:state.systemHome.selectedSourceIndex
        }))}`);
    }
    retrieveData = () => {
        this.updateUrl();
        state.systemHome.loading = true;
        state.api.get(`${state.systemHome.sources[state.systemHome.selectedSourceIndex].name.key}${state.buildGetSelector(state.systemHome.sources[state.systemHome.selectedSourceIndex].column.keys)}`).then(res => {
            state.systemHome.data = state.systemHome.sources[state.systemHome.selectedSourceIndex].column.friendlyMap(res.data);
            state.systemHome.loading = false;
        })
    }
    handleDataSourceChange = e => {
        state.track({
            module : 'DATA',
            action : `DATA_SOURCE_CHANGED_TO_${state.systemHome.sources[state.systemHome.selectedSourceIndex].name.key.toUpperCase()}`
          });
        state.systemHome.selectedSourceIndex = e.currentTarget.value;
        state.systemHome.hiddenColumns = [];
        state.systemHome.filters = [];
        this.retrieveData();
    }
    handleHideShowColumn = e => {
        let index = state.systemHome.hiddenColumns.findIndex(x => x === e.currentTarget.getAttribute('value'));
        if(index !== -1){
            state.systemHome.hiddenColumns.splice(index,1);
        } else {
            state.systemHome.hiddenColumns.push(e.currentTarget.getAttribute('value'));
        }
        this.updateUrl();
    }
    get tableView(){
        return state.systemHome.data.map(r => {
          return Object.keys(r).reduce((j,k) => {
              j[k] = r[k];
            return j;
          },{});
        });
    }
    get generateOperators(){
        switch(state.validation.getType(state.systemHome.data[0][state.systemHome.filterSelection.column])){
            case 'number':
            case 'date':
                return [
                    { operator : '==', name : 'Equal to' },
                    { operator : '!=', name : 'Not equal to' },
                    { operator : '>', name : 'More than (>)' },
                    { operator : '>=', name : 'More than or Equal to (>=)' },
                    { operator : '<', name : 'Less than (<)' },
                    { operator : '<=', name : 'Less than or Equal to (<=)' }
                ]
            case 'boolean':
                return [
                    { operator : '==', name : 'Equal to' },
                    { operator : '!=', name : 'Not equal to' }
                ]
            default:
                return [
                    { operator : '==', name : 'Equal to' },
                    { operator : '!=', name : 'Not equal to' },
                    { operator : 'contains', name : 'Contains' },
                    { operator : '!contains', name : 'Does not contain' }
                ]
        }
    }
    get generateValueField(){
        switch(state.validation.getType(state.systemHome.data[0][state.systemHome.filterSelection.column])){
            case 'number':
                return <input type='number' onChange={e => state.systemHome.filterSelection.value = parseFloat(e.currentTarget.value)}/>
            case 'date':
                return <input type='datetime-local' onChange={e => state.systemHome.filterSelection.value = new Date(e.currentTarget.value)}/>
            case 'boolean':
                state.systemHome.filterSelection.value = true;
                return <select onChange={e => state.systemHome.filterSelection.value = e.currentTarget.value === '1'}><option value="1">Yes</option><option value="0">No</option></select>
            default:
                return <input type='string' onChange={e => state.systemHome.filterSelection.value = e.currentTarget.value}/>
        }
    }
    render() {
        return <div className="grid-1 grid-gap-big">
            <Card className="report-filters grid-3" height="auto" style={{ padding: '4px 16px 16px 16px' }}>
                            <div>
                                <label htmlFor="dataSource">Data Source</label>
                                <select id="dataSource" onChange={this.handleDataSourceChange}>
                                    {state.systemHome.sources.map((x,i)=><option value={i} key={i}>{x.name.friendly}</option>)}
                                </select>
                                <button style={{marginTop:'10px'}} onClick={this.retrieveData}>Refresh Data</button>
                            </div>
                            
                        </Card>
                        <Card height="calc(100vh - 215px)" style={{ padding: '16px' }}>
                            <Table
                                data={this.tableView}
                                loading={state.systemHome.loading}
                                tid='exports'
                                height='calc(100vh - 250px)'
                                perPage={150}
                                icons={{
                                    fullscreen : false
                                }}
                            />
                        </Card>
                    </div>
    }
})