import React, { Component } from 'react';
import Card from '../../generic/Card';
import Loader from '../../generic/Loader';
import state from '../../State';
import { view } from 'react-easy-state';
import Notification from '../../generic/Notification';

export default view(class ArchiveCustomer extends Component {
  componentDidMount() {
    state.api.post(`customers/find${state.buildGetSelector(['id','name'])}`, {
      name: decodeURIComponent(this.props.match.params.customer),
      isActive: true
  }).then(res => {
      if (res.data.length) {
        state.deactivateClient.customerID = res.data[0].id;
        state.deactivateClient.customerName = res.data[0].name;
          state.deactivateClient.loading = false;
      } else {
          this.props.history.push('/sales-system')
      }
  })
  }
  componentWillUnmount() {
    
  }
  handleDeactivate = () => {
    state.deactivateClient.loading = true;
    state.api.post(`customers/${state.deactivateClient.customerID}`,{isActive : false, deactivationDate: new Date()}).then(res => {
      new Notification('Deactivated Customer Successfully');
      this.props.history.push('/sales-system')
    })
  }
  render() {
    return (
      state.deactivateClient.loading ? <Loader absolute colour="#f4f4f4" /> :
                <div className="grid-center" style={{ height: 'calc(100vh - 66px)' }}>
                    <Card height="auto" width="600px" style={{padding:'50px 0px'}}>
                        <div className="center-contents">
                            <h1 className="with-st">Deactivate {state.deactivateClient.customerName}?<small>Are you sure you want to deactivate this customer?</small></h1>
                            <div className="flex-row flex-row-padder" style={{marginTop:'50px'}}>
                                <button className={`icon-btn`} style={{width:'auto',background:'#D51F57'}} onClick={() => this.handleDeactivate()}>
                                    <i className="fas fa-check"></i>
                                    <span>Yes</span>
                                </button>
                                <button className={`icon-btn`} style={{width:'auto',background:'#111111'}} onClick={() => this.props.history.goBack()}>
                                    <i className={`fas fa-times`}></i>
                                    <span>Cancel</span>
                                </button>
 
                            </div>
                        </div>
                    </Card>
                </div>
    );
  }
})
