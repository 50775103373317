import React, { Component } from 'react';
import Card from '../../generic/Card';
import Loader from '../../generic/Loader';
import Table from '../../generic/Table';
import Notification from '../../generic/Notification';
import state from '../../State';
import { view } from 'react-easy-state';
import jszip from 'jszip';
import { saveAs } from 'file-saver';
import downloadHelper from '../../plugins/downloadFile';

export default view(class Dashboard extends Component {
  componentDidMount() {
    state.adminHome.view = 'all';
    state.track({
      module: 'ADMIN',
      action: 'LOADED_ADMIN_HOME'
    });
    state.api.get(`customers/admin/loas`).then(resp => {
      state.adminHome.data = state.validation.ordersValuesByProperty(resp.data.map(x => {
        x.loaLevelOneSignDate = x.loaLevelOneSignDate ? new Date(x.loaLevelOneSignDate) : null;
        x.loaLevelTwoSignDate = x.loaLevelTwoSignDate ? new Date(x.loaLevelTwoSignDate) : null;
        return x;
      }),'loaLevelOneSignDate',true);
      state.adminHome.loading = false;

    })
  }
  
  componentWillUnmount() {
    state.adminHome.loading = true;
    state.adminHome.data = [];
    state.adminHome.view = 'all';
  }
  get tableView() {
    return state.adminHome.data.filter(x => {
      switch(state.adminHome.view){
        case 'all':
          return true;
        case 'init':
          return !x.readyForPricing
        case 'rfp':
          return x.readyForPricing
          case 'pricing':
          return x.hasPricingPdfDocument
          case 'pricingcomplete':
          return x.hasPricingPdfCompleted
      }
    }).map(x => {
      // let loaIcons = [];
      // if(x.hasLoaLevelOne){
      //   loaIcons.push({
      //     type: 'file-signature',
      //     title: 'Download LOA Level One',
      //     handleClick: () => this.downloadLoa(x, 1)
      //   });
      // }
      // if (x.hasLoaLevelTwo) {
      //   loaIcons.push({
      //     type: 'file-contract',
      //     title: 'Download LOA Level Two',
      //     handleClick: () => this.downloadLoa(x, 2)
      //   })
      // }
      let row = {
        'Company': x.name,
        'Agent': `${x.user.firstName} ${x.user.lastName} ${x.user.teamName ? `(${x.user.teamName})` : ''}`,
        'Contact': x.nameOnAccount + ' (' + x.telephone + ')'
      }
      row['LOA Level One'] = x.loaLevelOneSignDate ? x.loaLevelOneSignDate.toLocaleDateString('en-GB') : null;
      row['LOA Level Two'] = x.loaLevelTwoSignDate ? x.loaLevelTwoSignDate.toLocaleDateString('en-GB') : null;
      row['Ready for Pricing'] = x.readyForPricing;
      row['Has Pricing'] = x.hasPricingPdfDocument;
      row['Has Completed Pricing'] = x.hasPricingPdfCompleted;
      // row['Notes'] = x.notes ? x.notes[0] === '{' ? JSON.parse(x.notes).ops.map(x => x.insert).join(' ') : x.notes : null;
      return row;
    });
  }
  render() {
    return (
      state.adminHome.loading ? <Loader absolute colour="#f4f4f4" /> :
        <Card height="calc(100vh - 66px)" style={{ padding: '16px' }}>
        <div className="flex-row flex-row-padder">
                            <button className={`icon-btn ${state.adminHome.view !== 'all' ? 'inactive-btn' : ''}`} style={{ marginBottom: '16px' }} onClick={() => state.adminHome.view = 'all'}>
                                <i className="fas fa-table"></i>
                                <span>All</span>
                            </button>
                            <button className={`icon-btn ${state.adminHome.view !== 'init' ? 'inactive-btn' : ''}`} style={{ marginBottom: '16px' }} onClick={() => state.adminHome.view = 'init'}>
                                <i className="fas fa-file"></i>
                                <span>Initial Stage</span>
                            </button>
                            <button className={`icon-btn ${state.adminHome.view !== 'rfp' ? 'inactive-btn' : ''}`} style={{ marginBottom: '16px' }} onClick={() => state.adminHome.view = 'rfp'}>
                                <i className="fas fa-check"></i>
                                <span>Ready for Pricing</span>
                            </button>
                            <button className={`icon-btn ${state.adminHome.view !== 'pricing' ? 'inactive-btn' : ''}`} style={{ marginBottom: '16px' }} onClick={() => state.adminHome.view = 'pricing'}>
                                <i className="fas fa-credit-card-blank"></i>
                                <span>Has Pricing</span>
                            </button>
                            <button className={`icon-btn ${state.adminHome.view !== 'pricingcomplete' ? 'inactive-btn' : ''}`} style={{ marginBottom: '16px' }} onClick={() => state.adminHome.view = 'pricingcomplete'}>
                                <i className="fas fa-credit-card"></i>
                                <span>Completed Pricing</span>
                            </button>
                        </div>
          <Table
            tid="admin"
            data={this.tableView}
            handleClick={e => this.props.history.push(`/admin-information/${encodeURIComponent(e.Company.toLowerCase())}/`)}
            perPage={50}
            height="calc(100vh - 160px)"
            icons={{
              fullscreen: false,
              download: true
            }}
          />
        </Card>
    );
  }
})
