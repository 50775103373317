import React, { Component } from 'react';
import Card from './Card';

class Stat extends Component {
  render() {
    return (
        <Card className={`stat ${this.props.handleClick ? 'stat-clickable' : ''}`} height={this.props.height} style={{background:this.props.colour,color : this.props.textColour ? this.props.textColour : 'white'}} handleClick={()=>this.props.handleClick && this.props.handleClick()}>
        <h2>{this.props.value ? this.props.value : this.props.icon ? <i className={`fas fa-${this.props.icon}`} style={{marginBottom:'15px'}}></i> : ''}</h2>
        <span>{this.props.label}</span>
      </Card>
    );
  }
}

export default Stat;
