import React, { Component } from 'react';
import Card from '../../generic/Card';
import Table from '../../generic/Table';
import state from '../../State';
import { view } from 'react-easy-state';
import Loader from '../../generic/Loader';

export default view(class DataInformationCentre extends Component {
    componentWillUnmount() {
        state.dataInformationCentre.loading = true;
        state.dataInformationCentre.data = []
    }
    componentDidMount() {
        state.track({
            module: 'DATA',
            action: `LOADED_DATA_INFORMATION_CENTRE`
        });
        Promise.all([
            state.api.post(`suppliers/find${state.buildGetSelector(['id', 'name', 'content'])}`,{isActive:true}),
            state.api.post(`articles/find${state.buildGetSelector(['id', 'name', 'content'])}`,{isActive:true})
        ]).then(data => {
            state.dataInformationCentre.data = data[1].data.concat(data[0].data.map(x => {
                x.supplier = true;
                return x;
            }));
            state.dataInformationCentre.loading = false;
        })
    }
    get tableView() {
        return state.dataInformationCentre.data.map(x => {
          return {
              'Type' : x.supplier ? 'Supplier' : 'Article',
              'Name' : x.name,
              'Content' : x.content.substr(0,100) + '...'
          }  
        });
    }
    handleClick = (r,i) => {
        this.props.history.push(`${state.dataInformationCentre.data[i].supplier ? 'Supplier' : 'Article'}/${state.dataInformationCentre.data[i].id.replace(/-/g,'_-_')}`);
    }
    render() {
        return (
            state.dataInformationCentre.loading ? <Loader absolute colour="#f4f4f4" /> :
                <Card height="calc(100vh - 66px)" style={{ padding: '16px' }}>
                <div className="flex-row flex-row-padder">
                    <button className={`icon-btn`} style={{ marginBottom: '16px' }} onClick={() => this.props.history.push(`Supplier/new-supplier`)}>
                        <i className="fas fa-bolt"></i>
                        <span>New Supplier</span>
                    </button>
                    <button className={`icon-btn`} style={{ marginBottom: '16px' }} onClick={() => this.props.history.push(`Article/new-article`)}>
                        <i className="fas fa-info"></i>
                        <span>New Article</span>
                    </button>
                </div>
                    <Table
                        tid="informationCentre"
                        data={this.tableView}
                        handleClick={this.handleClick}
                        height="calc(100vh - 160px)"
                        icons={{
                            fullscreen: false,
                            download: false
                        }}
                    />
                </Card>
        );
    }
})