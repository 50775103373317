import React, { Component } from 'react';
import Card from './Card';

class DashboardStat extends Component {
  render() {
    return (
        <Card className={`dashboard-stat`} height={this.props.height} style={{maxWidth : this.props.maxWidth ? this.props.maxWidth : 'auto'}}>
        <h3>{this.props.heading}</h3>
    <p style={{color : this.props.textColour ? this.props.textColour : 'black'}} >{this.props.icon ? <i className={`fas fa-${this.props.icon}`}></i> : null}<span>{this.props.value !== null ? this.props.value : null}</span></p>
        <span>{this.props.description}</span>
      </Card>
    );
  }
}

export default DashboardStat;
