import ReactDOM from 'react-dom';
import React from 'react';

export default class Notification {
    constructor(message){
        this.message = message;
        this.render();
    }
    render(){
        ReactDOM.render(<NotificationComp message={this.message}/>,document.getElementById('notification'));
    }
}

class NotificationComp extends React.Component {
    constructor(props){
        super();
        this.props = props;
        this.state = {
            closing : false
        }
    }
    componentDidMount(){
        setTimeout(() => {
            this.setState({
                closing: true
            })
            setTimeout(() => {
                ReactDOM.render(null,document.getElementById('notification'));
            },200);
        },4000);
    }
    render(){
        return <span className={this.state.closing ? "close-notification" : ""}>{this.props.message}</span>
    }
}