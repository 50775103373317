import React, { Component } from 'react';
import state from './State';
import { view } from 'react-easy-state';
import Loader from './generic/Loader';
import Login from './components/misc/Login'
import Main from './Main';
import * as Sentry from '@sentry/browser';

Sentry.init({
  dsn: "https://6bd331a663ac4b03b81555237cf96ab1@sentry.io/1421700"
});

export default view(class App extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
}
  componentDidMount(){
    state.authTest().then(success => {
      state.app.loading = false;
    });
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    return (
      state.app.loading ? <Loader/> : 
      state.keys.app ?
      <Main/> : <Login/>
    );
  }
})