import React, { Component } from 'react';
import Card from '../../generic/Card';
import Notification from '../../generic/Notification';
import state from '../../State';
import { view } from 'react-easy-state';

export default view(class NewProspect extends Component {
     constructor(props) {
        super(props);

        this.state = {
            disable: false
        }
    }
    componentDidMount() {
        state.track({
            module: 'SALES',
            action: `LOADED_NEW_PROSPECT`
        });
        this.resetData();
    }
    resetData(){
        state.newProspect.data = {
            name : '',
            nameOnAccount : '',
            telephone : ''
          };
    }
    handleSaveProfile = () => {
        this.setState({disable: true});
        if(
            state.newProspect.data.name &&
            state.newProspect.data.nameOnAccount &&
            state.newProspect.data.telephone
        ) {
            state.api.post('customers/find',{
                name : state.newProspect.data.name,
                isActive : true
            }).then(d => {
                if(d.data.length){
                    this.setState({disable: false});
                    new Notification('Prospect already exists with that name');
                } else {
                    state.api.post('customers',{...state.newProspect.data,isActive:true,userID: state.user.id,dateCreated: new Date(),hasCalled:true}).then(d => {
                        this.setState({disable: false});
                        new Notification('Successfully added prospect');
                        this.props.history.push(`/sales-system/${encodeURIComponent(state.newProspect.data.name.toLowerCase())}/`);
                        this.resetData();
                    });
                }
            })
        } else {
            new Notification('Missing required fields');
        }
    }
    render() {
        return <div className="grid-gap-big user-profile-grid">
                <Card height="380px" style={{ padding: '16px', overflow: 'auto' }} className="full-width-form">
                    <h2 className="with-st">New Prospect<small>Create a new prospect to be assigned to yourself</small></h2>
                    <hr />
                    <div className="grid-1">
                        <span>
                            <label>Company Name</label>
                            <input maxLength="200" type="text" defaultValue={state.newProspect.data.name} onChange={e => state.newProspect.data.name = e.currentTarget.value} />
                        </span>
                        <span>
                            <label>Name on Account</label>
                            <input maxLength="200" type="text" defaultValue={state.newProspect.data.nameOnAccount} onChange={e => state.newProspect.data.nameOnAccount = e.currentTarget.value} />
                        </span>
                        <span>
                            <label>Telephone Number</label>
                            <input maxLength="50" type="number" value={state.newProspect.data.telephone} onChange={e => state.newProspect.data.telephone = state.validation.onlyNumbers(e.currentTarget.value.replace(/ /g, ''))} />
                        </span>
                    </div>
                    <button className={`icon-btn`} style={{ marginTop: '16px' }} onClick={this.handleSaveProfile} disabled={this.state.disable}>
                        <i className="fas fa-save"></i>
                        <span>Save Prospect</span>
                    </button>
                </Card>
            </div>
    }
})
