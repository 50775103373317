import React, { Component } from 'react';
import Card from '../../generic/Card';
import Loader from '../../generic/Loader';
import state from '../../State';
import { view } from 'react-easy-state';
import  Cookies  from  'universal-cookie';
import config from '../../config';
const COOKIES = new Cookies();

export default view(class Login extends Component {
    signInAttempt = () => {
        state.login.loading = true;
        state.api.post('login',state.login.credentials).then(resp => {
            if(resp.status === 200){
                state.login.credentials.password = '';
                state.keys.esign = resp.data.eSignToken;
                COOKIES.set(config.cookies.system,resp.data.accessToken,{ path: '/', maxAge: 60 * 60 * 24 * 28});
                COOKIES.set(config.cookies.esign,resp.data.eSignToken,{ path: '/', maxAge: 60 * 60 * 24 * 28});
                state.keys.app = resp.data.accessToken;
                state.login.loading = false;
            }
        }).catch(() => {
            state.login.loading = false;
            state.login.error = true;
        });
    }
  render() {
    return (
        <div className="login-wrapper">
            <Card height="450px" width="400px">
            {
                state.login.loading ? <Loader absolute/> :

                <div className="login-container">


                    <img className="logo" alt="Logo" src={config.appearance.logo.dark}/>
                    <div>
                        <label htmlFor="email">E-mail Address</label>
                        <input id="email" type="email" defaultValue={state.login.credentials.email} onInput={e => state.login.credentials.email = e.currentTarget.value}/>
                        <label htmlFor="password">Password</label>
                        <input id="password" type="password" onKeyPress={e => e.key === 'Enter' && this.signInAttempt()} onInput={e => state.login.credentials.password = e.currentTarget.value}/>
                        <button onClick={this.signInAttempt} className={`icon-btn`}>
                                <i className="far fa-sign-in"></i>
                                <span>Sign In</span>
                            </button>
                        {state.login.error && <div className="login-invalid">
                            <i className="fas fa-exclamation-triangle"></i>
                            <span>Invalid credentials, please try again.</span>
                        </div>}
                    </div>
                </div>

            }
            </Card>
            <div/>
        </div>
    );
  }
});
